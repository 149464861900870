import React, { useState, useEffect } from "react";
import Table from "../../table";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import { Button, message } from "antd";
import { useHistory } from "react-router-dom";
import { getROLE } from "../../../utils/auth-client";
import { useAuth } from "../../../context/auth-context";


const Assigned = ({
  match: {
    params: { sectionName },
  },
  type
}) => {
  const {
    run,
    error,
    data,
  } = useAsync();

  let [files, setFiles] = useState([]);
  const { roleValues } = useAuth();
  let [filesLoading, setFilesLoading] = useState(false);
  console.log()

  useEffect(() => {
    if (data?.message === "Fetched files") {
      setFiles(transformTableData(data.files));
      setFilesLoading(false);
    }
  }, [data,roleValues]);

  useEffect(() => {
    if (error?.message === "Section not found") {
      setFilesLoading(false);
      message.error(error.message);
    }
  }, [error, roleValues]);

  const history = useHistory();

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      ellipsis: true,
    },
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      ellipsis: true,
    },
    // {
    //     title: "Section",
    //     dataIndex: "section",
    //     key: "section",
    //     ellipsis: true
    // },
    {
        title: "Tasks",
        dataIndex: "count",
        key: "count",
        ellipsis: true,
        align: 'center'
    },
    {
      title: "Actions",
      key: "x",
      dataIndex: "",
      render: (record) => (
        <>
          <Button
            type="primary"
            onClick={() => history.push(`/${type==="to be skipped"?"tobeskipped":type==="skip-approved"?"skipApproved":type}/${record.name}`)}
          >
            View {type}
          </Button>
        </>
      ),
    },
  ];

  const transformTableData = (data) =>
    data.map(({ name, section, count }, index) => ({
      key: index,
      name,
      section,
      sr: index + 1,
      count
    }));

  const fetchFiles = () => {
    run(client(`files/user/list/${roleValues}?type=${type}`));
    setFilesLoading(true);
  };

  useEffect(() => {
    fetchFiles();
  }, [type, roleValues]);

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>{sectionName} Files</h1>
      </div>
      <Table
        loading={filesLoading}
        data={files}
        columns={columns}
        size="middle"
      />
    </div>
  );
};

export default Assigned;
