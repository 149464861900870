import React, { useEffect, useState } from "react";
import { Button, Table, Input } from "antd";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import exportFromJSON from "export-from-json";
import { useAuth } from "../../../context/auth-context";
import moment from "moment";

const { Search } = Input;
const columns = [
  {
    title: "Email",
    dataIndex: ["statusDoc", "email"],
    key: "email",
  },
  {
    title: "Department",
    dataIndex: ["statusDoc", "department"],
    key: "subject",
  },
  {
    title: "Subject",
    dataIndex: ["statusDoc", "subject"],
    key: "subject",
  },
  {
    title: "Status",
    dataIndex: ["statusDoc", "status"],
    key: "status",
  },
  {
    title: "Authoring Status",
    dataIndex: ["statusDoc", "authoring_status"],
    key: "authoring_status",
  },
  {
    title: "Authoring Limit",
    dataIndex: ["statusDoc", "authoring_limit"],
    key: "authoring_limit",
  },
  {
    title: "PS Status",
    dataIndex: ["statusDoc", "ps_limit"],
    key: "ps_limit",
  },
  {
    title: "QC Status",
    dataIndex: ["statusDoc", "qc_status"],
    key: "qc_status",
  },
  {
    title: "QC Limit",
    dataIndex: ["statusDoc", "qc_limit"],
    key: "qc_limit",
  },
  {
    title: "PC Status",
    dataIndex: ["statusDoc", "qps_limit"],
    key: "qps_limit",
  },
  {
    title: "Technical Issues",
    dataIndex: ["statusDoc", "technical_issues"],
    key: "technical_issues",
  },
  {
    title: "Remarks",
    dataIndex: ["statusDoc", "remarks"],
    key: "remarks",
  },
  {
    title: "Updated by",
    dataIndex: ["statusDoc","updated_by","email"],

  },
  {
    title: "Updated At",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (text) => moment(text).format('MMMM Do YYYY, h:mm:ss a'), 

  },
];

const StatusReportTable = () => {
  const { run, data } = useAsync();
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });
  const handlePrint = () => {
    const fileName = "Current Status Report";
    const exportType = "xls";
    const flattenedData = data?.data.map((item) => ({
      Email: item.statusDoc.email,
      Department: item.statusDoc.Department,
      Subject: item.statusDoc.subject,
      Status: item.statusDoc.status,
      "Authoring Status": item.statusDoc.authoring_status,
      "Authoring Limit": item.statusDoc.authoring_limit,
      "PS Status":item.statusDoc.ps_limit,
      "QC Status": item.statusDoc.qc_status,
      "QC Limit": item.statusDoc.qc_limit,
      "PS Status":item.statusDoc.qps_limit,
      "Technical Issues": item.statusDoc.technical_issues,
      Remarks: item.statusDoc.remarks,
      "Updated By":item?.statusDoc?.updated_by?.email,
      "Updated At": new Date(item.updatedAt).toLocaleString(),
    }));

    exportFromJSON({ data: flattenedData, fileName, exportType });
  };
  const {user}=useAuth()

  useEffect(() => {
    const fetchUsers = async () => {
      const result = await run(client(`/fetch/history/status/${user.email}`));
      if (result) {
        setFilteredData(result.data);
      }
    };
    fetchUsers();
  }, [run]);

  const handleSearch = (value) => {
    setSearchText(value);
    if (!value) {
      setFilteredData(data?.data || []);
      return;
    }

    const lowerCaseValue = value.toLowerCase();
    const filtered = data?.data.filter((item) => {
      return (
        item.statusDoc?.email?.toLowerCase()?.includes(lowerCaseValue) ||
        item.statusDoc.subject.toLowerCase().includes(lowerCaseValue)
      );
    });
    setFilteredData(filtered);
  };
  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };
  return (
    <div className="manage--wrapper" style={{ padding: "20px" }}>
      <h1>Status Reports</h1>
      <Button
        onClick={handlePrint}
        style={{ float: "right", marginBottom: "2%" }}
      >
        Download History Status
      </Button>
      <Search
        placeholder="Search..."
        onSearch={handleSearch}
        enterButton
        style={{ marginBottom: "20px", width: "300px" }}
      />
      <Table
        dataSource={filteredData?.length > 0 ? filteredData : data?.data}
        columns={columns}
        rowKey={(record) => record.updatedAt}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: filteredData?.length > 0 ? filteredData?.length : data?.data?.length,
          showSizeChanger: true,
          pageSizeOptions: ["50", "100", "150", "200",'500'],
          onChange: (page, pageSize) => {
            setPagination({ current: page, pageSize });
          },
        }}
        onChange={handleTableChange}
        scroll={{ y: 500 }} 
      />
    </div>
  );
};

export default StatusReportTable;
