import React, { useState, useEffect } from "react";
import "../timesheet.css";
import Table from "../../../table";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Button, Space } from "antd";
import { useHistory } from "react-router-dom";
import Report from "../report";

const Timesheet = ({ type }) => {
  const { run, data } = useAsync();

  const [reportModal, setReportModal] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (data?.message === "Fetched users") {
      setUsers(data.users);
      setUsersLoading(false);
    }
  }, [data]);

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Actions",
      key: "x",
      dataIndex: "",
      render: (record) => (
        <>
          <Space>
            <Button
              type="primary"
              onClick={() => history.push(`/timesheet/${record._id}/manage`)}
            >
              Show Details
            </Button>
          </Space>
        </>
      ),
    },
  ];

  const fetchUsers = () => {
    run(client("timesheet/users"));
    setUsersLoading(true);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const transformTableData = (data) => {
    return data.map((record, index) => ({
      ...record,
      sr: index + 1,
    }));
  };

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>Manage Users</h1>
        <Space>
          <Button onClick={() => setReportModal(!reportModal)}>
            Generate report
          </Button>
        </Space>
      </div>
      <em>
        If any user is not available in the list, please assign any manager to
        the user.
      </em>
      <Table
        loading={usersLoading}
        data={transformTableData(users)}
        columns={columns}
        size="middle"
        paginationConfig={{ defaultPageSize: 50 }}
      />
      <Report
        modal={reportModal}
        handleCancel={() => setReportModal(!reportModal)}
      />
    </div>
  );
};

export default Timesheet;
