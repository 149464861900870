import React, { useState, useEffect } from "react";
import Table from "../../../table";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import {
  Button,
  message,
  Popconfirm,
  Space,
  Form,
  InputNumber,
  Popover,
  Select,
  Breadcrumb,
  Radio
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const Target = ({
  match: {
    params: { targetId },
  },
}) => {
  const { run, data } = useAsync();

  const [target, setTarget] = useState([]);
  const [targetLoading, setTargetLoading] = useState(false);

  useEffect(() => {
    if (data?.message === "Fetched target") {
      setTarget(data.target);
      setTargetLoading(false);
    }

    if (data?.message === "Target removed succesfully.") {
      message.success(data.message);
      fetchTarget(targetId);
    }

    if (data?.message === "Target changed") {
      message.success(data.message);
      fetchTarget(targetId);
    }
  }, [data]);

  const deleteTarget = (deleteId) => {
    run(client(`targets/admin/${targetId}/${deleteId}`, { method: "PUT" }));
  };

  const changeTarget = (vals, id) => {
    run(
      client(`targets/admin/${targetId}/${id}`, { body: vals, method: "PATCH" })
    );
  };

  const content = (record) => (
    <div>
      <Form
        layout="horizontal"
        size="small"
        initialValues={{
          target: record.target,
          type: record.type,
          completedUnits: record.completedUnits,
          completedHours: record.completedHours,
          reason: record.off?.reason
        }}
        onFinish={(val) => changeTarget(val, record._id)}
      >
        <Form.Item label="Target" name="target">
          <InputNumber style={{ width: "50px" }} />
        </Form.Item>
        <Form.Item label="Target type" name="type" rules={[{ required: true }]}>
          <Select style={{ width: 150, margin: "0 8px" }}>
            <Select.Option value="units">units</Select.Option>
            <Select.Option value="hours">hours</Select.Option>
            <Select.Option value="units_hours">units or hours</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Completed Units" name="completedUnits">
          <InputNumber
            style={{ width: "50px" }}
            disabled={record.type === "hours"}
          />
        </Form.Item>
        <Form.Item label="Completed Hours" name="completedHours">
          <InputNumber
            style={{ width: "50px" }}
            disabled={record.type === "units"}
          />
        </Form.Item>
        <Form.Item label="weekly off" name="reason">
          <Radio.Group>
            <Radio value="SL">SL</Radio>
            <Radio value="PL">PL</Radio>
            <Radio value="LOP">LOP</Radio>
            <Radio value="reject">Reject</Radio>
          </Radio.Group>
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </div>
  );

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      ellipsis: true,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Target",
      key: "target",
      render: (record) => (
        <span>
          {record.target} {record.type}
        </span>
      ),
    },
    {
      title: "Completed",
      key: "completed",
      render: (record) => (
        <span>
          {record.type === "units"
            ? `${record.completedUnits} units`
            : record.type === "hours"
            ? `${record.completedHours} hours`
            : `${record.completedUnits} units ${record.completedHours} hours`}

          {record?.off?.isOff && ` with ${record.off.reason}`}
        </span>
      ),
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      render: (record) => (
        <Space>
          <Popover
            content={() => content(record)}
            title="Change target"
            trigger="click"
          >
            <Button type="primary">
              <EditOutlined />
            </Button>
          </Popover>
          <Popconfirm
            title="Are you sure？"
            onConfirm={() => deleteTarget(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const transformTableData = (data) =>
    data.map(({ date, ...target }, index) => ({
      key: index,
      date: new Date(date).toLocaleDateString(),
      sr: index + 1,
      ...target,
    }));

  const fetchTarget = (id) => {
    run(client(`targets/${id}`));
    setTargetLoading(true);
  };

  useEffect(() => {
    fetchTarget(targetId);
  }, []);

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/worklog">Projects</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/worklog/${target?.profileId?.subject?.projectId?._id}`}
              >
                {target.profileId?.subject?.projectId?.name}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/worklog/${target.profileId?.subject?.name}/profiles`}>
                {target.profileId?.subject?.name}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/worklog/${target.profileId?._id}/targets`}>
                {target?.name}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>targets</Breadcrumb.Item>
          </Breadcrumb>
          <br />

          <h1>
            Target: <strong>{target.name}</strong>
          </h1>

          <ul style={{ listStyle: "none" }}>
            <li>
              <strong>Project: </strong>
              <em>{target.profileId?.subject?.projectId?.name}</em>
            </li>
            <li>
              <strong>Subject: </strong>
              <em>{target.profileId?.subject?.name}</em>
            </li>
            <li>
              <strong>Profile: </strong>
              <em>{target.profileId?.name}</em>
            </li>
            <li>
              <strong>Assigned to: </strong>
              <em>{target.userId?.email}</em>
            </li>
            <li>
              <strong>Created by: </strong>
              <em>{target.addedBy?.email}</em>
            </li>
            <li>
              <strong>Created at: </strong>
              <em>{new Date(target.created_at).toLocaleString()}</em>
            </li>
          </ul>
        </div>
      </div>
      {target?.targets && (
        <Table
          loading={targetLoading}
          data={transformTableData(target.targets)}
          columns={columns}
          size="middle"
        />
      )}
    </div>
  );
};

export default Target;
