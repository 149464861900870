import React, { useState, useEffect } from "react";
import Table from "../../../table";
import { useAsync } from "../../../../utils/use-async";
import { useAuth } from "../../../../context/auth-context";
import { client } from "../../../../utils/api-client";
import { Button, message, Space, Modal, Form, Input, Select } from "antd";
import { Spinner } from "../../../lib";
import AssignProject from "./assign-project";

const ManageSheet = ({
  match: {
    params: { userId },
  },
}) => {
  const { run, error, data } = useAsync();
  const { user } = useAuth();

  let [timesheet, setTimesheet] = useState([]);
  let [timesheetLoading, setTimesheetLoading] = useState(false);
  const [currentTimesheet, setCurrentTimesheet] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [currentRecord, setCurrentRecord] = useState({});
  const [maxProjects, setMaxProjects] = useState(1);
  const [toggleAddProject, setToggleAddProject] = useState(false);

  useEffect(() => {
    if (data?.message === "Fetched subject") {
      const timesheet = data.data;
      setTimesheet(timesheet);
      setTimesheetLoading(false);
    }

    if (data?.code === "TIMESHEET_FETCH_SUCCESS") {
      const timesheet = data.data;
      setTimesheet(timesheet);
      setMaxProjects(data.maxProjects);
      setTimesheetLoading(false);
    }

    if (data?.code === "TIMESHEET_UPDATE_SUCCESS") {
      setUpdateLoading(false);
      setShowModal(false);
      setCurrentRecord({});
      fetchTimesheet();
    }
  }, [data]);

  useEffect(() => {
    if (error?.message === "Subject not found") {
      setTimesheetLoading(false);
      message.error(error.message);
    }
  }, [error]);

  const columns = () => {
    const projectKeys = Array.from(Array(maxProjects)).map((p, idx) => {
      const name = `project${idx + 1}-name`;
      const done = `project${idx + 1}-done`;
      return [
        { title: name, dataIndex: name, key: name },
        { title: done, dataIndex: done, key: done },
      ];
    });

    return [
      {
        title: "Sr. No",
        dataIndex: "sr",
        key: "sr",
        ellipsis: true,
        fixed: "left",
      },
      {
        title: "Date",
        key: "date",
        render: (record) => (
          <span>{new Date(record.date).toLocaleDateString()}</span>
        ),
        fixed: "left",
      },
      ...projectKeys.flat(),
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
      },
      {
        title: "User remarks",
        key: "userRemarks",
        dataIndex: "userRemarks",
      },
      {
        title: "Approver remarks",
        key: "approverRemarks",
        dataIndex: "approverRemarks",
      },
      {
        title: "Actions",
        key: "x",
        dataIndex: "",
        render: (record) => (
          <Space>
            <Button
              disabled={record.status === "APPROVED"}
              type="primary"
              onClick={() => {
                setShowModal(true);
                setCurrentTimesheet(record._id);
                setCurrentStatus("APPROVED");
                setCurrentRecord(record);
              }}
            >
              Approve
            </Button>

            <Button
              type="danger"
              onClick={() => {
                setShowModal(true);
                setCurrentTimesheet(record._id);
                setCurrentStatus("REJECTED");
                setCurrentRecord(record);
              }}
              disabled={record.status === "REJECTED"}
            >
              Decline
            </Button>
          </Space>
        ),
      },
    ];
  };

  const transformTableData = (data) =>
    data.map(({ _id, timesheets, ...rest }, index) => ({
      key: index,
      sr: index + 1,
      ...timesheets,
      _id,
      ...rest,
    }));

  const fetchTimesheet = (status = "") => {
    run(client(`timesheet/${userId}/view`));
    setTimesheetLoading(true);
  };

  const updateTimesheet = (timesheetId, updates = {}) => {
    run(
      client(`timesheet/${timesheetId}/update`, {
        method: "PATCH",
        body: updates,
      })
    );
  };

  useEffect(() => {
    fetchTimesheet();
  }, []);

  const ignoredFields = [
    "created_at",
    "date",
    "key",
    "sr",
    "updated_at",
    "user",
    "__v",
    "userRemarks",
    "approverRemarks",
    "_id",
    "status",
  ];

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1>Available timesheet</h1>
        {user.role === "ADMIN" ? (
          <Space>
            <Button
              type="ghost"
              onClick={() => setToggleAddProject(!toggleAddProject)}
            >
              Assign Project
            </Button>
          </Space>
        ) : null}{" "}
      </div>
      <Table
        loading={timesheetLoading}
        data={transformTableData(timesheet)}
        columns={columns()}
        size="middle"
        paginationConfig={{ defaultPageSize: 50 }}
      />
      {showModal && (
        <Modal
          title="Update timesheet"
          visible={showModal}
          onCancel={() => {
            setCurrentRecord({});
            setShowModal(!showModal);
          }}
        >
          <Form
            size="middle"
            layout="horizontal"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              ...currentRecord,
            }}
            onFinish={(values) => {
              updateTimesheet(currentTimesheet, {
                ...values,
                status: currentStatus,
              });
              setUpdateLoading(true);
            }}
          >
            {Object.keys(currentRecord)
              .filter((_) => !ignoredFields.includes(_))
              .map((field) => (
                <Form.Item label={field} name={field}>
                  <Input disabled={field.endsWith("name")} />
                </Form.Item>
              ))}
            <Form.Item label="status" name="status">
              <Select>
                <Select.Option value="APPROVED">APPROVED</Select.Option>
                <Select.Option value="APPROVED">REJECTED</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Approver Remarks" name="approverRemarks">
              <Input.TextArea />
            </Form.Item>
            <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }}>
              <Button type="primary" htmlType="submit">
                Update{" "}
                {updateLoading && (
                  <span style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                    <Spinner />
                  </span>
                )}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
      <AssignProject
        modal={toggleAddProject}
        setModal={setToggleAddProject}
        handleCancel={() => setToggleAddProject(!toggleAddProject)}
        userId={userId}
      />
    </div>
  );
};

export default ManageSheet;
