import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  message,
  Button,
  Select,
  Input,
  DatePicker,
  Checkbox,
  TimePicker,
  Space,
  Table,
  Popconfirm,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import { Spinner } from "../../lib";
import { useAuth } from "../../../context/auth-context";


const TaskList = ({ modal, setModal, records,userId }) => {
  const { isLoading, run, isError, error, data } = useAsync();
  const { user,roleValues } = useAuth();
  const [allRecords, setAllRecords] = useState(records);
  const isAdmin= (user.roleData.includes("ADMIN")) 
  console.log(isAdmin)
  const handleDelete = (user_id,task_id) => {
    // setToDelete(name);
   
    run(client(`qualitative/task/all/admin/delete/${user_id}/${task_id}`, { method: "DELETE"},{body:"task_id"})).then(res => {
      message.success(res.message)
      setModal(false)
      
  });
  };
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Time Spent",
      dataIndex: "timeSpent",
      key: "timeSpent",
      render: (timeSpent) => {
        const { hours, minutes } = timeSpent;
        return `${hours} hours ${minutes} minutes`;
      },
    },
  ];
  return (
    <Modal
      title="View Qualitative Project"
      visible={modal}
      onCancel={() => {
        setModal(false);
      }}
    >
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Time Spent</th>
            {/* condition added by ayush */}
            {   isAdmin &&
            <th>Actions</th>
}
          </tr>
        </thead>
        <tbody>
          {/* delete button added by ayush */}
          {records.map((record, index) => (
            <tr key={index}>
              <td>{record.title}</td>
              <td>{record.description}</td>
              <td>{`${record.timeSpent.hours} hours ${record.timeSpent.minutes} minutes`}</td>
              <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record?._id,userId)}
          >
            {isAdmin &&
          <Button type="danger">
            delete
            {/* Delete {isLoading && toDelete === record.name ? <Spinner /> : ""} */}
          </Button>
}
          </Popconfirm>
            
            </tr>
          ))}
        </tbody>
      </table>
    </Modal>
  );
};

export default TaskList;
