import React, { useState, useEffect } from "react";

import Table from "../../table";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import { Button, Popconfirm, message, Space, Tooltip, Switch } from "antd";
import AddSection from "../manage-sections/add-section";
import { Spinner } from "../../lib";
import { useHistory } from "react-router-dom";
import AddFile from "../manage-sections/add-file";
import AddDoneTask from "../update-file-done";
import ManageFIleAddProject from "./manage-file-add-project";
import { useAuth } from "../../../context/auth-context";

const ManageFile = ({ type }) => {
  const { run, isLoading, data } = useAsync();

  let [users, setUsers] = useState([]);
  let [modal, setModal] = useState(false);
  let [doneModal, setDoneModal] = useState(false);
  let [fileModal, setFileModal] = useState(false);
  let [usersLoading, setUsersLoading] = useState(false);
  const [isFileVisible, setIsFileVisible] = useState(true);
  const { user, roleValues } = useAuth();
  const isAdmin = user.roleData.includes("ADMIN");
  const history = useHistory();
  let [toDelete, setToDelete] = useState("");

  useEffect(() => {
    if (
      data?.message === "Please delete all files from section before delete"
    ) {
      message.error(data.message);
      setToDelete("");
    }
    if (data?.message === "Section removed successfully") {
      message.success(data?.message);
      setToDelete("");
      fetchSections();
    }

    if (data?.message === "Fetched section") {
      if (isAdmin) {
        setUsers(transformTableData(data.data));
      } else {
        setUsers(transformTableData(data.projects));
      }
      setUsersLoading(false);
    }
  }, [data]);

  const handleDelete = ({ name }) => {
    setToDelete(name);
    run(
      client(`manage-file/delete/project/${name}`, {
        method: "DELETE",
        body: { name },
      })
    );
    fetchSections();
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      ellipsis: true,
    },
    {
      title: "Project Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      ellipsis: true,
    },
    // {
    //   title: "Added By",
    //   dataIndex: "fullName",
    //   key: "fullName",
    //   sorter: true,
    //   ellipsis: true,
    // },
    {
      title: "Actions",
      key: "x",
      dataIndex: "",
      render: (record) => (
        <>
          <Space>
            <Button
              type="primary"
              onClick={() => history.push({
                pathname: `/manage-file/${record.key}`,
                state: { prev_record: record }
              })}
            >
              Show Detail
            </Button>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              {isAdmin && (
                <Button type="danger">
                  Delete{" "}
                  {isLoading && toDelete === record.name ? <Spinner /> : ""}
                </Button>
              )}
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

  const transformTableData = (data) => {
    return data.map(({ _id, project_name, is_file_visible }, index) => ({
      key: _id,
      name: project_name,
      isFileVisible: is_file_visible,
    }));
  };

  const fetchSections = (query) => {
    run(client(`manage-file/all/projects`));
    setUsersLoading(true);
  };

  useEffect(() => {
    fetchSections();
  }, []);

  const handleCancel = (e) => {
    setModal(false);
  };

  const showModal = () => setModal(true);
  const showFileModal = () => setFileModal(true);

  const handleCancelDone = (e) => {
    setDoneModal(false);
  };

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>Manage Projects</h1>
        {isAdmin && (
          <Button
            type="ghost"
            onClick={showModal}
            style={{
              marginLeft: "auto",
              marginRight: "0.3rem",
            }}
          >
            Add Project
          </Button>
        )}
      </div>
      <Table
        pagination={{
          //  pagination added by ayush
          pageSize: 100,
        }}
        loading={usersLoading}
        data={users}
        columns={columns}
        size="middle"
      />
      <ManageFIleAddProject
        modal={modal}
        handleCancel={handleCancel}
        fetchSections={fetchSections}
        setModal={setModal}
      />
    </div>
  );
};

export default ManageFile;
