import React, { useState, useEffect } from "react";
import Table from "../../table";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  message,
  Form,
  Select,
  Modal,
  InputNumber,
  Radio,
  Checkbox,
  Input,
  Space,
} from "antd";
import { Spinner } from "../../lib";
import Filter from "../../filter";
import moment from "moment";
import "./file.css";
import AddRows from "../update-file";
import AddDoneTask from "../update-file-done";
import { useAuth } from "../../../context/auth-context";
import { useParams } from "react-router-dom";

const File = ({
  match: {
    params: { sectionName, filename, department },
  },
}) => {
  const { run, error, data } = useAsync();
  const {user}= useAuth();
  let [rows, setRows] = useState([]);
  let [modalStatus, setModalStatus] = useState(false)
  let [doneModal, setDoneModal] = useState(false)
  let [modal, setModal] = useState(false);
  let [contentLoading, setContentLoading] = useState(false);
  let [usersLoading, setUsersLoading] = useState(false);
  let [roleusers, setRoleusers] = useState([]);
  let [columns, setColumns] = useState([]);
  let [role, setRole] = useState("");
  let [qsection, setQsection] = useState("");
  let [unqSections, setUnqSections] = useState([]);
  let [totalRows, setTotalRows] = useState("");
  let [assignLoading, setAssignLoading] = useState(false);
  let [assignBy, setAssignBy] = useState("range");
  let [selectedusers, setSelectedUsers] = useState({});
  const [assignMode, setAssignMode] = useState("checked");
  const [checkedRows, setCheckedRows] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [unassignMode, setUnassignMode] = useState("checked");
  const [unassignModal, setUnassignModal] = useState(false);
  const [unassignLoading, setUnassignLoading] = useState(false);
  const [page_no, setPageNo] = useState(1);
  const [limit, setLimit] = useState(100000);//added by ayush
  const [searchText, setSearchText] = useState("");
  const [form] = Form.useForm();
  const [unAssignForm] = Form.useForm();
  const [assignCheckedForm] = Form.useForm();
  const [unassignCheckedForm] = Form.useForm();
  const [searchFields, setSearchFields] = useState([]);
  const [userData, setUserData]= useState("");
  const [allocationData, setAllocation] = useState('')

  const qNumSearch = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search question`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleCancelStatus = (e) => {
    setModalStatus(false);
  };

  const handleCancelDone = (e) => {
    setDoneModal(false);
  };

  const setCols = (rows) => {
    let cols = rows[0]
      ? Object.keys(rows[0])
          .filter((_) => !!_)
          .map((col) => {
            if (col === "sr_no") {
              return {
                title: col,
                key: col,
                dataIndex: col,
                width: 70,
                fixed: true,
              };
            }
            if (col === "question_code" || col === "question_id") {
              return {
                title: col,
                key: col,
                dataIndex: col,
                width: 120,
                fixed: true,
                filterIcon: (filtered) => (
                  <SearchOutlined
                    style={{ color: filtered ? "#1890ff" : undefined }}
                  />
                ),
                ...qNumSearch(col),
              };
            }
            if (["link", "question_link", "solution_link"].includes(col)) {
              return {
                title: col,
                key: "x",
                width: 150,
                ellipsis: true,
                render: (record) => (
                  <a target="_blank" href={record[col]}>
                    {record[col]}
                  </a>
                ),
              };
            } else if (col.endsWith("date")) {
              return {
                title: col,
                key: col,
                width: 190,
                render: (record) => (
                  <div
                    style={{ wordWrap: "break-word", wordBreak: "break-word" }}
                  >
                    {record[col]
                      ? moment(record[col]).format("DD/MM/YYYY hh:mm a")
                      : ""}
                  </div>
                ),
              };
            } 
            else if (col === "author_skipped_date") {
              return {
                title: col,
                key: col,
                width: 190,
                render: (record) => (
                  // <div
                  //   style={{ wordWrap: "break-word", wordBreak: "break-word" }}
                  // >
                  //   {record[col]
                  //     ? moment(record[col]).format("DD/MM/YYYY hh:mm a")
                  //     : ""}
                  // </div>
                  <>Some Data</>
                ),
              };
            } 
            else if (col.endsWith("status")) {
              return {
                title: col,
                dataIndex: col,
                key: col,
                width: 140,
              };
            } else if (
              [
                "chapter",
                "section",
                "page",
                "q_number",
                "q_part",
                "q_type",
              ].includes(col)
            ) {
              return {
                title: col,
                dataIndex: col,
                key: col,
                width: 100,
              };
            } else {
              return {
                title: col,
                dataIndex: col,
                key: col,
                width: 180,
              };
            }
          })
      : [];

    setColumns(cols);
  };

  useEffect(() => {
    if (data?.message === "Fetched file") {
      setCols(data.sheet);
      setUnqSections(data.sections);
      setRows(transformTableData(data.sheet));
      setTotalRows(data.rows);
      setContentLoading(false);
    }

    if (data?.message === "Fetched IDs") {
      setAllocation(data?.allocationData);
    }


    if (data?.message === "Fetched users") {
      setUsersLoading(false);
      let users = data?.users?.map((_) => _.email);
      setRoleusers(users);
    }

    if (data?.message === "Assigned tasks") {
      fetchFile(filename, page_no, limit);
      message.success(data.message);
      setModal(false);
      setAssignLoading(false);
      setSelectedUsers({});
      setCheckedRows([]);
      setAssignBy("range");
    }

    if (data?.message === "Unassigned tasks") {
      fetchFile(filename, page_no, limit);
      message.success(data.message);
      setUnassignModal(false);
      setUnassignLoading(false);
      setCheckedRows([]);
      setAssignBy("range");
    }
  }, [data]);

  useEffect(() => {
    if (error?.message === "Section not found") {
      setContentLoading(false);
      message.error(error.message);
    }

    if (error?.message === "File not found") {
      setContentLoading(false);
      message.error(error.message);
    }

    if (error?.message?.includes("already assigned")) {
      setContentLoading(false);
      setAssignLoading(false);
      setSelectedUsers({});
      message.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    fetchFile(filename, page_no, limit, searchText);
  }, [page_no, limit, searchText, searchFields]);

  const fetchRoleusers = (val) => {
    run(client(`admin/users/${department}?role=${val}`));
    setUsersLoading(true);
  };

  const fetchAllocations = () => {
    run(client(`allocation/allData/allocation/${sectionName}/${userData}`));
  };

  const handleRoleChange = (value) => {
    fetchRoleusers(value);
    setRole(value);
  };

  const handleUserSelect = (value) => setRoleusers([]);

  const handleQsectionChange = (value) => setQsection(value);

  const transformTableData = (data) => {
    if (data[0] && data[0].sr_no) {
      let d = data.map((row, index) => ({
        key: index,
        ...row,
      }));
      return d;
    } else {
      return [];
    }
  };

  const fetchFile = (
    filename,
    page_no = "",
    limit = "",
    question_id = ""
  ) => {
    run(
      client(
        `file/${filename}/${sectionName}/${department}?page_no=${page_no}&limit=${limit}&question_id=${question_id}`,
        { body: { searchFields }, method: "POST" }
      )
    );
    setContentLoading(true);
  };

  const assignTasks = (val) => {
    run(
      client(`/file/${filename}/assign`, { body: { ...val }, method: "POST" })
    );
  };

  const unassignTasks = (val) => {
    run(
      client(`/file/${filename}/unassign`, { body: { ...val }, method: "POST" })
    );
  };

  useEffect(() => {
    fetchFile(filename, page_no, limit);

  }, [modalStatus,doneModal]);

  useEffect(()=>{
   fetchAllocations()
  },[userData])

  const handlePage = (page_no, limit) => {
    setPageNo(page_no);
    setLimit(limit);
    setCheckedKeys([]);
    setCheckedRows([]);
    // fetchFile(filename, page_no, limit);
  };

  const changePageSize = (page_no, limit) => {
    setPageNo(1);
    setLimit(limit);
    setCheckedRows([]);
    setCheckedKeys([]);
    // fetchFile(filename, 1, limit);
  };

  const closeAssignModal = () => {
    setSelectedUsers({});
    resetAssign();
    setModal(false);
    setAssignMode("nonchecked");
  };

  const closeUnassignModal = () => {
    setUnassignModal(false);
  };

  const selectUser = (role, value) => {
    let users = { ...selectedusers };
    users[role] = value;
    setSelectedUsers(users);
    handleUserSelect(value);
  };

  const handleSubmit = (val) => {
    const { start, end, section, ...selectedRoles } = val;
    if (section) {
      assignTasks({ section, ...selectedRoles });
    } else if (start) {
      assignTasks({ start, end, ...selectedRoles });
    } else if (checkedRows.length) {
      let checked = checkedRows;
      assignTasks({ checked, ...selectedRoles });
    }
    setAssignLoading(true);
    resetAssign();
  };

  const handleCheckedSubmit = (val) => {
    Object.keys(val).forEach((key) => {
      if (key.startsWith("checked")) {
        const newKey = key.split("checked-")[1];
        val[newKey] = val[key];

        delete val[key];
      }
    });

    const { section, ...selectedRoles } = val;
    if (checkedRows.length) {
      let checked = checkedRows;
      assignTasks({ checked, ...selectedRoles });
    }
    setAssignLoading(true);
    resetAssign();
  };

  const handleUnassignSubmit = (val) => {
    Object.keys(val).forEach((key) => {
      if (key.startsWith("range-")) {
        const newKey = key.split("range-")[1];

        val[newKey] = val[key];
        delete val[key];
      }
    });

    unassignTasks(val);
    setUnassignLoading(true);
    resetUnAssign();
  };

  const resetUnAssign = () => {
    unAssignForm.resetFields();
  };

  const resetAssign = () => {
    form.resetFields();
    assignCheckedForm.resetFields();
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let rows = selectedRows.map((_) => _.key);
      setCheckedRows(selectedRows.map((_) => _.sr_no));
      setCheckedKeys(rows);
    },
    getCheckboxProps: (record) => ({
      name: record.key,
    }),
    columnWidth: 100,
    fixed: true,
  };

  const submitFilter = (vals) => {
    setPageNo(1);
    setSearchFields(vals);
  };

  const clearFilter = () => {
    setSearchFields([]);
    setLimit(10);
    setPageNo(1);
  };

  const handleUnassignChecked = (vals) => {
    unassignTasks({...vals, checked: checkedRows});
    setUnassignLoading(true);

    // reset form
    unassignCheckedForm.resetFields();
  }

  // console.log(rows)

  return (
    <div>
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>{filename}</h1>
        <span>
          <strong>Total rows: </strong>
          {totalRows}
        </span>
      </div>
      <div
        style={{
          margin: "1rem 0rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Button
            type="primary"
            onClick={() => {
              setAssignMode("checked");
              setModal(true);
            }}
            disabled={!checkedRows.length}
            loading={assignLoading}
          >
            Assign checked
          </Button>{" "}
          <Button
            type="primary"
            onClick={() => {
              setUnassignModal(true);
              setUnassignMode("checked");
            }}
            disabled={!checkedRows.length}
            loading={assignLoading}
          >
            Unassign checked
          </Button>
          <span style={{ marginLeft: "9px" }}>
            {checkedRows.length
              ? `${checkedRows.length} questions selected`
              : ""}
          </span>
        </div>
        <div>
          <Button
            type="primary"
            htmlType="button"
            onClick={() => {
              setUnassignMode("nonchecked");
              setUnassignModal(true);
            }}
          >
            UnAssign Tasks
          </Button>
          <Button
            style={{ marginLeft: "8px" }}
            type="primary"
            htmlType="button"
            onClick={() => {
              setAssignMode("nonchecked");
              setModal(true);
            }}
          >
            Assign Tasks
          </Button>
          {/* <Button
            style={{ marginLeft: "8px" }}
            type="primary"
            htmlType="button"
            onClick={()=>setModalStatus(true)}
          >
            Add Rows
          </Button>
          <Button
            style={{ marginLeft: "8px" }}
            type="primary"
            htmlType="button"
            onClick={()=>setDoneModal(true)}
          >
            Add Done Task
          </Button> */}
        </div>
      </div>
      <div style={{ margin: "1rem 0" }}>
        <Filter
          columnKeys={columns}
          onFilterClear={clearFilter}
          onFilterSubmit={submitFilter}
        />
      </div>
      <div style={{ display: "block", overflow: "auto" }}>
        <Table
          rowSelection={{
            selectedRowKeys: checkedKeys,
            ...rowSelection,
          }}
          pagination={{
            pageSize: 500,
            onChange: handlePage,
            position: ["bottomCenter"],
            current: page_no,
            total: totalRows,
            showQuickJumper: true,
            pageSizeOptions: ["10", "20", "50", "100", "500"],
            showSizeChanger: true,
            onShowSizeChange: changePageSize,
          }}
          loading={contentLoading}
          data={rows}
          columns={columns}
          size="middle"
        />
      </div>
      <Modal
        title="Unassign tasks"
        visible={unassignModal && unassignMode !== "checked"}
        onCancel={closeUnassignModal}
      >
        <>
          UnAssign by:{" "}
          <Radio.Group
            defaultValue="range"
            buttonStyle="solid"
            onChange={(e) => setAssignBy(e.target.value)}
          >
            <Radio.Button value="range">Range</Radio.Button>
            <Radio.Button value="section">Section</Radio.Button>
          </Radio.Group>{" "}
        </>
        <div style={{ margin: "1rem" }}>
          <Form form={unAssignForm} onFinish={handleUnassignSubmit}>
            {assignBy === "range" ? (
              <>
                <Form.Item
                  name="range-start"
                  label="Start"
                  rules={[
                    { required: true, message: "Please enter start index" },
                  ]}
                >
                  <InputNumber
                    min="1"
                    name="start"
                    placeholder="Start sr. no"
                  />
                </Form.Item>
                <Form.Item
                  name="range-end"
                  label="End"
                  rules={[
                    { required: true, message: "Please enter end index" },
                  ]}
                >
                  <InputNumber min="1" name="end" placeholder="End sr. no" />
                </Form.Item>
              </>
            ) : assignBy === "section" ? (
              <Form.Item
                label="Select section"
                name="section"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  style={{ width: 120 }}
                  placeholder="Sections"
                  optionFilterProp="children"
                  onChange={handleQsectionChange}
                >
                  {unqSections?.map((s) => (
                    <Select.Option value={s}>{s}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : null}
            <Form.Item label="Select role" name="roles">
              <Checkbox.Group
                options={["author", "reviewer", "reviewer-2", "uploader"]}
              />
            </Form.Item>
            <Form.Item>
              <Button
                disabled={unassignLoading}
                type="primary"
                htmlType="submit"
              >
                Submit {unassignLoading ? <Spinner /> : null}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Modal
        title="Assign tasks"
        visible={modal && assignMode !== "checked"}
        confirmLoading={assignLoading}
        onCancel={closeAssignModal}
      >
        {assignBy !== "checked" ? (
          <>
            Assign by:{" "}
            <Radio.Group
              defaultValue="range"
              buttonStyle="solid"
              onChange={(e) => setAssignBy(e.target.value)}
            >
              <Radio.Button value="range">Range</Radio.Button>
              <Radio.Button value="section">Section</Radio.Button>
            </Radio.Group>{" "}
          </>
        ) : null}
        <div style={{ margin: "1rem" }}>
          <Form form={form} onFinish={handleSubmit}>
            {assignBy === "range" ? (
              <>
                <Form.Item
                  name="start"
                  label="Start"
                  rules={[
                    { required: true, message: "Please enter start index" },
                  ]}
                >
                  <InputNumber
                    min="1"
                    name="start"
                    placeholder="Start sr. no"
                  />
                </Form.Item>
                <Form.Item
                  name="end"
                  label="End"
                  rules={[
                    { required: true, message: "Please enter end index" },
                  ]}
                >
                  <InputNumber min="1" name="end" placeholder="End sr. no" />
                </Form.Item>
              </>
            ) : assignBy === "section" ? (
              <Form.Item
                label="Select section"
                name="section"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  style={{ width: 120 }}
                  placeholder="Sections"
                  optionFilterProp="children"
                  onChange={handleQsectionChange}
                >
                  {unqSections?.map((s) => (
                    <Select.Option value={s}>{s}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : null}

            {["AUTHOR", "REVIEWER", "REVIEWER-2", "UPLOADER"].map(
              (currentRole, idx) => (
                <>
                <Form.Item
                  id={`nonchecked-${currentRole}`}
                  key={`nonchecked-role-${idx}`}
                  name={currentRole}
                  label={`Select ${currentRole}`}
                >
                  <Select
                    id={`nonchecked-${currentRole}`}
                    onChange= {(e)=> {
                      selectUser(currentRole, e);
                      setUserData(e)
                    }}
                    onFocus={() => handleRoleChange(currentRole)}
                    onBlur={() => setRoleusers([])}
                    showSearch
                    allowClear
                    loading={currentRole === role && usersLoading}
                    style={{ width: 150 }}
                    // onChange={(val) => selectUser(currentRole, val)}
                    placeholder="Users"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {roleusers?.map((u) => (
                      <Select.Option value={u}>{u}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                </>
              )
            )}
             {
                  userData && 
                <p>Account Ids for  {userData} :  <span style={{ color: "red" }}>{allocationData}</span></p> //color added  by ayush
                }
            <Form.Item>
              <Button disabled={assignLoading} type="primary" htmlType="submit">
                Submit {assignLoading ? <Spinner /> : null}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <Modal
        title="Unassign checked"
        visible={unassignModal && unassignMode === "checked"}
        confirmLoading={unassignLoading}
        onCancel={closeUnassignModal}
      >
        <strong>{checkedRows.length} questions will be unassigned. <br/> Select roles to continue</strong>
        <Form form={unassignCheckedForm} onFinish={handleUnassignChecked}>
          <Form.Item label="Select role" name="roles">
            <Checkbox.Group
              options={["author", "reviewer", "reviewer-2", "uploader"]}
            />
          </Form.Item>
          <Form.Item>
            <Button disabled={unassignLoading} type="primary" htmlType="submit">
              Submit {unassignLoading ? <Spinner /> : null}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Assign checked"
        visible={modal && assignMode === "checked"}
        confirmLoading={assignLoading}
        onCancel={closeAssignModal}
      >
        <div style={{ margin: "1rem" }}>
          <Form form={assignCheckedForm} onFinish={handleCheckedSubmit}>
          {["AUTHOR", "REVIEWER", "REVIEWER-2", "UPLOADER"].map(
              (currentRole, idx) => (
                <>
                <Form.Item
                  id={`nonchecked-${currentRole}`}
                  key={`nonchecked-role-${idx}`}
                  name={currentRole}
                  label={`Select ${currentRole}`}
                >
                  <Select
                    id={`nonchecked-${currentRole}`}
                    onChange= {(e)=> {
                      selectUser(currentRole, e);
                      setUserData(e)
                    }}
                    onFocus={() => handleRoleChange(currentRole)}
                    onBlur={() => setRoleusers([])}
                    showSearch
                    allowClear
                    loading={currentRole === role && usersLoading}
                    style={{ width: 150 }}
                    // onChange={(val) => selectUser(currentRole, val)}
                    placeholder="Users"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {roleusers?.map((u) => (
                      <Select.Option value={u}>{u}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                </>
              )
            )}
             {
                  userData && 
                <p>Account Ids for  {userData} :  <span style={{ color: "red" }}>{allocationData}</span></p> //color added  by ayush
                }
            <Form.Item>
              <Button disabled={assignLoading} type="primary" htmlType="submit">
                Submit {assignLoading ? <Spinner /> : null}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <AddRows
        modalStatus={modalStatus}
        handleCancelStatus={handleCancelStatus}
        setModalStatus={setModalStatus} />
        
        <AddDoneTask
        modalStatus={doneModal}
        handleCancelStatus={handleCancelDone}
        setModalStatus={setDoneModal} />
    </div>
  );
};

export default File;