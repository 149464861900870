import React, { useState, useEffect } from "react";
import "./allocate.css";
import Table from "../../../table";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Button, Space, message } from "antd";
import { useHistory } from "react-router-dom"
import AddProjectModal from "../add-project-modal";

const AddTargetProjects = () => {
  const {
    run,
    isLoading,
    data
  } = useAsync();

  let [project, setProject] = useState([]);
  let [modal, setModal] = useState(false);
  let [usersLoading, setUsersLoading] = useState(false);
  const history = useHistory()


  const handleDelete = async(id)=> {
    await run(client(`projects/delete/${id}`, {method: "DELETE"}))
    await fetchProjects();
  }

  useEffect(() => {
    if (data?.message === "Get Project") {
      setProject(transformTableData(data?.allData))
      setUsersLoading(false)
    }
  }, [data]);

  const columns = [
    {
      title: "Project Name",
      dataIndex: "project_name",
      key: "project_name",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Actions",
      render: (record) => (
        <>
          <Space>
            <Button type="primary" onClick={()=>history.push(`/target/${record?._id}`)}>Show Details</Button>
          </Space>
          <Space style={{marginLeft: '0.8rem'}}>
            <Button type="danger" onClick={()=>handleDelete(record?._id)}>Delete</Button>
          </Space>
        </>
      ),
    },
  ];

  const transformTableData = (data) => {
    return data.map((project , index) => ({ key: index, ...project,  sr: index + 1 }));
  };

  const fetchProjects = () => {
    run(client(`target/all/project`));
    setUsersLoading(true);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const showModal = () => setModal(true);

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>Add Projects</h1>
        <div>
        <Button type="ghost" onClick={()=>setModal(true)} style={{marginRight:'1rem'}}>
          Add Project
        </Button>
        </div>
      </div>
      <Table
        loading={usersLoading}
        columns={columns}
        data={project}
        size="middle"
      />
      <AddProjectModal
        modal={modal}
        fetchProjects={fetchProjects}
        setModal={setModal}
      />
    </div>
  );
};

export default AddTargetProjects;
