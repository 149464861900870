import React, { useState, useEffect } from "react";
import "./details.css";
import Table from "../../../table";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Button, Input, message, Modal, Popconfirm, Space, Switch } from "antd";
import AddFile from "../add-file";
import { Spinner } from "../../../lib";
import { useHistory, Link } from "react-router-dom";
import EditFileName from "./edit-filename-modal";
import axios from "axios";
import { useAuth } from "../../../../context/auth-context";

const SectionDetails = ({
  match: {
    params: { sectionName, department, fileId },
  },
}) => {
  const { run, error, data, isLoading } = useAsync();

  let [users, setUsers] = useState([]);
  let [modal, setModal] = useState(false);
  let [idData, setId] = useState("");
  let [filesLoading, setFilesLoading] = useState(false);
  const [toDelete, setToDelete] = useState("");
  const [editFileName, setEditFileName] = useState("");
  let [editModalVisible, setEditModalVisible] = useState(false);
  const { user } = useAuth();
  const [isChecked, setIsChecked] = useState();
  const isAdmin = user.roleData.includes("ADMIN");

  const handleToggle = (checked, id, dept) => {
    const updatedUsers = users.map((user) => {
      if (user?._id === id) {
        return {
          ...user,
          visi: checked,
        };
      }
      return user;
    });
    setUsers(updatedUsers);

    if (id === idData) {
      setIsChecked(checked);
    }
    run(
      client(`manage-file/update/visibility/particular/file`, {
        method: "PUT",
        body: { isFileVisible: checked, dept: dept, id: id },
      })
    );
  };

  useEffect(() => {
    if (data?.message === "Fetched section") {
      setUsers(transformTableData(data?.data));
      setFilesLoading(false);
    }

    if (data?.message === "File deleted successfully") {
      setToDelete("");
      message.success(data?.message);
      fetchSection(sectionName);
    }
  }, [data]);

  useEffect(() => {
    if (error?.message === "Section not found") {
      setFilesLoading(false);
      message.error(error.message);
    }
  }, [error]);

  const handleFileDelete = ({ name }) => {
    setToDelete(name);
    run(
      client(`file/${name}/delete?section=${sectionName}`, { method: "DELETE" })
    );
  };

  const history = useHistory();

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      ellipsis: true,
    },
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Actions",
      key: "x",
      dataIndex: "",
      render: (record) => (
        <>
          <Space>
            <Button
              type="primary"
              onClick={() =>
                history.push(
                  `/project/${sectionName}/${department}/${record.name}`
                )
              }
            >
              Manage Tasks
            </Button>
            <Button type="link">
              <Link
                to={`/project/${sectionName}/${record.name}/summary/details`}
              >
                View summary
              </Link>
            </Button>

            <Button
              type="link"
              onClick={() => handleEdit(record.name, record._id)}
            >
              Edit
            </Button>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleFileDelete(record)}
            >
              <Button type="danger" disabled={isLoading && toDelete}>
                Delete
                {isLoading && toDelete === record.name ? <Spinner /> : ""}
              </Button>
            </Popconfirm>
            {isAdmin && (
              <Switch
                checkedChildren="View Summary"
                unCheckedChildren="Hide Summary"
                onChange={(checked) =>
                  handleToggle(checked, record._id, department)
                }
                checked={record?.visi}
                style={{ width: "59px" }}
              />
            )}
          </Space>
        </>
      ),
    },
  ];

  const transformTableData = (data) =>
    data.map(({ name, path, _id, dept_file_visibility }, index) => {
      const departmentVisibility = dept_file_visibility?.filter(
        (dep) => dep.department_name.toString() === department
      );
      const visi = departmentVisibility[0]?.isVisible;
      return {
        key: index,
        name,
        _id,
        sr: index + 1,
        visi,
      };
    });

  const fetchSection = (name) => {
    run(client(`section/${sectionName}/${department}?name=${name}`));
    setFilesLoading(true);
  };

  useEffect(() => {
    fetchSection(sectionName);
  }, []);

  // const handleCancel = (e) => {
  //   setModal(false);
  // };
  // const handleCancel = (e) => {
  //   setModal(false);
  //   setEditModalVisible(false);
  // };
  const handleEdit = (fileName, _id) => {
    setId(_id);

    setEditFileName(fileName);

    setEditModalVisible(true);
  };
  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>All Files</h1>
        {/* <Button type="ghost" onClick={showModal}>
          Add File
        </Button> */}
      </div>
      <Table
        loading={filesLoading}
        data={users}
        columns={columns}
        size="middle"
        pagination={{
          pageSize: 100,
        }}
      />
      <EditFileName
        modal={editModalVisible}
        setModal={setEditModalVisible}
        fetchProjects={fetchSection}
        fileId={idData}
        fileName={editFileName}
      />
    </div>
  );
};

export default SectionDetails;
