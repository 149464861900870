import React, { useState, useEffect, useCallback } from "react";
import "../timesheet.css";
import Table from "../../../table";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Button, Popconfirm, message, Space } from "antd";
import { Spinner } from "../../../lib";
import { useHistory } from "react-router-dom";
import Report from "../report";

const UserDetails = ({
  match: {
    params: { userId },
  },
}) => {
  const { run, isLoading, data } = useAsync();

  let [projects, setProjects] = useState([]);
  const [reportModal, setReportModal] = useState(false);
  let [projectsLoading, setProjectsLoading] = useState(false);
  const history = useHistory();

  let [toDelete, setToDelete] = useState("");

  const fetchProjects = useCallback(
    (query) => {
      run(client(`timesheet/user/${userId}/projects`));
      setProjectsLoading(true);
    },
    [run]
  );

  useEffect(() => {
    if (data?.code === "PROJECT_DELETE_ERR") {
      message.error(data.message);
      setToDelete("");
    }

    if (data?.code === "USER_REMOVE_SUCCESS") {
      setToDelete("");
      fetchProjects();
    }

    if (data?.message === "Fetched projects") {
      setProjects(transformTableData(data.data));
      setProjectsLoading(false);
    }
  }, [data, fetchProjects]);

  const handleDelete = ({_id}) => {
    setToDelete(_id);
    run(
      client(`timesheet/${_id}/${userId}/remove`, { method: "PATCH", body: {} })
    );
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      ellipsis: true,
    },
    {
      title: "Project Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Added By",
      dataIndex: "fullName",
      key: "fullName",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Actions",
      key: "x",
      dataIndex: "",
      render: (record) => (
        <>
          <Space>
            <Button
              type="primary"
              onClick={() => history.push(`/timesheet/${record._id}/${userId}/manage`)}
            >
              View timesheet
            </Button>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <Button type="danger">
                Delete{" "}
                {isLoading && toDelete === record.name ? <Spinner /> : ""}
              </Button>
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

  const transformTableData = (data) => {
    return data.map(({ addedBy: { fullName, email }, ...section }, index) => ({
      key: index,
      ...section,
      fullName,
      email,
      sr: index + 1,
    }));
  };

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>Assigned Projects</h1>
        <Space>
          {/* <Button onClick={() => setReportModal(!reportModal)}>
            Generate report
          </Button> */}
        </Space>
      </div>
      <Table
        loading={projectsLoading}
        data={projects}
        columns={columns}
        size="middle"
      />
      <Report
        modal={reportModal}
        handleCancel={() => setReportModal(!reportModal)}
      />
    </div>
  );
};

export default UserDetails;
