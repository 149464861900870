import React, { useEffect } from "react";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import { FullPageSpinner } from "../../lib";
import Card from "../../lib/card";
import { Pie } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { Button, Divider, Row, Col } from "antd";

const UserSummary = ({
  match: {
    params: { userId },
  },
}) => {
  const { run, data } = useAsync();

  const getSummary = (userId) => {
    run(client(`user/summary/${userId}`));
  };

  useEffect(() => {
    getSummary(userId);
  }, []);

  if (data?.data) {
    const {
      details: { accountState, roleData, email, fullName, created_at },
      summary,
    } = data.data;

    return (
      <div style={{ background: "white" }}>
        <div style={{ padding: "1rem" }}>
          <div style={{ clear: "both" }}>
            <div style={{ float: "left" }}>
              <h1>User summary</h1>
            </div>
            <div style={{ float: "right" }}>
              <Link to="/users">
                <Button>Go to Manage Users</Button>
              </Link>
            </div>
          </div>

          <Divider
            orientation="left"
            style={{ color: "#333", fontWeight: "bold", fontSize: "1.5rem" }}
          >
            Details
          </Divider>
          <div style={{ marginLeft: "2rem" }}>
            <Row gutter={10} style={{ fontSize: "1.5rem" }}>
              <Col className="gutter-row" span={6}>
                <div>
                  <strong>Account State</strong>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div>{accountState}</div>
              </Col>
            </Row>
            <Row gutter={10} style={{ fontSize: "1.5rem" }}>
              <Col className="gutter-row" span={6}>
                <div>
                  <strong>Role</strong>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div>{roleData[0].toLowerCase()}</div>
              </Col>
            </Row>
            <Row gutter={10} style={{ fontSize: "1.5rem" }}>
              <Col className="gutter-row" span={6}>
                <div>
                  <strong>Email</strong>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div>{email}</div>
              </Col>
            </Row>
            <Row gutter={10} style={{ fontSize: "1.5rem" }}>
              <Col className="gutter-row" span={6}>
                <div>
                  <strong>Full name</strong>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div>{fullName}</div>
              </Col>
            </Row>
            <Row gutter={10} style={{ fontSize: "1.5rem" }}>
              <Col className="gutter-row" span={6}>
                <div>
                  <strong>Added on</strong>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div>{new Date(created_at).toLocaleString("en-US")}</div>
              </Col>
            </Row>
          </div>
          <Divider
            orientation="left"
            style={{ color: "#333", fontWeight: "bold", fontSize: "1.5rem" }}
          >
            Summary
          </Divider>
          {Object.keys(summary).map((file) => {
            const chartData = summary[file]
              .map(({ status, count }) => ({
                label: status,
                angle: count,
              }))
              .filter((_) => _.label !== "alloted");

            const pie = {
              labels: chartData.map((_) => _.label),
              datasets: [
                {
                  data: chartData.map((_) => _.angle),
                  backgroundColor: [
                    "#003f5c",
                    "#2f4b7c",
                    "#665191",
                    "#a05195",
                    "#d45087",
                    "#f95d6a",
                    "#ff7c43",
                    "#ffa600",
                  ],
                  hoverBackgroundColor: [
                    "#003f5c",
                    "#2f4b7c",
                    "#665191",
                    "#a05195",
                    "#d45087",
                    "#f95d6a",
                    "#ff7c43",
                    "#ffa600",
                  ],
                },
              ],
            };
            return (
              <>
                <span>
                  <h2>{file}</h2>{" "}
                  <Link to={`/section/-/${file}`}>
                    <Button type="primary">Go to File</Button>
                  </Link>
                </span>
                <Row>
                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        padding: "1rem",
                        justifyContent: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      {summary[file].map(({ status, count }) => (
                        <Card title={status} value={count} />
                      ))}
                    </div>
                  </Col>
                  <Col span={12}>
                    <div style={{ width: "100%", height: "100%" }}>
                      <Pie
                    options={{
                      legend: {
                        position: "bottom",
                      },
                    }}
                    data={pie}
                  />
                    </div>
                  </Col>
                </Row>
              </>
            );
          })}
        </div>
      </div>
    );
  } else {
    return <FullPageSpinner />;
  }
};

export default UserSummary;
