import React, { useState, useEffect } from "react";
import Table from "../../table";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import { Link } from "react-router-dom"

const UserRecruit = () => {
  const { run, data } = useAsync();

  const [jobs, setJobs] = useState([]);
  const [jobsLoading, setJobsLoading] = useState(false);

  useEffect(() => {
    if (data?.code === "JOB_FETCH_SUCCESS") {
      setJobs(data.data);
      setJobsLoading(false);
    }
  }, [data]);

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      ellipsis: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
    },
    {
      title: "Vacancies",
      dataIndex: "vacancy",
      key: "vacancy",
    },
    {
      title: "Shift timings",
      dataIndex: "shiftTime",
      key: "shiftTime",
    },
    {
      title: "Actions",
      key: "x",
      dataIndex: "",
      render: (record) => (
        <>
          <Link to={`recruit/${record._id}/add`}>Add candidates</Link>
        </>
      ),
    },
  ];

  const transformTableData = (data, targetId) =>
    data.map(({ date, ...jobs }, index) => ({
      key: index,
      date: new Date(date).toLocaleDateString(),
      sr: index + 1,
      targetId,
      ...jobs,
    }));

  const fetchJobs = () => {
    run(client(`recruit/jobs/user`));
    setJobsLoading(true);
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1>Job profiles</h1>
      </div>
      <Table
        size="middle"
        loading={jobsLoading}
        columns={columns}
        data={transformTableData(jobs)}
      />
    </div>
  );
};

export default UserRecruit;
