import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  message,
  Button,
  Select,
  Input,
  DatePicker,
  Checkbox,
  TimePicker,
  Space,
  InputNumber,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import { Spinner } from "../../lib";

const AddQualitative = ({ modal, setModal, fetchRecords }) => {
  const { isLoading, run, isError, error, data } = useAsync();
  const [userForm] = Form.useForm();
  const [formItems, setFormItems] = useState([{ id: 0 }]);
  const [totalTime, setTotalTime] = useState("00:00");

  const addFormItem = () => {
    const newItemId = formItems.length;
    setFormItems([...formItems, { id: newItemId }]);
  };

  const removeFormItem = (id) => {
    const updatedFormItems = formItems.filter((item) => item.id !== id);
    setFormItems(updatedFormItems);
  };

  const addTask = async (val) => {
    let set = {};
    const descriptionArray = val.descriptions;
    const titleArray = val.titles;
    const hoursArray = val.hours;
    const minutesArray = val.minutes;
    const effectiveDate = val.effectiveDate;
    let taskList = [];

    for (
      let i = 0;
      i <
      Math.min(
        descriptionArray.length,
        titleArray.length,
        hoursArray.length,
        minutesArray.length
      );
      i++
    ) {
      let timeSpent = {};
      timeSpent["hours"] = hoursArray[i];
      timeSpent["minutes"] = minutesArray[i];
      let task = {
        title: titleArray[i],
        description: descriptionArray[i],
        timeSpent: timeSpent,
      };
      taskList.push(task);
    }

    set["effectiveDate"] = effectiveDate;
    set["taskList"] = taskList;
    await run(client("qualitative/add", { body: set, method: "POST" })).then(
      (res) => {
        if (res.message === "Effectice Date Already Exist") {
          message.error(res.message);
        } else {
          message.success(res.message);
        }
        setModal(false);
      }
    );
    userForm.resetFields();
    fetchRecords();
  };

  function getMinDate() {
    const today = new Date();
    const pastDate = new Date(today);
    pastDate.setDate(today.getDate() - 3);
    return pastDate.toISOString().split("T")[0];
  }
  function getMaxDate() {
    return new Date().toISOString().split("T")[0];
  }
  //added by ayush
  const handleTimeChange = (changedValues, allValues) => {
    const parsedTasks = allValues.titles.map((title, index) => {
      const hours = parseInt(allValues.hours[index]) || 0;
      const minutes = parseInt(allValues.minutes[index]) || 0;
      return { title, hours, minutes };
    });

    const totalHours = parsedTasks.reduce((acc, task) => acc + task.hours, 0);
    const totalMinutes = parsedTasks.reduce(
      (acc, task) => acc + task.minutes,
      0
    );

    const hours = Math.floor(totalHours + totalMinutes / 60);
    const minutes = totalMinutes % 60;

    setTotalTime(
      `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`
    );
  };

  return (
    <Modal
      title="Add Qualitative Project"
      visible={modal}
      onCancel={() => {
        userForm.resetFields();
        setModal(false);
      }}
    >
      <Form
        onValuesChange={handleTimeChange} //added by ayush
        form={userForm}
        size="middle"
        layout="horizontal"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={addTask}
      >
        <Form.Item
          label="Effective Date"
          name="effectiveDate"
          rules={[{ required: true, message: "Please enter a effective date" }]}
        >
          <Input
            name="effectiveDate"
            type="date"
            max={getMaxDate()}
            min={getMinDate()}
          />
        </Form.Item>

        {formItems.map((item, index) => (
          <Space
            key={item.id}
            style={{ display: "grid", marginBottom: 8 }}
            align="baseline"
          >
            {index === 0 ? (
              <Button
                type="dashed"
                onClick={addFormItem}
                style={{ width: "4rem" }}
              >
                Add
              </Button>
            ) : (
              <MinusCircleOutlined onClick={() => removeFormItem(item.id)} />
            )}
            <Form.Item
              label="Title"
              name={["titles", index]}
              rules={[{ required: true, message: "Please enter a title" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Description"
              name={["descriptions", index]}
              rules={[
                { required: true, message: "Please enter a description" },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
            {/* chnages by ayush from 152 to 211 */}
            <Form.Item
              label="Hours"
              name={["hours", index]}
              rules={[
                {
                  required: true,
                  message: "Please enter Hour",
                },
                {
                  validator: (_, value) => {
                    if (!isNaN(value) && value >= 0 && value <= 23) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "Please enter a valid hour between 0 and 23"
                    );
                  },
                },
                {
                  validator: (_, value) => {
                    if (!isNaN(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Please enter a numeric value");
                  },
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Minutes"
              name={["minutes", index]}
              rules={[
                {
                  required: true,
                  message: "Please enter minutes",
                },
                {
                  validator: (_, value) => {
                    if (!isNaN(value) && value >= 0 && value <= 59) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "Please enter a valid hour between 0 and 59"
                    );
                  },
                },
                {
                  validator: (_, value) => {
                    if (!isNaN(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Please enter a numeric value");
                  },
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Space>
        ))}
        {/* added by ayush */}
        <p style={{ marginTop: "10px  ", marginLeft: "30%" }}>
          Total Time: {totalTime}
        </p>

        <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }}>
          <Button type="primary" htmlType="submit">
            Submit{" "}
            {isLoading && (
              <span style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                <Spinner />
              </span>
            )}
          </Button>
        </Form.Item>
        {isError && <p style={{ color: "red" }}>{error.message}</p>}
      </Form>
    </Modal>
  );
};

export default AddQualitative;
