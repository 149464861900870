import React, { useEffect, useState } from "react";
import { useAsync } from "../../../../utils/use-async";
import { client, fileClient } from "../../../../utils/api-client";
import { useAuth } from "../../../../context/auth-context";
import { Button, Form, DatePicker, Select } from "antd";
import Table from "../../../table";
import { Spinner } from "../../../lib";
import Filter from "../../../filter"

const ViewReport = ({
  type,
  match: {
    params: { filename },
  },
  ...props
}) => {
  const getType = (href) => {
    return href.split("/")[2];
  };

  type = !type ? getType(props.location.pathname) : type;

  const { run, data } = useAsync();
  const { user,roleValues } = useAuth();
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";

  const [typeUsers, setTypeUsers] = useState([]);
  const [rows, setRows] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [value, setValue] = useState("");
  const [selectedRole, setSelectedRole] = useState(type);
  const [reportLoading, setReportLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [start, setStartDate] = useState("");
  const [end, setEndDate] = useState("");
  const [page_no, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRows, setTotalRows] = useState("");
  const [downloading, setDownloading] = useState(false);
  const [downloadingFile, setDownloadingFile] = useState(false);
  const [filterForm] = Form.useForm();
  const [searchFields, setSearchFields] = useState([])

  const fetchReport = async (filename, body) => {
    run(
      client(`report/view/${filename}/${roleValues}`, { body: { ...body, page_no, limit, searchFields } })
    );
    setReportLoading(true);
  };
  

  const fetchTypeUsers = (type, filename) => {
    run(client(`file/${filename}/users?type=${type}`));
    setUsersLoading(true);
  };

  const setCols = (rows) => {
    let cols = rows[0]
      ? Object.keys(rows[0])
          .filter((_) => !!_)
          .map((col) => {
            if (col === "sr_no") {
              return {
                title: col,
                key: col,
                dataIndex: col,
                width: 70,
                fixed: true,
              };
            }
            if (col === "question_code") {
              return {
                title: col,
                key: col,
                dataIndex: col,
                width: 120,
                fixed: true,
              };
            }
            if (col === "link") {
              return {
                title: col,
                key: "x",
                width: 50,
                render: (record) => (
                  <a target="_blank" href={record.link}>
                    link
                  </a>
                ),
              };
            } else if (col.endsWith("status")) {
              return {
                title: col,
                dataIndex: col,
                key: col,
                width: 140,
              };
            } else if (
              [
                "chapter",
                "section",
                "page",
                "q_number",
                "q_part",
                "q_type",
              ].includes(col)
            ) {
              return {
                title: col,
                dataIndex: col,
                key: col,
                width: 100,
              };
            } else {
              return {
                title: col,
                dataIndex: col,
                key: col,
                width: 180,
              };
            }
          })
      : [];

    setColumns(cols);
  };

  const transformTableData = (data) => {
    if (data[0] && data[0].sr_no) {
      let d = data.map((row, index) => ({
        key: index,
        ...row,
      }));
      return d;
    } else {
      return [];
    }
  };

  useEffect(() => {
    fetchReport(filename, {
      selectedRole,
      value,
      start,
      end,
    });

    if (isAdminOrManager) {
      fetchTypeUsers(type, filename);
    }
  }, []);

  useEffect(() => {
    fetchReport(filename, {
      selectedRole,
      value,
      start,
      end,
    });
  }, [page_no, limit, searchFields]);

  useEffect(() => {
    if (data?.message === "fetched users") {
      setTypeUsers(data.users);
      setUsersLoading(false);
    }

    if (data?.message === "Generated report") {
      setCols(data.data);
      setRows(transformTableData(data.data));
      setTotalRows(data.totalRows);
      setReportLoading(false);
    }

    if (data?.type?.startsWith("application")) {
      var url = window.URL.createObjectURL(data);
      var a = document.createElement("a");
      a.href = url;
      if (downloadingFile) {
        a.download = "file.xlsx";
        setDownloadingFile(false);
      } else {
        a.download = "report.xlsx"
        setDownloading(false);
      }

      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    }
  }, [data]);

  const downloadReport = async (filename, body) => {
    setDownloading(true);
    run(fileClient(`report/download/${filename}/${roleValues}`, { body }));
  };

  const handleAdminFilterSubmit = ({ value, range }) => {
    const start = range?.[0].startOf("day").format();
    const end = range?.[1].endOf("day").format();

    setValue(value);
    setSelectedRole(type);
    setStartDate(start);
    setEndDate(end);
    setPageNo(1);
    setLimit(10);

    fetchReport(filename, {
      selectedRole,
      value,
      start,
      end,
    });
  };

  const handleAdminFilterClear = () => {
    setValue("");
    setSelectedRole(type);
    setStartDate("");
    setEndDate("");
    setPageNo(1);
    setLimit(10);
    filterForm.resetFields();
    fetchReport(filename, { selectedRole: type });
  };

  const handlePage = (page_no, limit) => {
    setPageNo(page_no);
    setLimit(limit);
  };

  const changePageSize = (page_no, limit) => {
    setPageNo(1);
    setLimit(limit);
  };

  const handleReportDownload = () => {
    downloadReport(filename, {
      selectedRole,
      value,
      start,
      end,
      searchFields
    });
  };

  const isAdminOrManager = user.role === "ADMIN" || user.role === "MANAGER";

  const submitFilter = vals => {
    setPageNo(1)
    setSearchFields(vals)
  }

  const clearFilter = () => {
    setSearchFields([])
    setLimit(10)
    setPageNo(1)
  }

  const downloadFile = async (filename) => {
    setDownloadingFile(true);
    run(fileClient(`file/download/${filename}`));
  };


  const UserFilter = () => {
    return (
      <div style={{ margin: "1rem" }}>
        <Form
          form={filterForm}
          onFinish={handleAdminFilterSubmit}
          name="filter_form"
          layout="inline"
        >
          {isAdminOrManager ? (
            <Form.Item name="value" required>
              <Select
                allowClear
                loading={usersLoading}
                placeholder={`Select ${type}`}
                style={{ width: "250px" }}
              >
                {typeUsers?.map((_) => (
                  <Select.Option key={_} value={_}>
                    {_}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : null}
          <Form.Item name="range" label="select range">
            <RangePicker format={dateFormat} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Apply filter
            </Button>
            <Button
              type="dashed"
              htmlType="button"
              onClick={handleAdminFilterClear}
            >
              Clear
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  };

  return (
    <div style={{ background: "white", padding: "1rem" }}>
      <div style={{ clear: "both", height: "50px" }}>
        <div style={{ float: "left" }}>
          <h1>Report</h1>
        </div>
        <div style={{ float: "right" }}>
          <Button type="primary" onClick={handleReportDownload}>
            {downloading ? (
              <>
                Downloading{" "}
                <span style={{ marginLeft: "4px" }}>
                  <Spinner />
                </span>
              </>
            ) : (
              "Download Report"
            )}
          </Button>
          <Button type="dashed" onClick={() => downloadFile(filename)}>
            {downloadingFile ? (
              <>
                Downloading{" "}
                <span style={{ marginLeft: "4px" }}>
                  <Spinner />
                </span>
              </>
            ) : (
              "Download full book"
            )}
          </Button>
        </div>
      </div>
      <div style={{ minHeight: "50px" }}>
      {type !== "book" ? <UserFilter /> : <Filter onFilterClear={clearFilter} onFilterSubmit={submitFilter} columnKeys={columns} />}
      </div>

      <div style={{ display: "block", overflow: "auto" }}>
        <Table
          pagination={{
            onChange: handlePage,
            position: ["bottomCenter"],
            current: page_no,
            total: totalRows,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            showQuickJumper: true,
            pageSizeOptions: ["10", "20", "50", "100", "500"],
            showSizeChanger: true,
            onShowSizeChange: changePageSize,
          }}
          loading={reportLoading}
          data={rows}
          columns={columns}
          size="middle"
        />
      </div>
    </div>
  );
};

export default ViewReport;
