import React from "react";
import { Modal, Form, Input, message, Button } from "antd";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Spinner } from "../../../lib"
import { useAuth } from "../../../../context/auth-context";

const AddProjectModal = ({ modal, fetchProjects, setModal }) => {
    const { isLoading, run, isError, error } = useAsync();
    const { user } = useAuth();
    const [userForm] = Form.useForm();

    const addSection = (values) => {
        run(client("project/target/add", { body: { ...values, addedBy: user._id } }).then(res => {
            message.success(res.message)
            setModal(false)
            fetchProjects();
        }))};

    const handleCancel = () => {
        setModal(false);
        userForm.resetFields();
    }

    return (
        <Modal title="Add Project" visible={modal} onCancel={handleCancel}>
            <Form
                form={userForm}
                size="middle"
                layout="horizontal"
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 16,
                }}
                onFinish={addSection}
            >
                <Form.Item
                    label="Project Name"
                    name="project_name"
                    rules={[
                        {
                            required: true,
                            message: "Please input project name!",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }}>
                    <Button type="primary" htmlType="submit">
                        Submit{" "}
                        {isLoading && (
                            <span style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                                <Spinner />
                            </span>
                        )}
                    </Button>
                </Form.Item>
                {isError && <p style={{ color: "red" }}>{error.message}</p>}
            </Form>
        </Modal>
    );
};

export default AddProjectModal;
