import React, { useState, useEffect } from "react";
import "./worklog.css";
import Table from "../../table";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import { Button, Popconfirm, message, Space } from "antd";
import AddProject from "./add-project";
import { Spinner } from "../../lib";
import { useHistory } from "react-router-dom";
import Report from "./report";

const Projects = ({ type }) => {
  const { run, isLoading, data } = useAsync();

  let [users, setUsers] = useState([]);
  let [modal, setModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  let [usersLoading, setUsersLoading] = useState(false);
  const history = useHistory();

  let [toDelete, setToDelete] = useState("");

  useEffect(() => {
    if (data?.code === "PROJECT_DELETE_ERR") {
      message.error(data.message);
      setToDelete("");
    }

    if (data?.message === "Project removed successfully") {
      message.success(data?.message);
      setToDelete("");
      fetchProjects();
    }

    if (data?.message === "Fetched projects") {
      setUsers(transformTableData(data.projects));
      setUsersLoading(false);
    }
  }, [data]);

  const handleDelete = ({ _id }) => {
    setToDelete(_id);
    run(client(`project/${_id}/delete`, { method: "DELETE", body: {} }));
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      ellipsis: true,
    },
    {
      title: "Project Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Added By",
      dataIndex: "fullName",
      key: "fullName",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Actions",
      key: "x",
      dataIndex: "",
      render: (record) => (
        <>
          <Space>
            <Button
              type="primary"
              onClick={() => history.push(`/worklog/${record._id}`)}
            >
              Show Details
            </Button>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <Button type="danger">
                Delete{" "}
                {isLoading && toDelete === record.name ? <Spinner /> : ""}
              </Button>
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

  const transformTableData = (data) => {
    return data.map(({ addedBy: { fullName, email }, ...section }, index) => ({
      key: index,
      ...section,
      fullName,
      email,
      sr: index + 1,
    }));
  };

  const fetchProjects = (query) => {
    run(client(`project/all`));
    setUsersLoading(true);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleCancel = (e) => {
    setModal(false);
  };

  const showModal = () => setModal(true);

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>Manage Projects</h1>
        <Space>
          <Button onClick={() => setReportModal(!reportModal)}>
            Generate report
          </Button>
          <Button type="ghost" onClick={showModal}>
            Add Project
          </Button>
        </Space>
      </div>
      <Table
        loading={usersLoading}
        data={users}
        columns={columns}
        size="middle"
      />
      <AddProject
        modal={modal}
        handleCancel={handleCancel}
        fetchProjects={fetchProjects}
        setModal={setModal}
      />
      <Report
        modal={reportModal}
        handleCancel={() => setReportModal(!reportModal)}
      />
    </div>
  );
};

export default Projects;
