import locale from 'antd/es/date-picker/locale/hi_IN';
import React, { useEffect, useState } from "react";
import { Modal, Form, message, Button, DatePicker, Checkbox } from "antd";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Spinner } from "../../../lib";
import moment from "moment";
import { getToken } from "../../../../utils/auth-client";
export const url = "https://work.vaidikedu.com"


const RemoveUser = ({
  modal,
  handleCancel,
  setRemoveModal,
  project,
  fetchProject,
  setRemove,
  recordData
}) => {
  const { isLoading, run, isError, error, data } = useAsync();
  const [revokeTime, setRevokeTime] = useState('');
  const [allocateTime, setAllocateTime] = useState('')
  const [checked, setChecked] = useState(false)
  const [userForm] = Form.useForm();
  const fetchAccountIds = async () => {
    const all = await fetch(`${url}/api/v1/timesheet/getAllocations/getOneAllocation/${recordData.email}`, {
      headers: {
        "x-auth-token": getToken()
      }
    }).then((res) => res.json().then((res) => {
      setAllocateTime(res?.allocations?.current_user_allocation_time)
    })).catch(error => console.log(error))
  }

  useEffect(() => {
    fetchAccountIds();
  }, [modal])

  const removeUser = async (values) => {
    const userId = recordData._id;
    await run(client(`timesheet/${project}/${userId}/remove`, { body: values, method: "PATCH" })).then((res) => {
      setRemove(true)
      setRemoveModal(false);
      userForm.resetFields();
      message.success(res.message);
      fetchProject(project)
    }
    )
  };
  const handleChangeWEF = (e) => {
    setRevokeTime(e.target.value)
  };
  const onOk = (value) => {
    console.log("onOk: ", moment().format());
    console.log("onOk: ", typeof value);
    console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
  };

  return (
    <Modal
      title="Remove User"
      visible={modal}
      onCancel={() => {
        handleCancel();
        userForm.resetFields();
      }}
    >
      <Form
        form={userForm}
        size="middle"
        layout="horizontal"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={removeUser}
      >

        <Form.Item
          label="Add Effective Date">
          <Checkbox onChange={(e) => setChecked(e.target.checked)}></Checkbox>
        </Form.Item>
        {
          checked === true &&
          <Form.Item
            label="Date Effective :"
            onChange={handleChangeWEF}
            name='revokeTime'
            rules={[
              {
                required: true,
                message: "Please input the date effective",
              },
            ]}
          >
            <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime={true} onOk={onOk} style={{ width: '100%' }} />
          </Form.Item>
        }

        <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }}>
          <Button type="danger" htmlType="submit">
            Submit{" "}
            {isLoading && (
              <span style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                <Spinner />
              </span>
            )}
          </Button>
        </Form.Item>
        {isError && <p style={{ color: "red" }}>{error.message}</p>}
      </Form>
    </Modal>
  );
};

export default RemoveUser;
