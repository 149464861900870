import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  message,
  Button,
  Select,
  Input,
  DatePicker,
  Checkbox,
} from "antd";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Spinner } from "../../../lib";
import moment from "moment";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
const { Option } = Select;

const AddUser = ({ modal, setModal, accountId, multiple, accountdetails }) => {
  const { isLoading, run, isError, error, data } = useAsync();
  const [users, setUsers] = useState([]);
  const [wef, setWef] = useState(new Date());
  const [addedUsers, setAdded] = useState([]);
  const [addedIds, setAddedIds] = useState("");
  const [departmentData, setDepartments] = useState("");
  const [userData, setUserData] = useState("");
  const { projectId, departmentId } = useParams();
  const [userForm] = Form.useForm();
  
  const fetchUsers = () => {
    run(client(`timesheet/users/${departmentId}`));
  };

  const fetchSingleDepartment = () => {
    run(client(`department/one/${departmentId}`));
  };

  const fetchAllAlocationIDs = async () => {
    await run(client(`allocation/users/allData/${userData}`));
  };

  const handleChangeWEF = (value) => {
    setWef(value);
  };

  const onOk = (value) => {
    console.log("onOk: ", moment().format());
    console.log("onOk: ", typeof value);
    console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
  };

  useEffect(() => {
    fetchUsers();
    fetchSingleDepartment();
  }, [modal]);

  useEffect(() => {
    fetchAllAlocationIDs();
  }, [userData]);

  const addUser = async (values) => {
    const body = { ...values, accountId, multiple };

    await run(
      client(`timesheet/project/${projectId}/user/add`, {
        body,
        method: "PUT",
      }).then((res) => {
        if (
          res.message ===
          "This user already has some allocation ID for this date"
        ) {
          message.error(res.message);
        } else {
          message.success(res.message);
        }

        setModal(false);
      })
    );
    userForm.resetFields();
  };

  useEffect(() => {
    if (data?.message === "Fetched users") {
      setUsers(data.users);
    } else if (data?.message === "Fetched One Department") {
      setDepartments(data?.department?.department_name);
    } else if (data?.message === "Fetched IDs") {
      setAddedIds(data?.allocationData);
    } else if (data?.message === "Fetched project") {
      const { users, ...project } = data.data;
      setAdded(users);
    }
  }, [data]);

  const fetchProject = (id) => {
    run(client(`timesheet/project/${id}`));
  };

  useEffect(() => {
    fetchUsers();
    fetchSingleDepartment();
    fetchProject(projectId);
  }, [modal]);

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day").endOf("hours");
  };

  return (
    <Modal
      title="Add User"
      visible={modal}
      onCancel={() => {
        userForm.resetFields();
        setModal(false);
      }}
    >
      <Form
        form={userForm}
        size="middle"
        layout="horizontal"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={addUser}
      >
        <Form.Item
          label="Select users"
          name="users"
          rules={[{ required: true }]}
        >
          <Select
            showSearch={true}
            allowClear={true}
            placeholder="select"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => setUserData(value)}
          >
            {users
              .filter((user) => {
                const multipleUserListExist = !accountdetails?.find(
                  (account) =>
                    Array.isArray(account.user_list) &&
                    account.user_list.includes(user.email)
                );

                const found = !addedUsers.find((u) => {
                  return u._id === user._id;
                });
                return found && multipleUserListExist;
              })
              .map((user, idx) => {
                return (
                  <Select.Option key={`profile-${idx}`} value={user._id}>
                    {user.email}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>

        {userData && addedIds !== "" ? (
          <Form.Item label="Already Added IDs:">{addedIds}</Form.Item>
        ) : (
          <></>
        )}

        {/* {
          checked === true && */}
        {!multiple && (
          <Form.Item
            label="Date Effective :"
            name="wef"
            onChange={handleChangeWEF}
            rules={[
              {
                required: true,
                message: "Please input the date effective",
              },
            ]}
          >
            <DatePicker
              format="YYYY-MM-DD HH:mm:ss"
              showTime={true}
              onOk={onOk}
              style={{ width: "100%" }}
              disabledDate={disabledDate}
            />
          </Form.Item>
        )}
        {/* } */}

        <p>
          {/* <em>
            <strong>Note: </strong>Please assign a manager to the users first
            otherwise the user email won't show in the list.
          </em> */}
        </p>
        <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }}>
          <Button type="primary" htmlType="submit">
            Submit{" "}
            {isLoading && (
              <span style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                <Spinner />
              </span>
            )}
          </Button>
        </Form.Item>
        {isError && <p style={{ color: "red" }}>{error.message}</p>}
      </Form>
    </Modal>
  );
};

export default AddUser;
