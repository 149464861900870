import React, { useState, useEffect } from "react";
import Table from "../../table";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import {
  Button,
  message,
  Popconfirm,
  Space,
  Form,
  Breadcrumb,
  Input,
  Collapse,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const AddCandidate = ({
  match: {
    params: { jobId },
  },
}) => {
  const [form] = Form.useForm();
  const { run, data } = useAsync();

  const [job, setJob] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [candidatesLoading, setCandidatesLoading] = useState(false);

  useEffect(() => {
    if (data?.code === "JOB_FETCH_SUCCESS") {
      setJob(data.data);
    }

    if (data?.code === "CANDIDATE_ADD_SUCCESS") {
      message.success(data.message);
      fetchCandidates();
    }

    if (data?.code === "CANDIDATES_FETCH_SUCCESS") {
      setCandidates(data.data);
      setCandidatesLoading(false);
    }

    if (data?.code === "CANDIDATE_REMOVED") {
      fetchCandidates();
      message.success(data.message);
    }

    if (data?.message === "Target changed") {
      message.success(data.message);
      fetchJob(jobId);
    }
  }, [data]);

  const deleteCandidate = (deleteId) => {
    run(client(`recruit/candidate/${deleteId}`, { method: "DELETE" }));
  };

  const fetchJob = (id) => {
    run(client(`recruit/jobs/user/${id}`));
  };

  const fetchCandidates = () => {
    run(client(`recruit/${jobId}/candidate/get`));
    setCandidatesLoading(true);
  };

  const addCandidate = (values) => {
    run(client(`recruit/${jobId}/candidate/add`, { body: values }));
  };

  useEffect(() => {
    fetchJob(jobId);
    fetchCandidates();
  }, []);

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      ellipsis: true,
    },
    {
      title: "Full name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Added at",
      dataIndex: "created_at",
      key: "created_at",
      render: (t) => <span>{new Date(t).toLocaleString()}</span>,
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      render: (record) => (
        <Space>
          <Popconfirm
            title="Are you sure？"
            onConfirm={() => deleteCandidate(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const transformTableData = (data) =>
    data.map(({ date, ...job }, index) => ({
      key: index,
      date: new Date(date).toLocaleDateString(),
      sr: index + 1,
      ...job,
    }));

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/recruit">Recruitment</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/recruit`}>{job?.name}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Add candidates</Breadcrumb.Item>
          </Breadcrumb>
          <br />

          <Collapse>
            <Collapse.Panel header={`Profile: ${job.name}`}>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <strong>Location: </strong>
                  <em>{job.location}</em>
                </li>
                <li>
                  <strong>Shift time: </strong>
                  <em>{job.shiftTime}</em>
                </li>
                <li>
                  <strong>Vacancy: </strong>
                  <em>{job.vacancy}</em>
                </li>
                <li>
                  <strong>Budget: </strong>
                  <em>{job.budget}</em>
                </li>
                <li>
                  <strong>Description: </strong>
                  <em>{job.description}</em>
                </li>
                <li>
                  <strong>Created at: </strong>
                  <em>{new Date(job.created_at).toLocaleString()}</em>
                </li>
              </ul>
            </Collapse.Panel>
          </Collapse>
        </div>
      </div>

      <Form
        form={form}
        name="horizontal_login"
        layout="inline"
        onFinish={addCandidate}
      >
        <Form.Item
          style={{ margin: "1rem" }}
          label="Full name"
          name="name"
          rules={[{ required: true, message: "Please input full name!" }]}
        >
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item
          style={{ margin: "1rem" }}
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please input email!" }]}
        >
          <Input type="email" placeholder="Email" />
        </Form.Item>
        <Form.Item
          style={{ margin: "1rem" }}
          label="Contact"
          name="contact"
          rules={[{ required: true, message: "Please input email!" }]}
        >
          <Input placeholder="Contact number" />
        </Form.Item>
        <Form.Item style={{ margin: "1rem" }} label="Remarks" name="remarks">
          <Input.TextArea />
        </Form.Item>
        <Form.Item style={{ margin: "1rem" }}>
          <Button type="primary" htmlType="submit">
            Add candidate
          </Button>
        </Form.Item>
      </Form>
      <Table
        loading={candidatesLoading}
        data={transformTableData(candidates)}
        columns={columns}
        size="middle"
      />
    </div>
  );
};

export default AddCandidate;
