import React from "react";
import { Modal, Form, Input, message, Button } from "antd";
import { useAsync } from "../../../../../utils/use-async";
import { client } from "../../../../../utils/api-client";
import { Spinner } from "../../../../lib";
import { useAuth } from "../../../../../context/auth-context";

const EditFileName = ({ modal, fetchProjects, setModal, fileId, fileName }) => {
  const { isLoading, run, isError, error } = useAsync();
  const { user } = useAuth();
  const [userForm] = Form.useForm();

  const updateFile = (values) => {
    run(
      client(`file/update/name/${fileId}`, {
        method: "PUT",
        body: values,
      }).then((res) => {
        message.success(res.message);
        setModal(false);
        fetchProjects();
      })
    );
  };

  const handleCancel = () => {
    setModal(false);
    userForm.resetFields();
  };

  return (
    <Modal title="Update File" visible={modal} onCancel={handleCancel}>
      <Form
        form={userForm}
        size="middle"
        layout="horizontal"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={updateFile}
      >
        <Form.Item
          label="File Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input file name!",
            },
            {
              validator: (_, value) => {
                if (value && !value.endsWith(".xlsx")) {
                  return Promise.reject(new Error("File name must end with .xlsx"));
                }
                return Promise.resolve();
              },
            },
          ]}
          initialValue={fileName}
         
        >
          <Input />
        </Form.Item>
        <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }}>
          <Button type="primary" htmlType="submit">
            Update{" "}
            {isLoading && (
              <span style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                <Spinner />
              </span>
            )}
          </Button>
        </Form.Item>
        {isError && <p style={{ color: "red" }}>{error.message}</p>}
      </Form>
    </Modal>
  );
};

export default EditFileName;
