import React, { useState, useEffect } from "react";
import "./allocate.css";
import Table from "../../../table";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Button, Popconfirm, Space, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import AddTargetModal from "../add-target-data-modal";
import AssignAll from "../assign-target-all-modal";
import EditParticular from "../edit-target-modal-particular";
import { render } from "@testing-library/react";
import HistoryModal from "../history-modal";

const AssignTarget = (props) => {
  const { run, isLoading, data } = useAsync();

  let [projects, setProjects] = useState([]);
  let [allDep, setDep] = useState([]);
  let [modal, setModal] = useState(false);
  let [usersLoading, setUsersLoading] = useState(false);
  const history = useHistory();
  const params = useParams();
  //added by ayush
  const [recordId, setRecordId] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [initialValues, setInitialValues] = useState({
    target_assigned: params.target_assigned,
    dateEffective: "",
  });
  const [historymodal, setHistorymodal] = useState();
  const [targetid, settargetid] = useState();
  const [targetuser, setTargetuser] = useState();
  const { id } = props.location.state;
  const handleEdit = (recordId, target_assigned, dateEffective) => {
    setRecordId(recordId);
    setInitialValues({
      target_assigned: target_assigned,
      dateEffective: dateEffective,
    });
    setEditModalVisible(true);

    // Show the EditParticular modal
  };
  const handleview = async (targetid, targetuser) => {
    settargetid(targetid);

    setHistorymodal(true);
    setTargetuser(targetuser);
  };
  const handleDelete = async (id) => {
    // await run(client(`targets/delete/${id}`, {method: "DELETE"}))
    // await fetchProjects();
  };

  useEffect(() => {
    if (data?.message === "Get Assigned Target") {
      setProjects(transformTableData(data?.allData));
      setUsersLoading(false);
    }
  }, [data]);

  const columns = [
    {
      title: "Project",
      dataIndex: "project_name",
      key: "project_name",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Sub Project",
      dataIndex: "sub_project",
      key: "sub_project",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Assigned Target",
      dataIndex: "target_assigned",
      key: "target_assigned",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Effective Date",
      render: (record) => <>{record.dateEffective.split("T")[0]}</>,
    },
    {
      title: "Actions",
      width: 200,
      render: (record) => (
        <>
          <Space style={{ marginLeft: "0.8rem" }}>
            <Button
              type="primary"
              onClick={() =>
                handleEdit(
                  record._id,
                  record.target_assigned,
                  record.dateEffective
                )
              }
              style={{ marginRight: "1rem", width: "100%" }}
            >
              Edit Target
            </Button>
          </Space>
          <Space style={{ marginLeft: "0.8rem", marginTop: "1rem" }}>
            <Button
              type="Ghost"
              onClick={() => handleview(record._id, record.user)}
              style={{ marginRight: "1rem" }}
            >
              View{"       "} History
            </Button>
          </Space>
        </>
      ),
    },
  ];

  const transformTableData = (data) => {
    return data.map((project, index) => ({
      key: index,
      ...project,
      sr: index + 1,
    }));
  };

  const fetchProjects = async () => {
    await run(client(`targets/all/assigned/${id}`));
    setUsersLoading(true);
  };
  useEffect(() => {
    fetchProjects();
  }, []);

  // const handleEdit = (recordId,target_assigned,dateEffective) => {
  //   setRecordId(recordId);
  //   setInitialValues({ target_assigned:target_assigned, dateEffective: dateEffective });
  //  console.log(initialValues)
  //   setEditModalVisible(true);

  //    // Show the EditParticular modal
  // };

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>Add Target Data</h1>
        <div>
          <Button
            type="ghost"
            onClick={() => setModal(true)}
            style={{ marginRight: "1rem" }}
          >
            Assign Target
          </Button>
        </div>
      </div>
      <Table
        loading={usersLoading}
        columns={columns}
        data={projects}
        size="middle"
      />
      <AssignAll
        modal={modal}
        setModal={setModal}
        projectId={params.projectId}
        targetId={params.targetId}
        fetchProjects={fetchProjects}
      />
      <EditParticular
        modal={editModalVisible}
        setModal={setEditModalVisible}
        fetchProjects={fetchProjects}
        recordId={recordId}
        initialValues={initialValues}
      />
      <HistoryModal
        modal={historymodal}
        setModal={setHistorymodal}
        fetchProjects={fetchProjects}
        targetid={targetid}
        targetuser={targetuser}
      />
    </div>
  );
};

export default AssignTarget;
