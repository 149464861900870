import React, { useEffect, useState } from "react";
import { Modal, Form, Input, message, Select, Button, Radio } from "antd";
import { useAsync } from "../../../../utils/use-async";
import { useAuth } from "../../../../context/auth-context";
import { client } from "../../../../utils/api-client";
import { Spinner } from "../../../lib";

const AddUser = ({ modal, fetchUsers, handleCancel, setModal }) => {
  const { isLoading, run, isError, error, data } = useAsync();
  const { user } = useAuth();
  const [dptmts, setDptmts] = useState([]);
  const [selectedDptmts, setSelectedDptmts] = useState([]);
  const [userForm] = Form.useForm()
  const [role, setRole] = useState([])

  const addUser = async(values) => {
    
    await run(
      client("addUser", { body: values }).then((res) => {
        message.success(res.message);
        setModal(false);
        userForm.resetFields()
        fetchUsers();
      })
    );
  };

  useEffect(() => {
    fetchDepartments();
  }, []);

  useEffect(() => {
    if (data?.message === "Fetched departments") {
      setDptmts(data.departments);
    }
  }, [data]);

  const fetchDepartments = (query) => {
    run(client(`department/all`));
  };

  return (
    <Modal title="Add User" visible={modal} onCancel={() => { 
      handleCancel()
      userForm.resetFields()
    }}>
      <Form
        size="middle"
        layout="horizontal"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        form={userForm}
        onFinish={addUser}
      >
        <Form.Item
          label="Full Name"
          name="fullName"
          rules={[
            {
              required: true,
              message: "Please input full name of the user!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
              required: true,
              message: "Please input email of the user",
            },
          ]}
        >
          <Input autoComplete = "new-email"/>
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input password for the user",
            },
          ]}
        >
          <Input.Password autoComplete = "new-password"/>
        </Form.Item>
        <Form.Item
          label="Role"
          name="role"
          rules={[{ required: true, message: "Please select role" }]}
        >
          <Select
          mode={role.includes('MANAGER')?"":"multiple"}
          name="role"
          value={role}
          onChange={(e)=>setRole(e)}
          >
            {user.roleData[0] === "ADMIN" ? (
              <Select.Option value="MANAGER">Manager</Select.Option>
            ) : null}
            <Select.Option value="AUTHOR">Author</Select.Option>
            <Select.Option value="REVIEWER">Reviewer</Select.Option>
            <Select.Option value="REVIEWER-2">Reviewer-2</Select.Option>
            <Select.Option value="UPLOADER">Uploader</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Departments" name="departments">
          <Select
            mode="multiple"
            placeholder="Select departments"
            value={selectedDptmts}
            onChange={setSelectedDptmts}
            style={{ width: "100%" }}
            showSearch={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {dptmts.map((item) => (
              <Select.Option key={item._id} value={item._id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Enable assignments"
          name="enableAssignments"
          rules={[{ required: true, message: "Choose to show assignments to the user." }]}
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }}>
          <Button type="primary" htmlType="submit">
            Submit{" "}
            {isLoading && (
              <span style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                <Spinner />
              </span>
            )}
          </Button>
        </Form.Item>
        {isError && <p style={{ color: "red" }}>{error.message}</p>}
      </Form>
    </Modal>
  );
};

export default AddUser;
