import React, { useState, useEffect } from "react";
import { Modal, message, Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import {useParams} from 'react-router-dom'

const AddDoneTask= ({ modalStatus,  handleCancelStatus, setModalStatus }) => {
  const { isLoading, run, error } = useAsync();
  const {filename} = useParams();
  let [file, setFile] = useState([]);

  const addFile = (values) => {
    run(
      client(`file/update/rows/done/final`, { body: values, type: "formData", method:"PUT" }).then((res) => {
        message.success(res.message);
        setFile([])
        setModalStatus(false);
      })
    );
  };

  useEffect(() => {
    if (error) {
      message.error(error?.message);
      setFile([]);
    }
  }, [error]);

  const handleUpload = () => {
    const formData = new FormData();
    formData.append("done", file[0]);
    addFile(formData);
  };

  const props = {
    onRemove: () => {
      setFile([]);
    },
    beforeUpload: (file) => {
      setFile([file]);
      return false;
    },
    accept: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel`,
  };

  return (
    <Modal title="Add Done Task" visible={modalStatus} onCancel={handleCancelStatus}>
      <div style={{ marginLeft: "2rem" }}>
        <Upload fileList={file} {...props}>
          <Button disabled={!!file.length}>
            <UploadOutlined /> Select File
          </Button>
        </Upload>
        <Button
          type="primary"
          loading={isLoading}
          onClick={handleUpload}
          disabled={file.length === 0}
          style={{ marginTop: 16 }}
        >
          {isLoading ? "Uploading" : "Start Upload"}
        </Button>
      </div>
    </Modal>
  );
};

export default AddDoneTask;
