import React, { useState, useEffect } from "react";
import Table from "../../../table";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Button, message, Space, Popconfirm, Breadcrumb, Collapse } from "antd";
import { Link } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import UpdateJob from "../update-job";

const Job = ({
  match: {
    params: { jobId },
  },
}) => {
  const { run, data } = useAsync();

  const [job, setJob] = useState([]);
  const [candidatesLoading, setCandidatesLoading] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [updateJob, setUpdateJob] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (data?.code === "FETCH_DEPARTMENT_SUCCESS") {
      setUsers(data.data);
    }

    if (data?.code === "JOB_FETCH_SUCCESS") {
      setJob(data.data);
    }

    if (data?.code === "CANDIDATES_FETCH_SUCCESS") {
      setCandidates(data.data);
      setCandidatesLoading(false);
    }

    if (data?.code === "CANDIDATE_REMOVED") {
      fetchCandidates();
      message.success(data.message);
    }
  }, [data]);

  const fetchCandidates = () => {
    run(client(`recruit/${jobId}/candidate/getAll`));
    setCandidatesLoading(true);
  };

  const fetchJob = (id) => {
    run(client(`recruit/job/${id}`));
  };

  const fetchUsers = () => {
    run(client(`department/users?department=recruiter`));
  };

  const deleteCandidate = (deleteId) => {
    run(client(`recruit/candidate/${deleteId}`, { method: "DELETE" }));
  };

  useEffect(() => {
    fetchJob(jobId);
    fetchUsers();
    fetchCandidates();
  }, []);

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      ellipsis: true,
    },
    {
      title: "Full name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Added by",
      dataIndex: "addedBy",
      key: "addedBy",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Added at",
      dataIndex: "created_at",
      key: "created_at",
      render: (t) => <span>{new Date(t).toLocaleString()}</span>,
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      render: (record) => (
        <Space>
          <Popconfirm
            title="Are you sure？"
            onConfirm={() => deleteCandidate(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const transformTableData = (data) =>
    data.map(({ addedBy, ...job }, index) => ({
      key: index,
      addedBy: addedBy.email,
      sr: index + 1,
      ...job,
    }));

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/recruitment">Recruitment</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{job?.name}</Breadcrumb.Item>
          </Breadcrumb>
          <br />

          <Collapse>
            <Collapse.Panel header={`Job: ${job.name}`}>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <strong>Location: </strong>
                  <em>{job.location}</em>
                </li>
                <li>
                  <strong>Shift time: </strong>
                  <em>{job.shiftTime}</em>
                </li>
                <li>
                  <strong>Vacancy: </strong>
                  <em>{job.vacancy}</em>
                </li>
                <li>
                  <strong>Budget: </strong>
                  <em>{job.budget}</em>
                </li>
                <li>
                  <strong>Description: </strong>
                  <em>{job.description}</em>
                </li>
                <li>
                  <strong>Recruiters: </strong>
                  <em>{job.recruiters?.map((_) => _.email).join(", ")}</em>
                </li>
                <li>
                  <strong>Created by: </strong>
                  <em>{job.addedBy?.email}</em>
                </li>
                <li>
                  <strong>Created at: </strong>
                  <em>{new Date(job.created_at).toLocaleString()}</em>
                </li>
                <li>
                  <Button
                    type="primary"
                    onClick={() => setUpdateJob(!updateJob)}
                  >
                    Edit
                  </Button>
                </li>
              </ul>
            </Collapse.Panel>
          </Collapse>
        </div>
      </div>
      <div>
        <h3>Candidates: </h3>
      </div>
      <UpdateJob
        setModal={setUpdateJob}
        modal={updateJob}
        currentJob={job}
        users={users}
        handleCancel={() => setUpdateJob(!updateJob)}
        fetchJob={fetchJob}
      />
      <Table
        loading={candidatesLoading}
        data={transformTableData(candidates)}
        columns={columns}
        size="middle"
      />
    </div>
  );
};

export default Job;
