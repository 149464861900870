import React, { useState, useEffect, useCallback } from "react";
import "./allocate.css";
import Table from "../../../table";
import { useAuth } from "../../../../context/auth-context";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import {useParams} from 'react-router-dom'
import {
  Form,
  Input,
  message,
} from "antd";
import { removeFalsyObj } from "../../../../utils";
import AddAllocation from "../add-allocation";

const WORKING_DAYS = 20;
const WORKING_HOURS_PER_DAY = 8;

const SummaryAllocations = ({ type }) => {
  const {accountId, project} = useParams()
  const { run, isLoading, data, isError, error } = useAsync();
  const { user } = useAuth();
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState("")
  const [modal, setModal] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);
  const [toDelete, setToDelete] = useState("");

  const [form] = Form.useForm();

  const fetchUsers = (query)=>{
    run(client(`allocation/history/${accountId}/${project}`));
  }

  const transformTableData = (data) =>
  data.map((user, index) => {
    let {  ...rest } = user;
    return {
      key: index,
      ...rest,
      sr: index + 1,
    };
  });

  useEffect(()=>{
    fetchUsers();
  },[])

  useEffect(() => {

    if (data?.message === "Fetched Allocation History") {
      if(filter===""){
        setUsers(transformTableData(data.users));
      }
      else{
        const filteredData = data.users.filter((val)=>{
            return val.current_user.includes(filter)
        })
        setUsers(transformTableData(filteredData))
      }
    }
  }, [data, filter]);



  useEffect(() => {
    if (isError) {
      message.error(error.message);
    }
  }, [error]);

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      width: 100,
      ellipsis: true,
    },
    {
      title: "User",
      dataIndex: "current_user",
      key: "current_user",
      width: 200,
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Allocation Time",
      dataIndex: "current_user_allocation_time",
      key: "current_user_allocation_time",
      width: 200,
      sorter: true,
      ellipsis: true,
      render:(rec)=>
      (
        <>
        {
          rec?new Date(rec).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'}):""
          }
        </>
      )
    },
    {
      title: "Revoke Time",
      dataIndex: "user_revoke_time",
      key: "user_revoke_time",
      width: 200,
      ellipsis: true,
      render:(rec)=>
      (
        <>
        {
          rec?new Date(rec).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'}):""
          }
        </>
      )
    },
    {
      title: "Department",
      dataIndex: "subject_name",
      key: "subject_name",
      width: 200,
    },
  ];

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>Allocation history for {accountId}</h1>
        
      </div>

      <div style={{ margin: "1rem" }}>
        <Form layout="inline">
          <Form.Item
            label="User Email Id"
            style={{ padding: "1rem 0rem" }}
          >
            <Input name="filter" value={filter} onChange={(e)=>setFilter(e.target.value)} type="text"/>
          </Form.Item> 
         
        </Form>
      </div>

      <Table
        loading={usersLoading}
        data={users}
        columns={columns}
        size="middle"
      />

    </div>
  );
};

export default SummaryAllocations;
