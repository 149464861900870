import React, { useState, useEffect } from "react";
import Table from "../../../table";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Button, message, Popconfirm, Space, Breadcrumb } from "antd";
import AddProfile from "../add-profile";
import { Spinner } from "../../../lib";
import { useHistory, Link } from "react-router-dom";

const ProjectDetails = ({
  match: {
    params: { subjectId },
  },
}) => {
  const { run, error, data, isLoading } = useAsync();

  let [profiles, setProfiles] = useState([]);
  let [modal, setModal] = useState(false);
  let [profilesLoading, setProfilesLoading] = useState(false);
  let [currentProject, setCurrentProject] = useState({});
  const [toDelete, setToDelete] = useState("");

  useEffect(() => {
    if (data?.message === "Fetched subject") {
      const { profiles, ...project } = data.data;
      setProfiles(profiles);
      setCurrentProject(project);
      setProfilesLoading(false);
    }

    if (data?.code === "PROFILE_DELETE_SUCCESS") {
      message.success(data.message);
      fetchSubject(subjectId);
    }
  }, [data]);

  useEffect(() => {
    if (error?.message === "Subject not found") {
      setProfilesLoading(false);
      message.error(error.message);
    }
  }, [error]);

  const handleProfileDelete = (id) => {
    setToDelete(id);
    run(client(`profile/${id}/remove`, { method: "DELETE" }));
  };

  const history = useHistory();

  const openTargets = (record) => {
    const id = profiles[record.sr - 1]._id;
    history.push(`/worklog/${id}/targets`);
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      ellipsis: true,
    },
    {
      title: "Profile Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Actions",
      key: "x",
      dataIndex: "",
      render: (record) => (
        <Space>
          <Button type="primary" onClick={() => openTargets(record)}>
            Manage targets
          </Button>
          <Popconfirm
            title="Sure to remove?"
            onConfirm={() => handleProfileDelete(record._id)}
          >
            <Button type="danger" disabled={isLoading && toDelete}>
              Remove {isLoading && toDelete === record._id ? <Spinner /> : ""}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const transformTableData = (data) =>
    data.map(({ name, _id }, index) => ({
      key: index,
      name,
      sr: index + 1,
      _id,
    }));

  const fetchSubject = (id) => {
    run(client(`project/subject/${id}`));
    setProfilesLoading(true);
  };

  useEffect(() => {
    fetchSubject(subjectId);
  }, []);

  const handleCancel = (e) => {
    setModal(false);
  };

  const showModal = () => setModal(true);

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/worklog">Projects</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/worklog/${currentProject.projectId?._id}`}>
                {currentProject.projectId?.name}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{currentProject.name}</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <h1>Available profiles</h1>
        </div>
        <Button type="ghost" onClick={showModal}>
          Add Profile
        </Button>
      </div>
      <Table
        loading={profilesLoading}
        data={transformTableData(profiles)}
        columns={columns}
        size="middle"

      />
      <AddProfile
        modal={modal}
        handleCancel={handleCancel}
        fetchSubject={fetchSubject}
        subjectId={subjectId}
        setModal={setModal}
      />
    </div>
  );
};

export default ProjectDetails;
