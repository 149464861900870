import React, { useEffect, useState } from "react";
import Table from "../../table";
import { Button } from "antd";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import { Link } from "react-router-dom";
import { getROLE } from "../../../utils/auth-client";
import { useAuth } from "../../../context/auth-context";

const Report = ({ type }) => {
  const { run, data } = useAsync();
  const { roleValues } = useAuth();
  const [cols, setCols] = useState([]);
  const [rows, setRows] = useState([]);
  const [loadingList, setLoadingList] = useState(false);

  const setColumns = (columns) => {
    columns = columns
      .map((col) => {
        if (col !== "filename") {
          if (col === "name") {
            return {
              key: col,
              title: col,
              dataIndex: col,
              width: 100,
            };
          } else {
            return {
              key: col,
              title: col,
              dataIndex: col,
              width: 100,
            };
          }
        }
      })
      .filter((_) => !!_);

    columns.push({
      key: "actions",
      title: "actions",
      width: 100,
      render: (record) => (
        <Link to={type ? `/report/${type}/${record.name}` : `/report/-/${record.name}`}>
          <Button type="primary">View Report</Button>
        </Link>
      ),
    });

    setCols(columns);
  };

  useEffect(() => {
    getFiles();
    setLoadingList(true);
  }, [type]);

  useEffect(() => {
    if (data?.message === "retrieved files") {
      if (data.files?.length) {
        setColumns(Object.keys(data.files[0]));
        setRows(data.files);
      }
      setLoadingList(false);
    }
  }, [data]);

  const getFiles = () => {
    run(client(`files/report/list/${roleValues}`));
  };

  return (
    <div>
      <Table data={rows} columns={cols} loading={loadingList} size="middle" />
    </div>
  );
};

export default Report;
