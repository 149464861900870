import React, { useEffect, useState } from "react";
import { Modal, Table, Spin } from "antd";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";

const HistoryModal = ({ modal, setModal, targetid,targetuser }) => {
  const [historyData, setHistoryData] = useState([]);
  const { run, isLoading, isError, error } = useAsync();

  useEffect(() => {
    if (modal && targetid) {
      fetchTarget(targetid);
    }
  }, [modal, targetid]);

  const handleCancel = () => {
    setModal(false);
  };

  const fetchTarget = (id) => {
    run(client(`/history/${id}`))
      .then((data) => {
        setHistoryData(data.data.history_record); // Extracting history_record array from the fetched data
      })
      .catch((error) => {



        console.error("Error fetching target history:", error);
      });
  };
  const columns = [
    {
      title: "Effective Date",
      dataIndex: "effective_date",
      key: "effective_date",
      render: (text) => text ? new Date(text).toLocaleDateString() : '',
    },
    {
      title: "Assigned Target",
      dataIndex: "target_count",
      key: "target_count",
    },
  ];
  const title = (
    <span>
      Target History <span style={{ color: 'blue', fontWeight: 'bold' }}>{targetuser}</span>
    </span>
  );  return (
    <Modal
      title={title}
      visible={modal}
      onCancel={handleCancel}
      width={800}
      footer={null}
    >
      {isLoading ? (
        <div style={{ textAlign: "center" }}>
          <Spin />
        </div>
      ) : isError ? (
        <div style={{ color: "red", textAlign: "center" }}>{error.message}</div>
      ) : (
        <Table
          columns={columns}
          dataSource={historyData} // Setting the history data as dataSource
          pagination={false}
        />
      )}
    </Modal>
  );
};

export default HistoryModal;
