import React, { useEffect, useState } from "react";
import { Modal, Tabs, Form, Select, Button, DatePicker } from "antd";
import { client, fileClient } from "../../../../utils/api-client";
import { useAsync } from "../../../../utils/use-async";
import { Spinner } from "../../../lib";

const Report = ({ modal, handleCancel }) => {
  const { run, data } = useAsync();
  const { TabPane } = Tabs;

  const [projects, setProjects] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const { RangePicker } = DatePicker;
  const dateFormat = "DD/MM/YYYY";

  const fetchProjects = (query) => {
    run(client("timesheet/project/all"));
  };

  const downloadReport = (query) => {
    const { project, date } = query;
    const getDateRange = (date) => {
      const [startDate, endDate] = date;
      return [startDate.startOf("day").toISOString(), endDate.endOf("day").toISOString()];
    };
    let queryStr = "";
    if (project && date) {
      const [startDate, endDate] = getDateRange(date);

      queryStr = `date=${startDate},${endDate}&project=${query.project}`;
    } else if (date) {
      const [startDate, endDate] = getDateRange(date);

      queryStr = `date=${startDate},${endDate}`;
    } else if (project) {
      queryStr = `project=${project}`;
    }

    setDownloading(true);
    run(fileClient(`timesheet/report?${queryStr}`, { method: "GET" }));
  };

  useEffect(() => {
    if (data?.message === "Fetched projects") {
      setProjects(data.projects);
    }

    if (data?.type?.startsWith("application")) {
      var url = window.URL.createObjectURL(data);
      var a = document.createElement("a");
      a.href = url;
      if (downloading) {
        a.download = "report.xlsx";
        setDownloading(false);
      }
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    }
  }, [data]);

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <Modal title="Generate Report" visible={modal} onCancel={handleCancel}>
      <Tabs defaultActiveKey="project">
        <TabPane tab="Project" key="project">
          <Form onFinish={downloadReport}>
            <Form.Item
              label="Select project"
              name="project"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="select"
              >
                {projects.map((project, idx) => (
                  <Select.Option key={`project-${idx}`} value={project._id}>
                    {project.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="date"
              label="select date range"
              rules={[{ required: true }]}
            >
              <RangePicker format={dateFormat} />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
              <Button type="primary" htmlType="submit">
                {downloading ? (
                  <>
                    Fetching report{" "}
                    <span style={{ marginLeft: "4px" }}>
                      <Spinner />
                    </span>
                  </>
                ) : (
                  "Download Report"
                )}
              </Button>
            </Form.Item>
          </Form>
        </TabPane>
        <TabPane tab="Date" key="date">
          <Form onFinish={downloadReport}>
            <Form.Item
              name="date"
              label="select date range"
              rules={[{ required: true }]}
            >
              <RangePicker format={dateFormat} />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
              <Button type="primary" htmlType="submit">
                {downloading ? (
                  <>
                    Fetching report{" "}
                    <span style={{ marginLeft: "4px" }}>
                      <Spinner />
                    </span>
                  </>
                ) : (
                  "Download Report"
                )}
              </Button>
            </Form.Item>
          </Form>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default Report;
