import React, { useState } from "react";
import { Modal, Form, Input, message, Button } from "antd";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Spinner } from "../../../lib";
import { useAuth } from "../../../../context/auth-context";
import dayjs from "dayjs";

const EditParticular = ({
  modal,
  setModal,
  fetchProjects,
  recordId,
  initialValues,
}) => {
  const { isLoading, run, isError, error } = useAsync();
  const { user } = useAuth();
  const [userForm] = Form.useForm();
  const [targetAssigned, setTargetAssigned] = useState(initialValues.target_assigned);
  const [dateEffective, setDateEffective] = useState(initialValues.dateEffective);

  const addSection = () => {
    const values = {
      target_assigned: targetAssigned,
      dateEffective: dateEffective,
      addedBy: user._id,
    };

    run(
      client(`targets/particular/edit/${recordId}`, {
        body: values,
        method: "PUT",
      })
    ).then((res) => {
      message.success(res.message);
      setModal(false);
      userForm.resetFields();
      fetchProjects();
    });
  };

  const handleCancel = () => {
    setModal(false);
    userForm.resetFields();
  };

  return (
    <Modal title="Assign Target" visible={modal} onCancel={handleCancel}>
      <Form
        form={userForm}
        size="middle"
        layout="horizontal"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 14,
        }}
        onFinish={addSection}
        initialValues={{
          target_assigned: initialValues.target_assigned,
          dateEffective: initialValues.dateEffective.slice(0, 10),
        }}
      >
        <Form.Item
          label="Task Count"
          name="target_assigned"
          rules={[
            {
              required: true,
              message: "Please enter task count!",
            },
          ]}
        >
          <Input
            type="number"
            onChange={(e) => setTargetAssigned(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Effective Date"
          name="dateEffective"
          rules={[
            { required: true, message: "Please enter an effective date" },
          ]}
        >
          <Input
            type="date"
            value={
              dateEffective
                ? new Date(dateEffective).toISOString().split("T")[0]
                : ""
            }
            onChange={(e) => setDateEffective(e.target.value)}
          />
        </Form.Item>
        <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }}>
          <Button type="primary" htmlType="submit" disabled={!targetAssigned || !dateEffective}>
            Submit{" "}
            {isLoading && (
              <span style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                <Spinner />
              </span>
            )}
          </Button>
        </Form.Item>
        {isError && <p style={{ color: "red" }}>{error.message}</p>}
      </Form>
    </Modal>
  );
};

export default EditParticular;
