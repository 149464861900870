import React, { useState, useEffect } from "react";
import "./manage.css";

import Table from "../../table";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import { Button, Popconfirm, message, Space ,Tooltip} from "antd";
import AddSection from "./add-section";
import { Spinner } from "../../lib";
import { useHistory } from "react-router-dom"
import AddFile from "./add-file";
import AddDoneTask from "../update-file-done";

const Sections = ({ type }) => {
  const {
    run,
    isLoading,
    data
  } = useAsync();

  let [users, setUsers] = useState([]);
  let [modal, setModal] = useState(false);
  let [doneModal, setDoneModal] = useState(false)
  let [fileModal, setFileModal] = useState(false);
  let [usersLoading, setUsersLoading] = useState(false);
  const history = useHistory()

  let [toDelete, setToDelete] = useState("");

  useEffect(() => {
    if (data?.message === "Please delete all files from section before delete") {
      message.error(data.message)
      setToDelete("")
    }
    if (data?.message === "Section removed successfully") {
      message.success(data?.message);
      setToDelete("");
      fetchSections();
    }

    if (data?.message === "Fetched sections") {
      setUsers(transformTableData(data.sections));
      setUsersLoading(false);
    }
  }, [data]);

  const handleDelete = ({name}) => {
    setToDelete(name);
    run(client(`section/delete`, { method: "DELETE", body: { name } }));
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      ellipsis: true,
    },
    {
      title: "Project Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Added By",
      dataIndex: "fullName",
      key: "fullName",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Actions",
      key: "x",
      dataIndex: "",
      render: (record) => (
        <>
          <Space>
          <Button type="primary" onClick={() => history.push(`/project/${record.name}`)}>Show Details</Button>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record)}
          >
          <Button type="danger">
            Delete {isLoading && toDelete === record.name ? <Spinner /> : ""}
          </Button>
          </Popconfirm>
          </Space>
        </>
      ),
    }
  ];

  const transformTableData = (data) => {
    return data.map(({addedBy: {fullName, email }, ...section}, index) => ({ key: index, ...section, fullName, email, sr: index + 1 }));
  };

  const fetchSections = (query) => {
    run(client(`sections`));
    setUsersLoading(true);
  };

  useEffect(() => {
    fetchSections();
  }, []);

  const handleCancel = (e) => {
    setModal(false);
  };

  const showModal = () => setModal(true);
  const showFileModal = () => setFileModal(true);

  const handleCancelDone = (e) => {
    setDoneModal(false);
  };


  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>Manage Projects</h1>
        <Button type="ghost" onClick={showModal} style={{
          marginLeft: 'auto',
          marginRight:'0.3rem'
        }}>
          Add Project
        </Button>

        <Button type="ghost" onClick={showFileModal} >
          Add Task
        </Button>

        <Button
            style={{ marginLeft: "0.3rem" }}
            onClick={()=>setDoneModal(true)}
          >
            Add Done Task
          </Button> 
      </div>
      <Table 
       pagination={{   //  pagination added by ayush
        pageSize: 100
        }}
        loading={usersLoading}
        data={users}
        columns={columns}
        size="middle"
      />
      <AddSection
        modal={modal}
        handleCancel={handleCancel}
        fetchSections={fetchSections}
        setModal={setModal}
      />
       <AddFile
        modal={fileModal}
        handleCancel={()=>setFileModal(false)}
        // fetchSection={fetchSection}
        // section={sectionName}
        setModal={setFileModal}
      />
        <AddDoneTask
        modalStatus={doneModal}
        handleCancelStatus={handleCancelDone}
        setModalStatus={setDoneModal} 
        />
    </div>
  );
};

export default Sections;
