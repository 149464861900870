import React, { useEffect, useState } from "react";
import { Modal, Form, message, Button, Select } from "antd";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Spinner } from "../../../lib";

const AssignProject = ({ modal, handleCancel, setModal, userId }) => {
  const { isLoading, run, isError, error, data } = useAsync();
  const [projects, setProjects] = useState([]);
  const [userForm] = Form.useForm();
  const [userProjects, setUserProjects] = useState([]);

  const assignProject = (values) => {
    run(
      client("timesheet/project/assign", {
        body: { userId, projects: values.projects },
        method: "PUT",
      }).then((res) => {
        message.success(res.message);
        setModal(false);
      })
    );
  };

  const fetchProjects = () => {
    run(client("timesheet/project/all"));
  };

  const fetchUserProjects = () => {
    client(`timesheet/user/projects?userId=${userId}`).then((res) => {
      setUserProjects(res.data);
    });
  };

  useEffect(() => {
    fetchProjects();
    fetchUserProjects();
  }, []);

  useEffect(() => {
    userForm.setFieldsValue({ projects: userProjects.map(_ => _._id) })
  }, [userForm, userProjects])

  useEffect(() => {
    fetchUserProjects();
  }, [userId]);

  useEffect(() => {
    if (data?.message === "Fetched projects") {
      setProjects(data.projects);
    }
  }, [data]);

  return (
    <Modal
      title="Assign projects"
      visible={modal}
      onCancel={() => {
        handleCancel();
        userForm.resetFields();
      }}
    >
      <Form
        form={userForm}
        size="middle"
        layout="horizontal"
        labelCol={{
          span: 7,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={assignProject}
      >
        <Form.Item
          label="Select projects"
          name="projects"
          rules={[{ required: true }]}
        >
          <Select
            showSearch={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear={true}
            mode="multiple"
            placeholder="select"
          >
            {projects.map((project, idx) => (
              <Select.Option key={`profile-${idx}`} value={project._id}>
                {project.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }}>
          <Button type="primary" htmlType="submit">
            Submit{" "}
            {isLoading && (
              <span style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                <Spinner />
              </span>
            )}
          </Button>
        </Form.Item>
        {isError && <p style={{ color: "red" }}>{error.message}</p>}
      </Form>
    </Modal>
  );
};

export default AssignProject;
