import React, { useEffect } from "react";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import { FullPageSpinner } from "../../lib";
import Card from "../../lib/card";
import { Pie } from 'react-chartjs-2';
import { Link } from "react-router-dom";
import { Button } from "antd"

const FileSummary = ({
  match: {
    params: { filename, sectionName },
  },
}) => {
  const { run, data } = useAsync();

  const getSummary = (filename) => {
    run(client(`file/${filename}/summary`));
  };

  useEffect(() => {
    getSummary(filename);
  }, []);

  if (data) {
    const {
      totalQuestions,
      authorAssigned,
      authorDone,
      authorLeft,
      authorSkipped,
      authorUnassigned,
      reviewer2Approved,
      reviewerApproved,
      uploaderUploaded,
    } = data.data;

    const dataPoints = [{
      label: "Total questions",
      angle: totalQuestions
    },
    {
      label: "Assigned to Author",
      angle: authorAssigned
    },
    {
      label: "Done by Author",
      angle: authorDone
    },
    {
      label: "Not done by Author",
      angle: authorLeft
    }, {
      label: "Skipped by Author",
      angle: authorSkipped
    }, {
      label: "Author not assigned",
      angle: authorUnassigned
    }, {
      label: "Approved by Reviewer-2",
      angle: reviewer2Approved
    }, {
      label: "Approved by Reviewer",
      angle: reviewerApproved
    }, {
      label: "Uploaded",
      angle: uploaderUploaded
    }]

    const chartData = dataPoints.filter(_ => _.label !== "Total questions");


    const doughnut = {
      labels: chartData.map(_ => _.label),
      datasets: [{
        data: chartData.map(_ => _.angle), backgroundColor: [
          '#003f5c',
          '#2f4b7c',
          '#665191',
          '#a05195',
          '#d45087',
          '#f95d6a',
          '#ff7c43',
          '#ffa600'
        ],
        hoverBackgroundColor: [
          '#003f5c',
          '#2f4b7c',
          '#665191',
          '#a05195',
          '#d45087',
          '#f95d6a',
          '#ff7c43',
          '#ffa600'
        ]
      }],
    }

    return (
      <div style={{ background: "white", height: "90vh" }}>
        <div style={{ padding: "1rem", clear: "both" }}>
          <div style={{ height: '100px', clear: "both" }}>
            <div style={{ float: 'left' }}>
              <h1>Filename: {filename}</h1>
              <h2>Section: {sectionName}</h2>
            </div>
            <div style={{ float: 'right' }}>
              <Link to={`/section/${sectionName}/${filename}`}><Button type="primary">Go to Tasks</Button></Link>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ width: "60%" }}>
            <div style={{ display: "flex", padding: "1rem", justifyContent: "center", flexWrap: "wrap" }}>
              {dataPoints.map(({ label, angle }) => <Card title={label} value={angle} />)}
            </div>
          </div>
          <div style={{ width: "35%" }}>
            <Pie
              width="100%"
              height="100%"
              options={{
                legend: {
                  position: 'bottom'
                }
              }} data={doughnut} />
          </div>
        </div>
      </div>
    );
  }

  return <FullPageSpinner />;
};

export default FileSummary;
