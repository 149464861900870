import React, { useState, useEffect } from "react";
import { Modal, message, Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";

const AddFile = ({ modal, fetchSection, handleCancel, section, setModal }) => {
  const { isLoading, run, error } = useAsync();
  let [file, setFile] = useState([]);

  const addFile = (values) => {
    run(
      client("file/add", { body: values, type: "formData" }).then((res) => {
        message.success(res.message);
        setFile([])
        setModal(false);
        // fetchSection(section);
      }).catch(e=>console.log(e))
    );
  };

  useEffect(() => {
    if (error) {
      message.error(error?.message);
      setFile([]);
    }
  }, [error]);

  const handleUpload = () => {
    const formData = new FormData();
    formData.append("avatar", file[0]);
    // formData.append("section", section);
    addFile(formData);
  };

  const props = {
    onRemove: () => {
      setFile([]);
    },
    beforeUpload: (file) => {
      setFile([file]);
      return false;
    },
    accept: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel`,
  };

  return (
    <Modal title="Add File" visible={modal} onCancel={handleCancel}>
      <div style={{ marginLeft: "2rem" }}>
        <Upload fileList={file} {...props}>
          <Button disabled={!!file.length}>
            <UploadOutlined /> Select File
          </Button>
        </Upload>
        <Button
          type="primary"
          loading={isLoading}
          onClick={handleUpload}
          disabled={file.length === 0}
          style={{ marginTop: 16 }}
        >
          {isLoading ? "Uploading" : "Start Upload"}
        </Button>
      </div>
    </Modal>
  );
};

export default AddFile;
