import React, { useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import "./change-password.css";
import { useAuth } from "../../../context/auth-context"
import { client } from "../../../utils/api-client";
import { useAsync } from "../../../utils/use-async";
import { Spinner } from "../../lib";

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const ChangePassword = () => {
  const { isLoading, isError, error, run, isSuccess, data } = useAsync();
const {logout} = useAuth()

  useEffect(() => {
    if(isError) {
        message.error(error.message)
    }
  }, [isError])

  useEffect(() => {
    if(data?.success) {
        message.success(data.message)
        logout()
    }
  }, [isSuccess])
  const onFinish = (values) => {
    run(client("user/reset-password", { body: values }));
  };

  return (
    <div className="wrapper">
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          label="Old Password"
          name="oldPassword"
          rules={[
            { required: true, message: "Please input your old password!" },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            { required: true, message: "Please input your new password!" },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
        Submit {isLoading ? <span style={{paddingLeft: '0.5rem'}}><Spinner /></span> : ""}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChangePassword;
