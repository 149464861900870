import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  message,
  Upload,
  Select,
  Input,
  AutoComplete,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import DataForm from "./status-form";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Option } from "antd/lib/mentions";
import { useAuth } from "../../../context/auth-context";

const MyPage = () => {
  const [fdata, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [accessUser, setAccessUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const { run, isLoading, data, error } = useAsync();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [accesmodal, setAcessModal] = useState(false);
  const [file, setFile] = useState(null);
  const [subject, setSubject] = useState();
  const [revokesubject, setRSubject] = useState([]);
  const [revokeDepartment, setRevokeDepartment] = useState([]);
  const { user } = useAuth();
  const isAdmin = user.roleData.includes("ADMIN");

  const fetchAccessUser = () => {
    run(client(`fetch/access/user`));
  };
  const fetchUsers = () => {
    run(client(`get/users/status/${user.email}`));
  };
  const handleUserChange = (value) => {
    const user = accessUser.find((user) => user.email === value);
    setSelectedUser(user);
    // console.log(user)
    // console.log(selectedUser)
    setRevokeDepartment(user?.statusReport);
  };
  const handleFormSubmit = (values) => {
    const updatedValues = {
      ...values,
      updated_by: user?._id,
    };
    run(
      client("update/users/status", {
        body: updatedValues,
        method: "PUT",
      }).then((res) => {
        if (res.message === "Status report saved successfully") {
          message.success(res.message);
        }
      })
    );
  };

  const setAddAccess = async () => {
    if (!selectedUser || !subject[0]) {
      message.error("Please select a user and enter a subject.");
      return;
    }

    const body = {
      userId: selectedUser._id,
      subject,
      action: "access",
    };
    run(client(`update/access`, { body, method: "PUT" }))
      .then((res) => {
        message.success(res.message);
        setSubject([]);
        setSelectedUser([]);
        setAcessModal(false);
        fetchUsers();
        fetchAccessUser();
      })
      .catch((err) => {
        message.error("hello");
      });
  };
  const setRemoveAccess = async () => {
    if (!selectedUser) {
      message.error("Please select a user to remove access.");
      return;
    }

    const body = {
      userId: selectedUser._id,
      subject: revokesubject,
      action: "revoke",
    };

    run(client(`/update/access`, { body, method: "PUT" }))
      .then((res) => {
        message.success(res.message);
        setRSubject([]);
        setSelectedUser(null);
        setAcessModal(false);
        fetchUsers();
        fetchAccessUser();
      })
      .catch((err) => message.error(err.message));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const showaccess = () => {
    setAcessModal(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setAcessModal(false);
    setFile(null);
  };
  const handleAccessCancel = () => {
    setSelectedUser(null);
    setAcessModal(false);
  };
  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append("status", file);

    run(
      client("save/users/status", { body: formData, type: "formData" })
        .then((res) => message.success(res.message))
        .catch((res) => message.error(res.message))
    );
    setFile(null);
    handleCancel();
    fetchUsers();
  };
  const router = useHistory();
  const props = {
    onRemove: () => {
      setFile(null);
    },
    beforeUpload: (file) => {
      setFile(file);
      return false;
    },
    accept: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel`,
  };
  const validSubjects = [
    "Mathematics",
    "Physics",
    "Chemistry",
    "Statistics",
    "Psychology",
    "Operations Management",
    "Biology",
    "Computer Science",
    "Accounting",
    "Finance",
    "Economics",
    "Civil Engineering",
    "Mechanical Engineering",
    "Electrical Engineering",
    "Earth Science",
    "Chemical Engineering",
    "Management",
  ];

  useEffect(() => {
    if (user) {
      fetchUsers();
    }
    fetchAccessUser();
  }, [user]);

  useEffect(() => {
    if (data?.message === "Users Fethed") {
      setUsers(data.users);
    }
    if (data?.message === "Aceess Users Fethed") {
      setAccessUser(data?.users);
    }
  }, [data]);

  // useEffect(() => {
  //   if (error) {
  //     message.error(error.message);
  //     setFile(null);
  //   }
  // }, [error]);

  return (
    <div className="manage--wrapper" style={{ padding: "20px" }}>
      <Row>
        <Col span={24}>
          <div
            style={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            {isAdmin && (
              <>
                <Button type="primary" onClick={showModal}>
                  Upload ID
                </Button>
                <Button type="primary" onClick={showaccess}>
                  Status Access
                </Button>
              </>
            )}
            <Button onClick={() => router.push("/status-report/history")}>
              View History Status
            </Button>
            <Button onClick={() => router.push("/status-report/current")}>
              View Current Status
            </Button>
          </div>
          <Card title="Form Details" bordered={false} className="custom-card">
            <DataForm
              users={users}
              onSubmit={handleFormSubmit}
              fetchUsers={fetchUsers}
            />
          </Card>
        </Col>
      </Row>

      <Modal
        title="Add Allocation ID"
        visible={isModalVisible}
        onCancel={handleCancel}
      >
        <div style={{ marginLeft: "2rem" }}>
          <Upload fileList={file ? [file] : []} {...props}>
            <Button disabled={!!file}>
              <UploadOutlined /> Select File
            </Button>
          </Upload>
          <Button
            type="primary"
            loading={isLoading}
            onClick={handleUpload}
            disabled={!file}
            style={{ marginTop: 16 }}
          >
            {isLoading ? "Uploading" : "Start Upload"}
          </Button>
        </div>
      </Modal>
      <Modal
        title="Add Access"
        visible={accesmodal}
        onCancel={handleAccessCancel}
        footer={null}
      >
        <div
          style={{
            padding: "1rem 2rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #f0f0f0",
          }}
        >
          <h4 style={{ margin: 0 }}>Set Access</h4>
          <h4 style={{ margin: 0 }}>Remove Access</h4>
        </div>
        <div
          style={{
            padding: "1rem 2rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 16,
          }}
        >
          <Select
            placeholder="Select a user"
            style={{ width: 220, marginTop: 16 }}
            onChange={handleUserChange}
            allowClear
            showSearch
          >
            {accessUser?.map((user) => (
              <Option key={user.email} value={user.email}>
                {user.email}
              </Option>
            ))}
          </Select>
          <Select
            placeholder="Select a user"
            style={{ width: 220, marginTop: 16 }}
            onChange={handleUserChange}
            allowClear
            showSearch
            disabled={accessUser.length === 0}
          >
            {accessUser
              ?.filter((user) => user.statusReportPermission === true)
              .map((user) => (
                <Option key={user.email} value={user?.email}>
                  {user.email}
                </Option>
              ))}
          </Select>
        </div>
        <div
          style={{
            padding: "1rem 2rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Select
            placeholder="Enter Department"
            style={{ width: "48%", marginTop: 16 }}
            value={subject}
            onChange={(value) => setSubject(value)}
            allowSearch
            mode="multiple"
            disabled={accessUser.length === 0}
          >
            {validSubjects?.map((subject) => (
              <Option key={subject} value={subject}>
                {subject}
              </Option>
            ))}
          </Select>

          <Select
            placeholder="Enter Department"
            style={{ width: "48%", marginTop: 16 }}
            value={revokesubject}
            onChange={(value) => setRSubject(value)}
            disabled={accessUser.length === 0} // Disable the select if no users are available
            allowSearch
            mode="multiple"
          >
            {revokeDepartment?.map((subject) => (
              <Option key={subject} value={subject}>
                {subject}
              </Option>
            ))}
          </Select>
        </div>
        <div
          style={{
            padding: "1rem 2rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            type="primary"
            style={{ width: "48%", marginTop: 16 }}
            onClick={setAddAccess}
          >
            Set Access
          </Button>
          <Button
            type="default"
            style={{ width: "48%", marginTop: 16 }}
            onClick={setRemoveAccess}
          >
            Remove Access
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default MyPage;
