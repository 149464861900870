  import React, { useState, useEffect, useCallback } from "react";
  import "./qualitative.css";
  import Table from "../../table";
  import { useAuth } from "../../../context/auth-context";
  import { useAsync } from "../../../utils/use-async";
  import { client } from "../../../utils/api-client";
  import { Button, Form, Input, Popconfirm, message } from "antd";
  import { useParams, useHistory } from "react-router-dom";
  import { Link } from "react-router-dom";
  import AddQualitative from "../qualitative-add";
  import TaskList from "../qualitative-task-list";
  import EditList from "../qualitative-edit";
  const moment = require("moment-timezone");

  const AllQualitative = () => {
    const { run, isLoading, data, isError, error } = useAsync();
    const { user } = useAuth();
    const [usersLoading, setUsersLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [form] = Form.useForm();
    const [modal, setModal] = useState(false);
    const [taskModal, setTaskModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [taskList, setTaskList] = useState([]);
    const [editList, setEditList] = useState([]);

    const approveRecord = async (val) => {
      run(client("qualitative/tasks/approve", { body: val, method: "PUT" })).then(
        (res) => {
          if (res.success) {
            fetchRecords();
          }
        }
      );
    };

    const columns = [
      {
        title: "Effective Date",
        dataIndex: "effectiveDate",
        key: "effectiveDate",
        width: 100,
        sorter: true,
        ellipsis: true,
        render: (record) => <>{record.split("T")[0]}</>,
      },
      {
        title: "Approval Status",
        width: 100,
        sorter: true,
        ellipsis: true,
        render: (record) => (
          <>
            {record.managerApproval.toString() === "false"
              ? "Pending"
              : "Approved"}
          </>
        ),
      },

      // added by Ayush
      {
        title: "Total Time",
        dataIndex: "totalHours",
        key: "totalHours",

        width: 100,
        sorter: true,
        ellipsis: true,
        render: (record) => (
          <>
            <div style={{ whiteSpace: "pre-wrap" }}>{record}</div>
          </>
        ),
      },
      {
        title: "Manager Remarks",
        dataIndex: "managerRemark",
        key: "managerRemark",

        width: 100,
        sorter: true,
        ellipsis: true,
        render: (record) => (
          <>
            <div style={{ whiteSpace: "pre-wrap" }}>{record}</div>
          </>
        ),
      },
      // added by Ayush
      {
        title: "User",
        width: 100,
        sorter: true,
        ellipsis: true,
        render: (record) => <>{record.addedBy.email}</>,
      },
      {
        title: "Actions",
        width: 200,
        ellipsis: true,
        render: (record) => (
          <>
            <Button
              type="link"
              onClick={() => {
                setTaskModal(true);
                setTaskList(record?.taskList);
              }}
            >
              View Task List
            </Button>

            <Button
              type="link"
              onClick={() => {
                setEditModal(true);
                setEditList(record);
              }}
            >
              Edit Task List
            </Button>

            <Popconfirm
              title="Sure to approve?"
              onConfirm={() => {
                approveRecord(record);
              }}
            >
              <Button type="link">Approve Task</Button>
            </Popconfirm>
          </>
        ),
      },
    ];

    const fetchRecords = useCallback(() => {
      run(client("qualitative/all/manager")).then((res) => {
        setUsersLoading(true);
      });
    }, [run]);

    useEffect(() => {
      if (data?.message === "Fetched Qualiatative Task") {
        setRecords(transformTableData(data?.qualitativeRecords));
        setUsersLoading(false);
      }
    }, [data]);

    useEffect(() => {
      if (isError) {
        message.error(error.message);
      }
    }, [error]);

    useEffect(() => {
      fetchRecords();
    }, [taskModal]);

    const transformTableData = (data) =>
      data.map((user, index) => {
        let { ...rest } = user;

        return {
          key: index,
          ...rest,
          sr: index + 1,
        };
      });

    return (
      <div className="manage--wrapper">
        <div
          style={{
            margin: "1rem 0rem",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h1 style={{ marginBottom: 0 }}>Qualitative Projects</h1>
          <Link to={`/qualitative-task/approved`}>
            <Button style={{ color: "#1890ff" }}>View Approved Task</Button>
          </Link>
          {/* <div>
            <Button onClick={() => setModal(true)} >Add Task</Button>
          </div> */}
        </div>
        <Table
          loading={usersLoading}
          data={records}
          columns={columns}
          size="middle"
        />
        <TaskList modal={taskModal} setModal={setTaskModal} records={taskList} />
        <EditList
          modal={editModal}
          setModal={setEditModal}
          records={editList}
          fetchData={fetchRecords}
        />
      </div>
    );
  };

  export default AllQualitative;
