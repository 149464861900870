import React, { useState, useEffect } from "react";
import Table from "../../table";
import { useAsync } from "../../../utils/use-async";
import { useAuth } from "../../../context/auth-context";
import { client } from "../../../utils/api-client";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import Marquee from 'react-fast-marquee';
import {
  Button,
  Radio,
  message,
  Form,
  Input,
  Select,
  Modal,
  Space,
  Alert
} from "antd";
import { Spinner } from "../../lib";
import Filter from "../../filter";

const UserTasks = ({
  match: {
    params: { filename },
  },
  type,
  ...props
}) => {
  const { run, error, data } = useAsync();
  const { setRework, user, roleValues } = useAuth();
  let [rows, setRows] = useState([]);
  let [contentLoading, setContentLoading] = useState(false);
  let [columns, setColumns] = useState([]);
  let [tasks_count, setTasksCount] = useState(0);
  let [currentRecord, setCurrectRecord] = useState({});
  const [taskModal, setTaskModal] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [role, setRole] = useState("");
  const [initialValues, setInitialValues] = useState({});
  const [page_no, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchFields, setSearchFields] = useState([]);
  const [accountId, setAccountData] = useState("")

  const handleActionChange = (values) => {
    run(
      client(`file/tasks/${filename}/update/${roleValues}`, {
        body: { ...values, accountId },
        method: "PATCH",
      })
    );
  };

  const qNumSearch = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search question`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    listTasks(filename, page_no, limit, selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    listTasks(filename, page_no, limit);
  };

  const setCols = (rows) => {
    let cols = [];

    if (rows[0]) {
      cols = Object.keys(rows[0])
        .filter((_) => _ !== "updateTask")
        .map((col) => {
          if (col === "sr_no") {
            return {
              title: col,
              key: col,
              dataIndex: col,
              width: 60,
              fixed: true,
            };
          }
          if (col === "question_code" || col === "question_id") {
            return {
              title: col,
              key: col,
              dataIndex: col,
              width: 120,
              fixed: true,
              filterIcon: (filtered) => (
                <SearchOutlined
                  style={{ color: filtered ? "#1890ff" : undefined }}
                />
              ),
              ...qNumSearch(col),
            };
          }
          if (col === "question_link" || col === "solution_link" || col === "new_content_id" ) {
            return {
              title: col,
              key: "x",
              width: 120,
              ellipsis: true,
              render: (record) => {
                return (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={record[col]}
                  >
                    {record[col]}
                  </a>
                );
              },
            };
          }
          if (col === "link") {
            return {
              title: col,
              key: "x",
              width: 80,
              render: (record) => {
                return record.link.disabled ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="#"
                    disabled
                  >
                    link
                  </a>
                ) : (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={record.link.url}
                  >
                    link
                  </a>
                );
              },
            };
          }

          if (col === 'author_done_allocation_id') {
            return {
              title: col,
              dataIndex: col,
              key: col,
              width: 160,
              ellipses: true,
            };
          }
          else if (col.endsWith("date")) {
            return {
              title: col,
              key: col,
              width: 180,
              render: (record) =>
                `${record[col]
                  ? moment(record[col]).format("DD/MM/YYYY hh:mm a")
                  : ""
                }`,
            };
          } else {
            return {
              title: col,
              dataIndex: col,
              key: col,
              width: 140,
              ellipses: true,
            };
          }
        });

      cols.push({
        title: "Action",
        key: "x",
        fixed: "right",
        width: 220,
        align: "center",
        render: (record) => (
          type === "assigned" || type === "rework-received" ?
            <Button
              type="dashed"
              disabled={!record.updateTask.enabled}
              onClick={() => showUpdateTask(record)}
            >
              {record.updateTask.enabled
                ? "Update task"
                : record.updateTask.message}
            </Button> : <></>
        ),
      });
    }
    // cols.push("project_name")
    setColumns(cols);
  };

  const showUpdateTask = async (record) => {
    setCurrectRecord(record);
    const { sr_no, link, metadata_update_needed } = record;
    setInitialValues({
      sr_no,
      link,
      metadata_update_needed,
    });
    setTaskModal(true);
    await run(
      client(`allocation/${roleValues === "AUTHOR" ? record.author : record.uploader}/${record.project_name}`)
    );
  };

  useEffect(() => {
    if (data?.message === "Fetched tasks") {
      setCols(data.tasks);
      const all = data.tasks.filter((e) => {
        return role === "reviewer" ? e.author_status !== "assigned" : e;
      })
      setRows(transformTableData(all));
      setContentLoading(false);
      setTasksCount(data.tasks_count);
      setRole(data.role);
    }

    if (data?.message === "Fetched Allocation Data") {
      setAccountData(data?.allocationData?.account_id ? data.allocationData.account_id : "")
    }
    if (data?.message === "Updated task") {
      message.success(data.message);
      setRework({ ...user, reworks: data.reworks });
      setUpdateLoading(false);
      listTasks(filename, page_no, limit);
      setTaskModal(false);
    }
  }, [data, roleValues]);

  useEffect(() => {
    if (error?.message === "Section not found") {
      setContentLoading(false);
      message.error(error.message);
    }

    if (error?.message === "Done task cannot be skipped") {
      setContentLoading(false);
      setUpdateLoading(false);
      message.error(error.message);
    }

    if (
      error?.message === "Please complete reworks before updating this task."
    ) {
      setContentLoading(false);
      setUpdateLoading(false);
      message.error(error.message);
    }
  }, [error, roleValues]);

  useEffect(() => {
    listTasks(filename, page_no, limit);
  }, [page_no, limit, searchFields, roleValues]);

  const transformTableData = (data) => {
    if (data[0] && data[0].sr_no) {
      let d = data.map((row, index) => ({
        key: index,
        ...row,
      }));
      return d;
    } else {
      return [];
    }
  };

  const listTasks = (filename, page_no = "", limit = "", question_id = "") => {
    run(
      client(
        `file/tasks/${filename}/${roleValues}?type=${type}&page_no=${page_no}&limit=${limit}&question_id=${question_id}`,
        { body: { searchFields }, method: "POST" }
      )
    );
    setContentLoading(true);
  };

  useEffect(() => {
    listTasks(filename, page_no, limit);
  }, [type, roleValues]);

  const handlePage = (page_no, limit) => {
    setPageNo(page_no);
    setLimit(limit);
    listTasks(filename, page_no, limit);
  };

  const changePageSize = (page_no, limit) => {
    setPageNo(1);
    setLimit(limit);
  };

  const handleUpdateTask = (val) => {
    handleActionChange({ ...val, sr_no: currentRecord.sr_no, role, accountId });
    setUpdateLoading(true);
    setCurrectRecord({});
  };

  const allReasons = [
    "Wrong Subject",
    "Spam",
    "Need More Information",
    "Too Much Time Required",
    "Insufficient Knowledge",
    "Honor Code Violation",
    "Answer size limit issue",
    "Other"
  ]

  const answerData = [
    "Uploaded on Portal",
    "Authored on Doc"
  ]

  const qcData = [0, 1, 2, 3, 4, 5]

  const AuthorForm = ({ taskModal, accountId }) => {
    const [reason, setReason] = useState("");
    const [taskStatus, setTaskStatus] = useState("");
    const [allList, setAllList] = useState(allReasons);
    const [answers, setAnswers] = useState(answerData);
    const [answerType, setAnswerType] = useState("");
    const [qc_score, setQcScore] = useState(null)
    let [recordData, setRecordData] = useState(currentRecord)
    const [qcList, SetQcList] = useState(qcData)
    let val = true;
    let val2 = false;

    return (
      <>
        {
          role === "author" ? <div>
            <Form
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 10 }}
              layout="horizontal"
              initialValues={initialValues}
              onFinish={handleUpdateTask}
            >
              <Form.Item label="sr.no">{recordData.sr_no}</Form.Item>
              <Form.Item label="question_id">{recordData.question_id}</Form.Item>
              <Form.Item label="project_name">{recordData.project_name}</Form.Item>
              {/* <Form.Item label="allocation_id" name="accountId">{type === "done" ? recordData.author_done_allocation_id : accountId}</Form.Item> */}
              <Form.Item label="status" name="status" rules={[{ required: true }]}>
                <Select
                  value={taskStatus}
                  placeholder="Select status"
                  onChange={(ev) => setTaskStatus(ev)}
                >
                  <Select.Option value="done">Done</Select.Option>
                  {role === "author" && type !== "rework-received" ? (
                    <Select.Option value="skipped">Skipped</Select.Option>
                  ) : null}
                </Select>
              </Form.Item>
              {taskStatus === "skipped" ? (
                <>
                  <Form.Item label="Reason" name="reason" rules={[{ required: true }]}>
                    <Select
                      value={reason}
                      placeholder="Select reason"
                      onChange={(e) => setReason(e)}
                    >
                      {
                        allList.map((a) => {
                          return (

                            <Select.Option key={a} value={a}>{a}</Select.Option>

                          )
                        })
                      }
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Any Comments"
                    name="comments"
                    rules={[{ required: taskStatus === "skipped" ? true : false }]}
                  >
                    <Input.TextArea />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item label="question link">
                    <a href={recordData.question_link} target="_blank">
                      link
                    </a>
                  </Form.Item>

                  <Form.Item label="Answer Type" name="answerType" rules={[{ required: true }]}>
                    <Select
                      value={answerType}
                      placeholder="Select Answer type"
                      onChange={(e) => setAnswerType(e)}
                    >
                      {
                        answers.map((a) => {
                          return (

                            <Select.Option key={a} value={a}>{a}</Select.Option>

                          )
                        })
                      }
                    </Select>
                  </Form.Item>

                  {
                    answerType === "Uploaded on Portal" ?
                      <>
                        <Form.Item label="moderator id" >
                          {accountId}
                        </Form.Item>
                        <Form.Item label="new content id" name='new_content_id' rules={[{ required: true }]}>
                          <Input
                            placeholder={`Enter new content id`}
                          />
                        </Form.Item>
                      </> : <></>

                  }

                  <Form.Item label="Any Comments" name="comments">
                    <Input.TextArea />
                  </Form.Item>
                </>

              )}
              <Form.Item wrapperCol={{ offset: 10 }}>
                {/* <Button disabled={updateLoading} type="primary" htmlType="submit"> */}
                <Button disabled={accountId === "" ? answerType === "Uploaded on Portal" ? val : val2 : updateLoading} type="primary" htmlType="submit">
                  Submit{" "}
                  <span style={{ marginLeft: "8px" }}>
                    {updateLoading ? <Spinner /> : null}
                  </span>
                </Button>
              </Form.Item>
            </Form>
          </div>
            :
            <Form
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 10 }}
              layout="horizontal"
              onFinish={handleUpdateTask}
            >
              <Form.Item label="sr.no">{recordData.sr_no}</Form.Item>
              <Form.Item label="question_id">{recordData.question_id}</Form.Item>
              <Form.Item label="status" name="status" rules={[{ required: true }]}>
                <Select placeholder="Select status" value={taskStatus} name="taskStatus" onChange={(ev) => setTaskStatus(ev)}>
                  {role === "reviewer-2" ? (
                    <>
                      <Select.Option value="approved">Approved</Select.Option>
                      <Select.Option value="send for rework">
                        Send for rework
                      </Select.Option>
                    </>
                  ) : role === "uploader" ? (
                    <>
                      <Select.Option value="uploaded">Uploaded</Select.Option>
                      <Select.Option value="skipped">Skipped</Select.Option>
                    </>
                  ) : role === "reviewer" && recordData.author_status === "skipped" ? (
                    <>
                      <Select.Option value="skip-approved">Skip Approved</Select.Option>
                      <Select.Option value="sent-for-re-attempt">Can be Attempted</Select.Option>
                    </>
                  ) : (
                    <>
                      <Select.Option value="approved">Approved</Select.Option>
                      {
                        recordData.author_answer_type === "Authored on Doc" ?
                          <Select.Option value="send for rework">
                            Send for rework
                          </Select.Option> : <></>
                      }
                      <Select.Option value="rejected">Rejected</Select.Option>
                      <Select.Option value="to be skipped">To be Skipped</Select.Option>
                    </>
                  )}
                </Select>
              </Form.Item>

              {
                currentRecord.author_answer_type === "Authored on Doc" && taskStatus === "uploaded" && role === "uploader" ?
                  <Form.Item label="moderator id">
                    {accountId}
                  </Form.Item>
                  : <></>
              }

              {
                role === "uploader" && taskStatus === "uploaded" ?
                  <>
                    <Form.Item label="new content id" name='new_content_id' rules={[{ required: true }]}>
                      <Input
                        placeholder={`Enter new content id`}
                        name="new_content_id"
                      />
                    </Form.Item>
                  </> : <></>

              }

              <Form.Item label="Your Remarks" name="comments" rules={[{ required: taskStatus === "approved" || taskStatus === "skip-approved" ? false : true }]}>
                <Input.TextArea />
              </Form.Item>

              {
                role === "reviewer" && recordData.author_status !== "skipped" && taskStatus !== "to be skipped" ?
                  <Form.Item label="QC Score" name="qc_score" rules={[{ required: taskStatus === "rejected" ? true : false }]}>
                    <Select
                      value={qc_score}
                      placeholder="Select QC Score"
                      onChange={(e) => setQcScore(e)}
                    >
                      {
                        qcList.map((a) => {
                          return (

                            <Select.Option key={a} value={a}>{a}</Select.Option>

                          )
                        })
                      }
                    </Select>
                  </Form.Item>
                  : <></>
              }

              <Form.Item wrapperCol={{ offset: 10 }}>
                <Button disabled={accountId === "" ? taskStatus === "uploaded" && currentRecord.author_answer_type === "Authored on Doc" ? val : val2 : updateLoading} type="primary" htmlType="submit">
                  Submit{" "}
                  <span style={{ marginLeft: "8px" }}>
                    {updateLoading ? <Spinner /> : null}
                  </span>
                </Button>
              </Form.Item>
            </Form>
        }
      </>
    )
  }

  const submitFilter = (vals) => {
    setPageNo(1);
    setSearchFields(vals);
  };

  const clearFilter = () => {
    setSearchFields([]);
    setLimit(10);
    setPageNo(1);
  };

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>{filename}</h1>
        {
          user.reworks && type==='assigned' &&  <Alert
          banner
          message={
            <Marquee pauseOnHover gradient={false}
            style={{color:'red', fontSize:'20px'}}
            >
              Please complete the rework before updating any other task
            </Marquee>
            
          }
        />        }
       
      </div>
      {role === "reviewer" || role === "reviewer-2" ? (
        <div style={{ margin: "1rem 0" }}>
          <Filter
            columnKeys={columns}
            onFilterClear={clearFilter}
            onFilterSubmit={submitFilter}
          />
        </div>
      ) : null}
      <Table
        pagination={{
          defaultPageSize:500,
          onChange: handlePage,
          total: tasks_count,
          showQuickJumper: true,
          pageSizeOptions: ["10", "20", "50", "100", "500","1000"],
          showSizeChanger: true,
          onShowSizeChange: changePageSize,
        }}
        loading={contentLoading}
        data={rows}
        columns={columns}
        size="middle"
      />
      <Modal
        title="Update task"
        visible={taskModal}
        onCancel={() => setTaskModal(false)}
      >
        <AuthorForm taskModal={taskModal} accountId={accountId} />
      </Modal>
    </div>
  );
};

export default UserTasks;
