import React from "react"
import * as authClient from "../utils/auth-client"
import { useAsync } from "../utils/use-async"
import { FullPageSpinner, FullPageErrorFallback } from "../components/lib"
import { bootstrapAppData } from "../utils/bootstrap"
import { userRole } from "../utils/api-client"

const AuthContext = React.createContext()
AuthContext.displayName = "AuthContext"

const appDataPromise = bootstrapAppData()

function AuthProvider(props) {
  const {
    data,
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    setData,
    run
  } = useAsync()

  React.useLayoutEffect(() => {
    run(appDataPromise)
  }, [run])

  const user = data?.user

  const roleValues = authClient.getROLE()

  const setRework = React.useCallback(_user => {
    if (user?.reworks !== _user.reworks) {
      setData({ user: _user })
    }  
  })

  const login = React.useCallback(
    form => authClient.login(form).then(user => setData({ user })),
    [setData]
  )

  const logout = React.useCallback(() => {
    authClient.logout()
    setData(null)
  }, [setData])

  const value = React.useMemo(() => ({ user, login, logout, setRework, roleValues }), [
    login,
    logout,
    user,
    setRework,
    roleValues
  ])

  if (isLoading || isIdle) {
    return <FullPageSpinner />
  }

  if (isError) {
    return <FullPageErrorFallback error={error} />
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />
  }

  throw new Error(`Unhandled status: ${status}`)
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthProvider, useAuth }
