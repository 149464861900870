
const removeFalsyObj = ( obj) => {
    let result = {}, key;

    for (key in obj) {
        if (obj.hasOwnProperty(key) && !!obj[key]) {
            result[key] = obj[key];
        }
    }

    return result;
};

const roleActions = {
    "author_status": ["done", "skipped"],
    "reviewer_status": ["approved", "sent for rework"],
    "reviewer-2_status": ["approved", "sent for rework"],
    "uploader_status": ["done", "skipped"]
}

const evaluateAction = role => {
    return roleActions[role]
}

export { removeFalsyObj, evaluateAction, roleActions }