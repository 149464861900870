import React, { useState, useEffect } from "react";
import { Layout, Menu, Dropdown, Button, Tag, Form, Select } from "antd";
import { useAuth } from "../../context/auth-context";
import { Switch, Route, Link, useHistory } from "react-router-dom";
import { FileTextOutlined } from "@ant-design/icons";
import "./dashboard.css";
import ManageUsers from "./manage-users";
import ManageSections from "./manage-sections";
import ChangePassword from "./change-password";
import SectionDetails from "./manage-sections/section-details";
import Assigned from "./assigned";
import UserTasks from "./user-tasks";
import Departments from "./manage-departments";
import Report from "./report";
import File from "./file";

/* worklog */
import UserWorklog from "./user-worklog";
import Worklog from "./worklog";
import ProjectDetails from "./worklog/project-details";
import Targets from "./worklog/targets";
import Profiles from "./worklog/profiles";
import Target from "./worklog/targets/target";

/* recruitment */

import Recruitment from "./recruitment";
import Job from "./recruitment/job";
import UserRecruit from "./user-recruit";
import AddCandidate from "./user-recruit/add-candidate";

/* timesheet */

import TimesheetProjects from "./timesheet";
import TimesheetUsers from "./timesheet/users";
import TimesheetUserDetails from "./timesheet/users/user-details";
import TimesheetProjectDetails from "./timesheet/project-details";
import UserTimesheet from "./user-timesheet";
import UserDeclinedTimesheet from "./user-timesheet/declined-timesheet";
import ManageSheet from "./timesheet/manage-sheet";

import FileSummary from "./file-summary.js";
import UserSummary from "./user-summary";
import ViewReport from "./report/view";

import DaywiseReport from "./report/view/daywise";
import logo from "../../logo.png";
import UploadAllocations from "./allocations/upload-allocations";
import SummaryAllocations from "./allocations/manage-allocations/index.js";
import ProjectAllocations from "./allocations/allocation-project/index.js";
import DepartmnentAllocations from "./allocations/allocation-department/index.js";
import ProjectDepartmnent from "./manage-sections/project-department/index.js";
import UserReport from "../dashboard/user-report";
import QualitativeProjects from "./qualitative/index.js";
import AllQualitative from "./qualitative-all/index.js";
import ApprovedQualitative from "./qualitative-approved/index.js";
import AllQualitativeDataForAdmin from "./qualitative-data-all-admin/index.js";
import AddTargetData from "./target-management/add-target-data/index.js";
import AddTargetProjects from "./target-management/add-project/index.js";
import AssignTarget from "./target-management/assign-target-all/index.js";
import Task from "./qualitative-approved-user/index.js";
import ManageFile from "./manage-file/index.js";
import ManageFileName from "./manage-file/project-file-name/index.js";
import AllTasklist from "./manage-file/all-task/index.js";
import TargetReport from "./target-management/target-report/index.js";
import StatusReport from "./status-report/index.js";
import StatusReportTable from "./status-report/history.js";
import CurrentStatusReportTable from "./status-report/current-data.js";
import UserAllocation from "./allocations/auto-allocations/index.js";

const menuOpts = {
  ADMIN: [
    { name: "Manage Projects", key: "projects" },
    { name: "Manage File", key: "manage-file" },
    { name: "Manage Users", key: "users" },
    { name: "Manage Targets", key: "targets" },
    { name: "Worklog", key: "worklog" },
    { name: "Recruitment", key: "recruitment" },
    { name: " Production Report", key: "target-report" },

    {
      name: "Timesheet",
      type: "menu",
      keys: [
        {
          name: "Manage users",
          key: "timesheet/users",
        },
      ],
    },

    { name: "Allocation ID", key: "projectAllocations" },
    {
      name: "Qualitative",
      type: "menu",
      keys: [
        { name: "Manager Qualitative Projects", key: "qualitative" },
        { name: "All Qualitative Projects", key: "qualitativedata/all" },
      ],
    },
    {
      type: "menu",
      name: "Generate report",
      keys: [
        // {
        //   name: "Books",
        //   key: "report/book",
        // },
        {
          name: "User Report",
          key: "report/user",
        },
        {
          name: "Department Report",
          key: "report/department",
        },
        {
          name: "Team Report",
          key: "report/team",
        },
        // {
        //   name: "Author report",
        //   key: "report/author",
        // },
        // {
        //   name: "Reviewer report",
        //   key: "report/reviewer",
        // },
        // {
        //   name: "Reviewer-2 report",
        //   key: "report/reviewer-2",
        // },
        // {
        //   name: "Uploader report",
        //   key: "report/uploader",
        // },
        // {
        //   name: "Day-wise report",
        //   key: "report/day",
        // },
      ],
    },
    { name: " Status Update", key: "status-report" },
  ],
  MANAGER: [
    { name: "Manage Projects", key: "projects" },
    { name: "Manage Users", key: "users" },
    { name: "Worklog", key: "worklog" },

    {
      name: "Timesheet",
      type: "menu",
      keys: [
        {
          name: "Manage users",
          key: "timesheet/users",
        },
      ],
    },
    {
      type: "menu",
      name: "Generate report",
      keys: [
        {
          name: "User Report",
          key: "report/user",
        },
        {
          name: "Team Report",
          key: "report/team",
        },
        // {
        //   name: "Author report",
        //   key: "report/author",
        // },
        // {
        //   name: "Reviewer report",
        //   key: "report/reviewer",
        // },
        // {
        //   name: "Reviewer-2 report",
        //   key: "report/reviewer-2",
        // },
        // {
        //   name: "Uploader report",
        //   key: "report/uploader",
        // },
        // {
        //   name: "Day-wise report",
        //   key: "report/day",
        // },
      ],
    },
    {
      name: "Qualitative",
      type: "menu",
      keys: [
        { name: "Team Qualitative Projects", key: "qualitative" },
        { name: "Add Qualitative Projects", key: "qualitativedata/projects" },
      ],
    },
  ],
  AUTHOR: [
    {
      name: "Task Management",
      type: "menu",
      keys: [
        {
          name: "All tasks",
          key: "all",
          type: "assignment",
        },
        {
          name: "Assigned tasks",
          key: "assigned",
          type: "assignment",
        },
        {
          name: "Done tasks",
          key: "done",
          type: "assignment",
        },
        {
          name: "Approved tasks",
          key: "approved",
          type: "assignment",
        },
        {
          name: "Skipped tasks",
          key: "skipped",
          type: "assignment",
        },
        {
          name: "Received for rework",
          key: "rework-received",
          type: "assignment",
        },
        {
          name: "To Be Skipped Task",
          key: "tobeskipped",
          type: "assignment",
        },
        {
          name: "Rejected Task",
          key: "rejected",
          type: "assignment",
        },
      ],
    },

    {
      name: "Generate report",
      key: "report",
      type: "assignment",
    },
    { name: "Worklog", key: "user/worklog" },
    { name: "Timesheet", key: "user/timesheet" },
    { name: "Add Qualitative Projects", key: "qualitativedata/projects" },
    {
      name: "Allocation Id",
      key: "allocations",
      type: "allocations",
    },
  ],
  REVIEWER: [
    {
      name: "Task Management",
      type: "menu",
      keys: [
        {
          name: "All tasks",
          key: "all",
          type: "assignment",
        },
        {
          name: "Assigned tasks",
          key: "assigned",
          type: "assignment",
        },
        {
          name: "Approved tasks",
          key: "approved",
          type: "assignment",
        },
        {
          name: "Sent For Rework",
          key: "rework-sent",
          type: "assignment",
        },
        {
          name: "Received for Rework",
          key: "rework-received",
          type: "assignment",
        },
        {
          name: "Skips Approved",
          key: "skipApproved",
          type: "assignment",
        },
        {
          name: "To Be Skipped Task",
          key: "tobeskipped",
          type: "assignment",
        },
        {
          name: "Rejected Task",
          key: "rejected",
          type: "assignment",
        },
      ],
    },

    {
      name: "Generate report",
      key: "report",
      type: "assignment",
    },
    { name: "Timesheet", key: "user/timesheet" },
    { name: "Add Qualitative Projects", key: "qualitativedata/projects" },
    {
      name: "Allocation Id",
      key: "allocations",
      type: "allocations",
    },
  ],
  "REVIEWER-2": [
    {
      name: "Task Management",
      type: "menu",
      keys: [
        {
          name: "All tasks",
          key: "all",
          type: "assignment",
        },
        {
          name: "Assigned tasks",
          key: "assigned",
          type: "assignment",
        },
        {
          name: "Approved tasks",
          key: "approved",
          type: "assignment",
        },
        {
          name: "Sent for Rework",
          key: "rework-sent",
          type: "assignment",
        },
      ],
    },

    {
      name: "Generate report",
      key: "report",
      type: "assignment",
    },
    { name: "Timesheet", key: "user/timesheet" },
  ],
  UPLOADER: [
    {
      name: "Task Management",
      type: "menu",
      keys: [
        {
          name: "All tasks",
          key: "all",
          type: "assignment",
        },
        {
          name: "Assigned tasks",
          key: "assigned",
          type: "assignment",
        },
        {
          name: "Uploaded tasks",
          key: "uploaded",
          type: "assignment",
        },
        {
          name: "Skipped",
          key: "skipped",
          type: "assignment",
        },
      ],
    },

    {
      name: "Generate report",
      key: "report",
      type: "assignment",
    },
    { name: "Timesheet", key: "user/timesheet" },
    {
      name: "Allocation Id",
      key: "allocations",
      type: "allocations",
    },
  ],
};

const { Header, Content, Sider } = Layout;

const menu = (logout) => (
  <Menu>
    <Menu.Item>
      <Link to="/changePassword">Change Password</Link>
    </Menu.Item>
    <Menu.Item>
      <a onClick={logout}>Logout</a>
    </Menu.Item>
  </Menu>
);

const Dashboard = () => {
  const { logout, user } = useAuth();
  const history = useHistory();
  let [active, setActive] = useState(history.location.pathname.substring(1));
  const [collapsed, setCollapsed] = useState(true);

  const isHeadRecruiter = (user) =>
    user?.departments?.find((d) => d.name === "head-recruiter");

  const isRecruiter = (user) =>
    user?.departments?.find((d) => d.name === "recruiter");

  const reworks = user.reworks;

  if (
    isHeadRecruiter(user) &&
    !menuOpts[user.roleData[0]].find((d) => d.name === "Recruitment")
  ) {
    menuOpts[user.roleData[0]].push({
      name: "Recruitment",
      key: "recruitment",
    });
  }

  if (
    isRecruiter(user) &&
    !menuOpts[user.roleData[0]].find((d) => d.name === "Recruitment")
  ) {
    menuOpts[user.roleData[0]].push({
      name: "Recruitment",
      key: "recruit",
    });
  }

  useEffect(() => {
    let key = history.location.pathname.split("/");
    setActive(key[1]);
  }, [history.location.pathname]);

  const handleChange = (e) => {
    console.log(e);
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <Header className="header" style={{ padding: 0 }}>
        <div className="logo">
          <img src={logo} width={100} alt="vaidikedu logo" />
        </div>
        <div style={{ float: "right", marginRight: "2rem" }}>
          <Link to="/rework-received">
            {user?.roleData[0] === "AUTHOR" ||
            user?.roleData[0] === "REVIEWER" ? (
              <Tag color={reworks > 0 ? "orange" : "green"}>
                Reworks: {reworks}
              </Tag>
            ) : null}
          </Link>
          <Tag color="blue">{user.roleData[0]}</Tag>
          <Dropdown overlay={() => menu(logout)} placement="bottomCenter">
            <Button>Hi, {user.fullName}</Button>
          </Dropdown>
        </div>
      </Header>
      <Layout>
        <Sider
          width={245}
          collapsible
          defaultCollapsed
          collapsed={collapsed}
          onCollapse={() => setCollapsed(!collapsed)}
          collapsedWidth={0}
          className="site-layout-background"
        >
          <Menu
            theme="dark"
            mode="inline"
            onClick={({ key }) => {
              setCollapsed(true);
              setActive(key);
              history.push(`/${key}`);
            }}
            selectedKeys={[active]}
            defaultOpenKeys={["sub1"]}
            style={{ height: "100%", borderRight: 0 }}
          >
            {menuOpts[user?.roleData[0]]
              .filter((_) =>
                _.type === "assignment" && user.enableAssignments === false
                  ? false
                  : true
              )
              .map((k) =>
                k.type !== "menu" ? (
                  <Menu.Item key={k.key} icon={<FileTextOutlined />}>
                    {k.name}
                  </Menu.Item>
                ) : (
                  <Menu.SubMenu
                    title={k.name}
                    key={k.name}
                    icon={<FileTextOutlined />}
                  >
                    {k.keys.map((_) => (
                      <Menu.Item key={_.key}>{_.name}</Menu.Item>
                    ))}
                  </Menu.SubMenu>
                )
              )}
            {user.statusReportPermission ? (
              <Menu.Item
                key="status-report"
                onClick={() => {
                  history.push(`/status-report`);
                }}
              >
                Status Update
              </Menu.Item>
            ) : (
              ""
            )}
          </Menu>
        </Sider>

        <Layout style={{ padding: "0 24px 24px" }}>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              borderRadius: "2px",
              minHeight: 280,
              position: "relative",
            }}
          >
            <Switch>
              {/* <Route exact path="/tasks/:filename" component={(props) => <UserTasks type="assigned"  />} /> */}
              <Route
                exact
                path="/assigned/:filename"
                component={(props) => <UserTasks {...props} type="assigned" />}
              />
              <Route
                exact
                path="/skipApproved/:filename"
                component={(props) => (
                  <UserTasks {...props} type="skip-approved" />
                )}
              />
              <Route
                exact
                path="/rejected/:filename"
                component={(props) => <UserTasks {...props} type="rejected" />}
              />
              <Route
                exact
                path="/tobeskipped/:filename"
                component={(props) => (
                  <UserTasks {...props} type="to be skipped" />
                )}
              />
              <Route
                exact
                path="/approved/:filename"
                component={(props) => <UserTasks {...props} type="approved" />}
              />
              <Route
                exact
                path="/declined/:filename"
                component={(props) => <UserTasks {...props} type="declined" />}
              />
              <Route
                exact
                path="/skipped/:filename"
                component={(props) => <UserTasks {...props} type="skipped" />}
              />
              <Route
                exact
                path="/done/:filename"
                component={(props) => <UserTasks {...props} type="done" />}
              />
              <Route
                exact
                path="/uploaded/:filename"
                component={(props) => <UserTasks {...props} type="uploaded" />}
              />
              <Route
                exact
                path="/rework-sent/:filename"
                component={(props) => (
                  <UserTasks {...props} type="rework-sent" />
                )}
              />
              <Route
                exact
                path="/rework-received/:filename"
                component={(props) => (
                  <UserTasks {...props} type="rework-received" />
                )}
              />
              <Route
                exact
                path="/all/:filename"
                component={(props) => <UserTasks {...props} type="all" />}
              />

              <Route
                exact
                path="/assigned"
                component={(props) => <Assigned {...props} type="assigned" />}
              />
              <Route
                exact
                path="/skipApproved"
                component={(props) => (
                  <Assigned {...props} type="skip-approved" />
                )}
              />
              <Route
                exact
                path="/approved"
                component={(props) => <Assigned {...props} type="approved" />}
              />
              <Route
                exact
                path="/declined"
                component={(props) => <Assigned {...props} type="declined" />}
              />
              <Route
                exact
                path="/skipped"
                component={(props) => <Assigned {...props} type="skipped" />}
              />
              <Route
                exact
                path="/rejected"
                component={(props) => <Assigned {...props} type="rejected" />}
              />
              <Route
                exact
                path="/tobeskipped"
                component={(props) => (
                  <Assigned {...props} type="to be skipped" />
                )}
              />
              <Route
                exact
                path="/uploaded"
                component={(props) => <Assigned {...props} type="uploaded" />}
              />
              <Route
                exact
                path="/done"
                component={(props) => <Assigned {...props} type="done" />}
              />
              <Route
                exact
                path="/rework-sent"
                component={(props) => (
                  <Assigned {...props} type="rework-sent" />
                )}
              />
              <Route
                exact
                path="/rework-received"
                component={(props) => (
                  <Assigned {...props} type="rework-received" />
                )}
              />
              <Route
                exact
                path="/all"
                component={(props) => <Assigned {...props} type="all" />}
              />
              <Route
                exact
                path="/report/book/:filename"
                component={ViewReport}
                type="book"
              />
              <Route
                exact
                path="/report/book"
                component={(props) => <Report {...props} type="book" />}
              />
              <Route
                exact
                path="/report/user"
                component={(props) => <UserReport {...props} type="user" />}
              />
              <Route
                exact
                path="/report/department"
                component={(props) => (
                  <UserReport {...props} type="department" />
                )}
              />
              <Route
                exact
                path="/report/team"
                component={(props) => <UserReport {...props} type="team" />}
              />
              <Route
                exact
                path="/report/day"
                component={DaywiseReport}
                type="day"
              />
              <Route
                exact
                path="/report/author/:filename"
                component={ViewReport}
                type="author"
              />
              <Route
                exact
                path="/report/author"
                component={(props) => <Report {...props} type="author" />}
              />
              <Route
                exact
                path="/report/reviewer/:filename"
                component={ViewReport}
                type="reviewer"
              />
              <Route
                exact
                path="/report/reviewer"
                component={(props) => <Report {...props} type="reviewer" />}
              />
              <Route
                exact
                path="/report/reviewer-2/:filename"
                component={ViewReport}
                type="reviewer-2"
              />
              <Route
                exact
                path="/report/reviewer-2"
                component={(props) => <Report {...props} type="reviewer-2" />}
              />
              <Route
                exact
                path="/report/uploader/:filename"
                component={ViewReport}
                type="uploader"
              />
              <Route
                exact
                path="/report/uploader"
                component={(props) => <Report {...props} type="uploader" />}
              />
              <Route exact path="/report/-/:filename" component={ViewReport} />
              <Route exact path="/report" component={Report} />
              <Route
                exact
                path="/project/:sectionName/:department/:filename"
                component={File}
              />
              <Route
                exact
                path="/project/:sectionName/:filename/summary/details"
                component={FileSummary}
              />
              <Route
                exact
                path="/project/:sectionName"
                // component={SectionDetails}
                component={ProjectDepartmnent}
              />
              <Route
                exact
                path="/project/:sectionName/:department"
                component={SectionDetails}
              />
              <Route path="/targets" component={AddTargetProjects} />
              <Route path="/target/:projectId" component={AddTargetData} />
              <Route
                path="/target-assign/:projectId/:targetId"
                component={AssignTarget}
              />
              <Route
                path="/qualitativedata/projects"
                component={QualitativeProjects}
              />
              <Route exact path="/qualitative" component={AllQualitative} />
              <Route
                exact
                path="/qualitative-task/approved"
                component={ApprovedQualitative}
              />
              <Route
                exact
                path="/qualitativedata/all"
                component={AllQualitativeDataForAdmin}
              />

              {/* added by ayush  */}
              <Route exact path="/qualitative-approved-user" component={Task} />
              <Route exact path="/target-report" component={TargetReport} />

              <Route exact path="/manage-file" component={ManageFile} />
              <Route
                exact
                path="/manage-file/:manage_project_name"
                component={ManageFileName}
              />
              <Route
                exact
                path="/manage-file/:project/:fileName"
                component={AllTasklist}
              />
              <Route path="/projects">
                <ManageSections />
              </Route>

              <Route exact path="/users/:userId" component={UserSummary} />
              <Route path="/users">
                <ManageUsers />
              </Route>
              <Route path="/upload-allocation">
                <UploadAllocations />
              </Route>
              <Route path="/projectAllocations">
                <ProjectAllocations />
              </Route>
              <Route
                exact
                path="/projectAllocation/:projectId"
                component={DepartmnentAllocations}
              />
              <Route
                exact
                path="/projectAllocation/:projectId/:departmentId"
                component={UploadAllocations}
              />

              <Route exact path="/allocation/:project/:accountId">
                <SummaryAllocations />
              </Route>

              <Route path="/departments">
                <Departments />
              </Route>
              <Route
                exact
                path="/worklog/:profileId/targets/:targetId"
                component={Target}
              />
              <Route
                exact
                path="/worklog/:profileId/targets"
                component={Targets}
              />
              <Route
                exact
                path="/worklog/:subjectId/profiles"
                component={Profiles}
              />
              <Route
                exact
                path="/worklog/:projectId"
                component={ProjectDetails}
              />
              <Route
                exact
                path="/timesheet/:userId/manage"
                component={ManageSheet}
              />
              <Route
                exact={true}
                path="/timesheet/projects"
                component={TimesheetProjects}
              />
              <Route
                exact={true}
                path="/timesheet/users/:userId/projects"
                component={TimesheetUserDetails}
              />
              <Route
                exact={true}
                path="/timesheet/users"
                component={TimesheetUsers}
              />
              <Route
                exact
                path="/timesheet/:projectId"
                component={TimesheetProjectDetails}
              />
              <Route path="/worklog">
                <Worklog />
              </Route>
              <Route path="/user/worklog">
                <UserWorklog />
              </Route>
              <Route path="/user/timesheet/declined">
                <UserDeclinedTimesheet />
              </Route>
              <Route path="/user/timesheet">
                <UserTimesheet />
              </Route>
              <Route path="/recruitment/job/:jobId" component={Job} exact />
              <Route path="/recruitment">
                <Recruitment />
              </Route>
              <Route
                path="/recruit/:jobId/add"
                component={AddCandidate}
                exact
              />
              <Route exact path="/status-report" component={StatusReport} />
              <Route
                exact
                path="/status-report/current"
                component={CurrentStatusReportTable}
              />
              <Route
                exact
                path="/status-report/history"
                component={StatusReportTable}
              />
              <Route exact path="/allocations" component={UserAllocation} />
              <Route path="/recruit" component={UserRecruit} />
              <Route path="/changePassword">
                <ChangePassword />
              </Route>
              <Route
                exact
                path="/"
                component={() => (
                  <div style={{ textAlign: "center", color: "white" }}>
                    <h1>Hi, {user.fullName}</h1>
                    <span style={{ fontSize: "4rem" }}>
                      Welcome to Vaidik Eduservices Pvt. Ltd.
                    </span>
                    <div>
                      <img src={logo} alt="vaidikedu logo" />
                    </div>
                  </div>
                )}
              />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
