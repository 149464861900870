import React, { useState, useEffect } from "react";
import Table from "../../table";
import { useAsync } from "../../../utils/use-async";
import { client, fileClient } from "../../../utils/api-client";
import { Button, Popconfirm, message, Space } from "antd";
import AddJob from "./add-job";
import { Spinner } from "../../lib";
import { useHistory } from "react-router-dom";

const Jobs = ({ type }) => {
  const { run, isLoading, data } = useAsync();

  const [jobs, setJobs] = useState([]);
  const [users, setUsers] = useState([]);
  const [modal, setModal] = useState(false);
  const [jobsLoading, setJobsLoading] = useState(false);
  const history = useHistory();

  const [toDelete, setToDelete] = useState("");
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    if (data?.code === "JOB_FETCH_SUCCESS") {
      setJobs(transformTableData(data.data));
      setJobsLoading(false);
    }

    if (data?.code === "JOB_DELETE_ERROR") {
      message.error(data.message);
    }

    if (data?.code === "JOB_DELETE_SUCCESS") {
      message.success(data.message);
      fetchJobs();
    }

    if (data?.code === "FETCH_DEPARTMENT_SUCCESS") {
      setUsers(data?.data);
    }

    if (data?.message === "Project removed successfully") {
      message.success(data?.message);
      setToDelete("");
      fetchJobs();
    }

    if (data?.message === "Fetched projects") {
      setJobs(transformTableData(data.projects));
      setJobsLoading(false);
    }

    if (data?.type?.startsWith("application")) {
      var url = window.URL.createObjectURL(data);
      var a = document.createElement("a");
      a.href = url;
      if (downloading) {
        a.download = "report.xlsx";
        setDownloading(false);
      }
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    }
  }, [data]);

  const handleDelete = ({ _id }) => {
    setToDelete(_id);
    run(client(`recruit/job/${_id}`, { method: "DELETE", body: {} }));
  };

  const fetchUsers = () => {
    run(client(`department/users?department=recruiter`));
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      ellipsis: true,
    },
    {
      title: "Job Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Added By",
      dataIndex: "fullName",
      key: "fullName",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Actions",
      key: "x",
      dataIndex: "",
      render: (record) => (
        <>
          <Space>
            <Button
              type="primary"
              onClick={() => history.push(`/recruitment/job/${record._id}`)}
            >
              View
            </Button>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <Button type="danger">
                Delete{" "}
                {isLoading && toDelete === record.name ? <Spinner /> : ""}
              </Button>
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

  const transformTableData = (data) => {
    return data.map(({ addedBy: { fullName, email }, ...section }, index) => ({
      key: index,
      ...section,
      fullName,
      email,
      sr: index + 1,
    }));
  };

  const fetchJobs = () => {
    run(client(`recruit/job/get`));
    setJobsLoading(true);
  };

  useEffect(() => {
    fetchJobs();
    fetchUsers();
  }, []);

  const handleCancel = (e) => {
    setModal(false);
  };

  const showModal = () => setModal(true);

  const downloadReport = () => {
    run(fileClient(`recruit/report/download`, { method: "POST" }));
  };

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>Manage Jobs</h1>
        <div>
          <Button
            style={{ marginRight: "1rem" }}
            onClick={() => {
              setDownloading(true);
              downloadReport();
            }}
          >
            {downloading ? (
              <>
                Downloading{" "}
                <span style={{ marginLeft: "4px" }}>
                  <Spinner />
                </span>
              </>
            ) : (
              "Download Report"
            )}
          </Button>
          <Button type="ghost" onClick={showModal}>
            Create Job
          </Button>
        </div>
      </div>
      <Table
        loading={jobsLoading}
        data={jobs}
        columns={columns}
        size="middle"
      />
      <AddJob
        modal={modal}
        handleCancel={handleCancel}
        fetchJobs={fetchJobs}
        users={users}
        setModal={setModal}
      />
    </div>
  );
};

export default Jobs;
