import React from "react";
import { Modal, Form, Input, message, Button, InputNumber, Select } from "antd";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Spinner } from "../../../lib";

const AddJob = ({ modal, fetchJobs, handleCancel, setModal, users }) => {
  const { isLoading, run, isError, error } = useAsync();
  const addJob = (values) => {
    // const [start, end] = values.shiftTime;

    // const timeStr = `${moment(start).format("hh:mm a")} - ${moment(end).format(
    //   "hh:mm a"
    // )}`;

    // values.shiftTime = timeStr;
    run(
      client("recruit/job/create", { body: values }).then((res) => {
        message.success(res.message);
        setModal(false);
        fetchJobs();
      })
    );
  };

  return (
    <Modal title="Create Job" visible={modal} onCancel={handleCancel}>
      <Form
        size="middle"
        layout="horizontal"
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={addJob}
      >
        <Form.Item
          label="Job Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input job name!",
            },
          ]}
        >
          <Input autoComplete="off" placeholder="Enter job name" />
        </Form.Item>
        <Form.Item
          label="Location"
          name="location"
          rules={[
            {
              required: true,
              message: "Please input location!",
            },
          ]}
        >
          <Input placeholder="Eg. Bangalore, Delhi" />
        </Form.Item>
        <Form.Item
          label="Budget"
          name="budget"
          rules={[
            {
              required: true,
              message: "Please input budget!",
            },
          ]}
        >
          <InputNumber style={{ width: "200px" }} placeholder="Eg. 25000" />
        </Form.Item>
        <Form.Item
          label="Vacancy"
          name="vacancy"
          rules={[
            {
              required: true,
              message: "Please input vacancy!",
            },
          ]}
        >
          <InputNumber placeholder="Eg. 8" />
        </Form.Item>
        <Form.Item
          autoComplete="off"
          label="Timings"
          name="shiftTime"
          rules={[{ required: true, message: "Enter shift timings!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Recruiters"
          name="recruiters"
          rules={[{ required: true }]}
        >
          <Select placeholder="select" mode="multiple" allowClear>
            {users?.map((user, idx) => (
              <Select.Option key={`user-${idx}`} value={user._id}>
                {user.email}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }}>
          <Button type="primary" htmlType="submit">
            Submit{" "}
            {isLoading && (
              <span style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                <Spinner />
              </span>
            )}
          </Button>
        </Form.Item>
        {isError && <p style={{ color: "red" }}>{error.message}</p>}
      </Form>
    </Modal>
  );
};

export default AddJob;
