import React from "react";
import {
  Form,
  Input,
  Select,
  Collapse,
  Button,
  Space,
  Radio,
} from "antd";
import {
  CaretRightOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "./filter.css";

const Filter = ({ columnKeys, onFilterSubmit, onFilterClear }) => {
  const [filterForm] = Form.useForm();

  columnKeys = columnKeys.map((col) => col.title);

  const clearFilter = () => {
    filterForm.resetFields();
    onFilterClear();
  };

  const submitFilter = ({ searchKeys }) => onFilterSubmit(searchKeys);

  return (
    <Collapse
      className="site-collapse-custom-collapse"
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
    >
      <Collapse.Panel
        className="site-collapse-custom-panel"
        header="Apply filters"
        key="1"
      >
        <span style={{ margin: ".5rem", display: "inline-block" }}>
          <i>
            <b>Note : </b>Enter date in YYYY-MM-DD format
          </i>
        </span>
        <Form form={filterForm} onFinish={submitFilter} autoComplete="off">
          <Form.List name="searchKeys">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field) => (
                    <Space
                      key={field.key}
                      style={{ display: "flex" }}
                      align="start"
                    >
                      <Form.Item
                        {...field}
                        name={[field.name, "key"]}
                        fieldKey={[field.fieldKey, "key"]}
                        rules={[
                          {
                            required: true,
                            message: "Please select search key",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select column"
                          style={{ width: "200px" }}
                        >
                          {columnKeys.map((name) => (
                            <Select.Option key={name} value={name}>
                              {name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, "value"]}
                        fieldKey={[field.fieldKey, "value"]}
                      >
                        <Input placeholder="Enter search key" />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, "type"]}
                        fieldKey={[field.fieldKey, "type"]}
                        initialValue="exact"
                        label="Search by"
                        rules={[
                          {
                            required: true,
                            message: "Please select search by",
                          },
                        ]}
                      >
                        <Radio.Group buttonStyle="solid">
                          <Radio.Button value="exact">Exact keyword</Radio.Button>
                          <Radio.Button value="part">Part</Radio.Button>
                        </Radio.Group>
                      </Form.Item>

                      {field.fieldKey !== 0 && (
                        <Button
                          key={`cross-${field.key}`}
                          onClick={() => remove(field.name)}
                        >
                          &times;
                        </Button>
                      )}
                    </Space>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                    >
                      <PlusOutlined /> Select fields
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button type="dashed" onClick={clearFilter} htmlType="button">
              Clear
            </Button>
          </Form.Item>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};

export default Filter;
