import React, { useState, useEffect } from "react";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import { Button, Form, Input, InputNumber, Space, Select, message } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import dayJs from "dayjs";
import { useForm } from "antd/lib/form/Form";
import { useHistory } from "react-router-dom";

const UserTimesheet = () => {
  const history = useHistory();
  const { run, data, error } = useAsync();
  const [form] = useForm();

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (error) {
      message.warn(error.error);
    }
  }, [error]);

  useEffect(() => {
    if (data?.code === "PROJECTS_FETCH_SUCCESS") {
      setProjects(data.data);
    }

    if (data?.code === "TIMESHEET_SUBMIT_SUCCESS") {
      message.success(data.message);
      form.resetFields();
    }

    if (data?.code === "TIMESHEET_SUBMIT_ERR") {
      message.error(data.message)
      form.resetFields()
    }
  }, [data, form]);

  const fetchProjects = () => {
    run(client("timesheet/user/projects"));
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const submitSheet = (values) => {
    if ((values.projects && values.projects.length)) {
      run(
        client("timesheet/user", {
          body: { ...values },
          method: "POST",
        })
      );
    } else {
      message.error("Please enter atleast one project.");
    }
  };

  const getDate = (day) => dayJs().subtract(day, 'days').startOf('day').toString()

  const dates = [...new Set([getDate(2), getDate(1), getDate(0)])]

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1>Timesheet</h1>
        <Button
          type="primary"
          onClick={() => {
            history.push("./timesheet/declined");
          }}
        >
          View submitted timesheets
        </Button>
      </div>
      <div>
        <p>
          <em>Fill timesheet for {new Date().toLocaleDateString()}</em>
        </p>

        <Form style={{width: '350px'}} form={form} name="timesheet-form" onFinish={submitSheet}>
          <Form.Item label="Date" name="date" rules={[{ required: true }]}>
            <Select placeholder="Select date">
              {dates.map(d => <Select.Option value={d}>{new Date(d).toLocaleDateString()}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.List name="projects">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name }) => (
                  <Space key={key} style={{ display: "flex", marginBottom: 8 }}>
                    <Form.Item name={[name, "id"]}>
                      <Select
                        style={{ width: "220px" }}
                        placeholder="Select project"
                      >
                        {projects.map((p, key) => (
                          <Select.Option
                            key={key}
                            value={p._id}
                            rules={[{ required: true }]}
                          >
                            {p.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={[name, "done"]}
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        style={{ width: "100px" }}
                        placeholder="# of done"
                      />
                    </Form.Item>
                    <Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Form.Item>
                  </Space>
                ))}
                <Form.Item wrapperCol={{ span: 24 }}>
                  <Button block={true} onClick={() => add()}>
                    Fill Project
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item
            label="Your remarks"
            name="remarks"
            wrapperCol={{ span: 24 }}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default UserTimesheet;
