import React, { useState, useEffect } from "react";
import Table from "../../../table";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Button, message, Popconfirm, Space, Switch } from "antd";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../../context/auth-context";
import { Spinner } from "../../../lib";
import exportFromJSON from 'export-from-json'
import { useLocation } from 'react-router-dom';

const ManageFileName = ({
  match: {
    params: { manage_project_name },
  },
}) => {
  const { run, error, data, isLoading } = useAsync();
  const { user } = useAuth();
  const isAdmin = user.roleData.includes("ADMIN");
  const [users, setUsers] = useState([]);
  const [filesLoading, setFilesLoading] = useState(false);
  const [filename, setfilename] = useState(false);
  const [prepare,setPrepare]=useState(false)
  let [allProjects, setAllProjects] = useState([]);
  const location = useLocation();
  const { prev_record } = location.state || {};
  const handleToggle = (checked, key) => {
    const updatedUsers = users.map((user) =>
      user._id === key ? { ...user, isFileVisible: checked } : user
    );
    setUsers(updatedUsers);

    run(
      client(`manage-file/update/project/file/${key}`, {
        method: "PUT",
        body: { file_visibility: checked },
      })
    )
      .then((response) => {})
      .catch((error) => {
        console.error("Error", error);
        const revertedUsers = users.map((user) =>
          user._id === key ? { ...user, isFileVisible: !checked } : user
        );
        setUsers(revertedUsers);
        message.error("Failed to update file visibility.");
      });
  };

  const handleDeleteFile = (name) => {
    run(
      client(`manage-file/delete/all/records/${name}`, {
        method: "DELETE",
      })
    )
      .then((res) =>{
        
        message.success(res.message)
        fetchSection(manage_project_name);


      })
      
  };

  const fetchSection = (name) => {
    run(client(`manage-file/${manage_project_name}/?name=${name}`));
    setFilesLoading(true);
  };

  useEffect(() => {
    fetchSection(manage_project_name);
  }, []);

  useEffect(() => {
    if (data?.message === "Fetched section") {
      setUsers(transformTableData(data?.data));
      setFilesLoading(false);
    }

    if (data?.message === "File deleted successfully") {
      message.success(data?.message);
      fetchSection(manage_project_name);
    }
    if (data?.message === "Fetched Data") {
      setAllProjects(data?.projects)
      console.log("data is ready for download")
    }
  }, [data]);

  useEffect(() => {
    if (error?.message === "Section not found") {
      setFilesLoading(false);
      message.error(error.message);
    }
  }, [error]);
  const fetchAllProjectData = (prev_record,record) => {
    run(client(`project/details/all/${prev_record.name}/${record.name}`))
    setAllProjects(data.projects)
  }

  const handleDownload = (record) => {
  fetchAllProjectData(prev_record,record)
    // run(client(`project/details/all/${prev_record.name}/${record.name}`))
    const fileName = `file_Data`
    const exportType = "xls"
    if(allProjects.length==0)
    {
      return console.log("data is not available")
    }
    exportFromJSON({ data: allProjects, fileName, exportType })
    setAllProjects([])
  }
  const history = useHistory();
  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      ellipsis: true,
    },
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Actions",
      key: "x",
      dataIndex: "",
      render: (record) => (
        <Space>
          <Button
            type="primary"
            onClick={() =>
              history.push(`/manage-file/${manage_project_name}/${record.name}`)
            }
          >
            Manage Tasks
          </Button>
          <Button variant="outlined" onClick={()=>handleDownload(record)} type=""> Download Record</Button>

          {isAdmin && (
            <Switch
              checkedChildren="View Summary"
              unCheckedChildren="Hide Summary"
              onChange={(checked) => handleToggle(checked, record._id)}
              checked={record.isFileVisible}
              style={{ width: "59px" }}
            />
          )}
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDeleteFile(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">Delete Records</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const transformTableData = (data) =>
    data.map(({ name, path, _id, file_visibility }, index) => ({
      key: index,
      name,
      path,
      _id,
      sr: index + 1,
      isFileVisible: file_visibility,
    }));

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>All Files</h1>
      </div>
      <Table
        loading={filesLoading}
        data={users}
        columns={columns}
        size="middle"
        pagination={{
          defaultPageSize: 100,
          current: 1,
          pageSizeOptions: ["100", "200", "500"],
        }}
      />
    </div>
  );
};

export default ManageFileName;
