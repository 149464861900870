import React, { useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  InputNumber,
  Row,
  Col,
  Avatar,
  Table,
  Switch,
  message,
} from "antd";
import { UserOutlined } from "@ant-design/icons"; // For user icon
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import { useAuth } from "../../../context/auth-context";

const { Option } = Select;

const DataForm = ({ onSubmit, users, fetchUsers }) => {
  const { run, isLoading, data, isError, error } = useAsync();
  const [form] = Form.useForm();
  const [status, setStatus] = useState("");
  const [authoringStatus, setAuthoringStatus] = useState("");
  const [qcStatus, setQcStatus] = useState("");
  const { user } = useAuth();
  const isAdmin = user.roleData.includes("ADMIN");
  const handleStatusChange = (value) => {
    setStatus(value);
    form.resetFields([
      "authoring_status",
      "authoring_limit",
      "qc_status",
      "qc_limit",
      "ps_limit",
      "qps_limit",
      "technical_issues",
      "remarks",
    ]);
    setAuthoringStatus("");
    setQcStatus("");
  };

  const handleAuthoringStatusChange = (value) => {
    setAuthoringStatus(value);
    if (value === "no") {
      form.setFieldsValue({ authoring_limit: "NA" });
    } else {
      form.resetFields(["authoring_limit"]);
    }
  };

  const handleQcStatusChange = (value) => {
    setQcStatus(value);
    if (value === "no") {
      form.setFieldsValue({ qc_limit: "NA" });
    } else {
      form.resetFields(["qc_limit"]);
    }
  };

  const onFinish = (values) => {
    onSubmit({
      ...values,
      userId: values.user_id,
    });
    form.resetFields();
    setStatus("");
    setAuthoringStatus("");
    setQcStatus("");
  };

  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(users);
  const [searchTableText, setSearchTableText] = useState("");
  const [filteredDatatable, setfilteredDatatable] = useState(users);
  const handleSearch = (value) => {
    setSearchText(value);
    if (value.trim() === "") {
      setFilteredData(users);
    } else {
      const filtered = users.filter(
        (user) =>
          user.email.toLowerCase().includes(value.toLowerCase()) ||
          user.subject.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };
  const handleSearchTable = (value) => {
    setSearchTableText(value);
    if (value.trim() === "") {
      setfilteredDatatable(users);
    } else {
      const filtered = users.filter(
        (user) =>
          user.email.toLowerCase().includes(value.toLowerCase()) ||
          user.subject.toLowerCase().includes(value.toLowerCase())
      );
      setfilteredDatatable(filtered);
    }
  };
  const handleActiveChange = (e, id) => {
    run(
      client("update/active/status", {
        body: { id, e },
        method: "PUT",
      }).then((res) => {
        message.success(res.message);
      })
    );
    fetchUsers();
  };
  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      sorter: {
        compare: (a, b) => a.department.length - b.department.length,
        multiple: 3,
      },
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      sorter: {
        compare: (a, b) => a.subject.length - b.subject.length,
        multiple: 3,
      },
    },

    // {
    //   title: "Action",
    //   key: "action",
    //   render: (text, record) => (
    //     <Switch
    //       defaultChecked={record?.is_active}
    //       onClick={(checked) => handleActiveChange(checked, record._id)} // On toggle, update the status
    //       checkedChildren="Active"
    //       unCheckedChildren="Inactive"
    //     />
    //   ),
    // },
  ];
  if (isAdmin) {
    columns.push({
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Switch
          defaultChecked={record?.is_active}
          onClick={(checked) => handleActiveChange(checked, record._id)} // On toggle, update the status
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    });
  }
  return (
    <div style={{ display: "flex", width: "100%" }} className="manage--wrapper">
      <div style={{ width: "50%", padding: "16px" }}>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={[16, 16]} justify="center">
            <Col span={24} style={{ width: "100%" }}>
              <Form.Item
                name="user_id"
                label="Select User"
                rules={[{ required: true, message: "Please select a user!" }]}
              >
                <Select
                  placeholder="Select a user"
                  style={{ width: "100%" }}
                  showSearch
                  onSearch={handleSearch}
                  filterOption={false}
                >
                  {users
                    .filter((user) =>
                      user.email
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    )
                    .filter((user) => user.is_active)
                    .map((user) => (
                      <Option key={user._id} value={user?._id}>
                        <Avatar
                          style={{ marginRight: 8 }}
                          icon={<UserOutlined />}
                          size="small"
                        />
                        {user?.email} <br></br>{" "}
                        <span style={{ fontSize: "12px", color: "#888" }}>
                          {user.department}
                        </span>
                        <span style={{ fontSize: "12px", color: "#888",marginLeft:'1rem' }}>
                          {user.subject}
                        </span>
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} style={{ width: "100%" }}>
              <Form.Item
                name="status"
                label="Status"
                rules={[
                  { required: true, message: "Please select the status!" },
                ]}
              >
                <Select
                  onChange={handleStatusChange}
                  placeholder="Select Status"
                >
                  <Option value="application-error">Application Error</Option>
                  <Option value="accessible">Accessible</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {status !== "application-error" && (
            <>
              <Row gutter={[16, 16]} justify="center">
                <Col span={24} style={{ width: "100%" }}>
                  <Form.Item
                    name="authoring_status"
                    label="Authoring Status"
                    rules={[
                      {
                        required: true,
                        message: "Please select the authoring status!",
                      },
                    ]}
                  >
                    <Select
                      onChange={handleAuthoringStatusChange}
                      placeholder="Select Authoring Status"
                    >
                      <Option value="yes">Yes</Option>
                      <Option value="no">No</Option>
                      <Option value="PS">PS</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24} style={{ width: "100%" }}>
                  {authoringStatus === "yes" && (
                    <Form.Item
                      name="authoring_limit"
                      label="Authoring Limit"
                      rules={[
                        {
                          required: true,
                          message: "Please input the authoring limit!",
                        },
                      ]}
                    >
                      <InputNumber min={0} style={{ width: "100%" }} value={''} />
                    </Form.Item>
                  )}
                  {authoringStatus === "no" && (
                    <Form.Item
                      name="authoring_limit"
                      label="Authoring Limit"
                      rules={[
                        {
                          required: true,
                          message: "Please select the authoring limit!",
                        },
                      ]}
                    >
                      <Select>
                        <Option value="NA">NA</Option>
                      </Select>
                    </Form.Item>
                  )}
                  {authoringStatus === "PS" && (
                    <Form.Item
                      name="ps_limit"
                      label="PS Status"
                      rules={[
                        {
                          required: true,
                          message: "Please select the PS Status!",
                        },
                      ]}
                    >
                      <Select>
                        <Option value="1st attempt failed">
                          1st Attempt Failed
                        </Option>
                        <Option value="both attempt failed">
                          Both Attempt Failed
                        </Option>
                        <Option value="PS ongoing">PS Ongoing</Option>
                      </Select>
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify="center">
                <Col span={24} style={{ width: "100%" }}>
                  <Form.Item
                    name="qc_status"
                    label="QC Status"
                    rules={[
                      {
                        required: true,
                        message: "Please select the QC status!",
                      },
                    ]}
                  >
                    <Select
                      onChange={handleQcStatusChange}
                      placeholder="Select QC Status"
                    >
                      <Option value="yes">Yes</Option>
                      <Option value="no">No</Option>
                      <Option value="PS">PS</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24} style={{ width: "100%" }}>
                  {qcStatus === "yes" && (
                    <Form.Item
                      name="qc_limit"
                      label="QC Limit"
                      rules={[
                        {
                          required: true,
                          message: "Please input the QC limit!",
                        },
                      ]}
                    >
                      
                      <InputNumber min={0} style={{ width: "100%" }} />
                    </Form.Item>
                  )}
                  {qcStatus === "no" && (
                    <Form.Item
                      name="qc_limit"
                      label="QC Limit"
                      rules={[
                        {
                          required: true,
                          message: "Please select the QC limit!",
                        },
                      ]}
                    >
                      <Select>
                        <Option value="NA">NA</Option>
                      </Select>
                    </Form.Item>
                  )}
                  {qcStatus === "PS" && (
                    <Form.Item
                      name="qps_limit"
                      label="PS Status"
                      rules={[
                        {
                          required: true,
                          message: "Please select the PS status!",
                        },
                      ]}
                    >
                      <Select>
                        <Option value="1st attempt failed">
                          1st Attempt Failed
                        </Option>
                        <Option value="both attempt failed">
                          Both Attempt Failed
                        </Option>
                        <Option value="PS ongoing">PS Ongoing</Option>
                      </Select>
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify="center">
                <Col span={24} style={{ width: "100%" }}>
                  <Form.Item
                    name="technical_issues"
                    label="Any Technical Issues"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please select if there are any technical issues!",
                      },
                    ]}
                  >
                    <Select placeholder="Select Technical Issues">
                      <Option value="yes">Yes</Option>
                      <Option value="no">No</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24} style={{ width: "100%" }}>
                  <Form.Item
                    name="remarks"
                    label="Remarks"
                    dependencies={["technical_issues"]}
                    rules={[
                      ({ getFieldValue }) => ({
                        required: getFieldValue("technical_issues") === "yes",
                        message:
                          "Please provide remarks if there are technical issues!",
                      }),
                    ]}
                  >
                    <Input.TextArea
                      rows={4}
                      placeholder="Enter remarks here..."
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit" className="submit-button">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>

      <div style={{ width: "50%", padding: "16px" }}>
        <Input.Search
          placeholder="Search by email or subject"
          value={searchTableText}
          onChange={(e) => handleSearchTable(e.target.value)}
          style={{ marginBottom: 16 }}
        />
        <Table
          columns={columns}
          dataSource={
            filteredDatatable.length === 0 ? users : filteredDatatable
          }
          showSorterTooltip={{
            target: 'sorter-icon',
          }}
          // pagination={{ pageSize: 10 }}
        />
      </div>
    </div>
  );
};

export default DataForm;
