import { queryCache } from "react-query";
const localStorageKey = "__vaidikedu_token__";
const authorStorageKey= "ROLE";

async function client(endpoint, { body, ...customConfig } = {}) {
  const token = window.localStorage.getItem(localStorageKey);
  const headers = {};
  if (customConfig.type !== "formData") {
    headers["content-type"] = "application/json";
  }
  if (token) {
    headers["x-auth-token"] = token;
  }
  const config = {
    method: customConfig?.method ? customConfig.method : body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.body =
      customConfig.type !== "formData" ? JSON.stringify(body) : body;
  }

  return window
    .fetch(`https://work.vaidikedu.com/api/v1/${endpoint}`, config)
    .then(async (r) => {
      if (r.status === 401) {
        logout();
        // refresh the page for them
        window.location.assign(window.location);
        return;
      }
      const data = await r.json();
      if (data.success) {
        return data;
      } else {
        return Promise.reject(data);
      }
    });
}

async function fileClient(endpoint, { body, ...customConfig } = {}) {
  const token = window.localStorage.getItem(localStorageKey);
  const headers = {};
  if (customConfig.type !== "formData") {
    headers["content-type"] = "application/json";
  }

  if (token) {
    headers["x-auth-token"] = token;
  }

  const config = {
    method: customConfig?.method ? customConfig.method : body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body =
      customConfig.type !== "formData" ? JSON.stringify(body) : body;
  }

  return window
    .fetch(`https://work.vaidikedu.com/api/v1/${endpoint}`, config)
    .then((response) => response.blob())
    .then((blob) => {
      return blob;
    });
}

async function logout() {
  await queryCache.clear();
  await window.localStorage.removeItem(localStorageKey);
  await window.localStorage.removeItem(authorStorageKey);
}

export { client, fileClient, localStorageKey, logout ,authorStorageKey};
