import React, { useState, useEffect } from "react";
import "./details.css";
import Table from "../../../table";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Button, message, Popconfirm, Breadcrumb } from "antd";
import AddUser from "../add-user";
import { Link } from "react-router-dom";
import RemoveUser from "../remove-user";

const   TimesheetProjectDetails = ({
  match: {
    params: { projectId },
  },
}) => {
  const { run, error, data, isLoading } = useAsync();

  let [users, setUsers] = useState([]);
  let [modal, setModal] = useState(false);
  let [allocations, setAlocations] = useState([]);
  let [recordData, setRecordData] = useState([]);
  let [remove, setRemove] = useState(false)
  let [removeModal, setRemoveModal]=useState(false)
  let [usersLoading, setUsersLoading] = useState(false);
  let [currentProject, setCurrentProject] = useState({});

  useEffect(() => {
    if (data?.message === "Fetched project") {
      const { users, ...project } = data.data;
      setUsers(users);
      setAlocations(data?.allocations)
      setCurrentProject(project);
      setUsersLoading(false);
    }

    if (data?.code === "PROJECT_DELETE_ERR") {
      message.error(data.message);
    }

    if (data?.code === "USER_REMOVE_SUCCESS") {
      message.success(data.message);
      fetchProject(projectId);
    }
  }, [data, removeModal]);

  useEffect(() => {
    if (error?.message === "Project not found") {
      setUsersLoading(false);
      message.error(error.message);
    }
  }, [error]);

  const getAccountId = (email)=> {
    const filterArray = allocations.filter((e)=> {
      return e.current_user === email;
    })
    return filterArray[0]?.account_id
  } 

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      ellipsis: true,
    },
    {
      title: "Name",
      dataIndex: "fullName",
      key: "name",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Allocation Id",
      render : (record)=>
      (
        <>
        {getAccountId(record.email)}
        </>
      )
    },
    {
      title: "Actions",
      key: "x",
      dataIndex: "",
      render: (record) => (
        <>
        <Popconfirm
          title="Sure to remove?"
          onConfirm={() => 
         { 
          setRemoveModal(true)
          setRecordData(record)
         }
          }
        >
          {/* removeUser(record) */}
          <Button type="danger" disabled={isLoading}>
            Remove
          </Button>
        </Popconfirm>
       
      </>
      ),
    },
  ];

  const transformTableData = (data) =>
    data.map(({ name, ...data }, index) => ({
      key: index,
      name,
      sr: index + 1,
      ...data,
    }));

  const fetchProject = (id) => {
    run(client(`timesheet/project/${id}`));
    setUsersLoading(true);
  };

  useEffect(() => {
    fetchProject(projectId);
  }, [currentProject.name]);

  const handleCancel = (e) => {
    setModal(false);
  };
  const handleCancelRemove = () => {
    setRemoveModal(false)
  }

  const showModal = () => setModal(true);

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/timesheet">Timesheet</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{currentProject.name}</Breadcrumb.Item>
          </Breadcrumb>
          <h1>Available users</h1>
        </div>
        <Button type="ghost" onClick={showModal}>
          Add Users
        </Button>
      </div>
      <Table
        loading={usersLoading}
        data={transformTableData(users)}
        columns={columns}
        size="middle"
        paginationConfig={{ defaultPageSize: 50 }}
      />
      <AddUser
        modal={modal}
        handleCancel={handleCancel}
        fetchProject={fetchProject}
        project={projectId}
        addedUsers={users}
        setModal={setModal}
        remove = {remove}
      />
       <RemoveUser
        modal={removeModal}
        handleCancel={handleCancelRemove}
        project={projectId}
        fetchProject={fetchProject}
        setRemoveModal={setRemoveModal}
        setRemove={setRemove}
        recordData={recordData}
      />
    </div>
  );
};

export default TimesheetProjectDetails;