import React, { useEffect, useState } from "react";
import { Table, Spin, Alert, Button, Input, Row, Col } from "antd";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import exportFromJSON from "export-from-json";
import { useAuth } from "../../../context/auth-context";

const columns = [
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Department",
    dataIndex: "department",
    key: "department",
  },
  {
    title: "Subject",
    dataIndex: "subject",
    key: "subject",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Authoring Status",
    dataIndex: "authoring_status",
    key: "createdAt",
  },
  {
    title: "Authoring Limit",
    dataIndex: "authoring_limit",
    key: "authoring_limit",
  },
  {
    title: "PS Status",
    dataIndex: "ps_limit",
    key: "ps_limit",
  },
  {
    title: "QC Status",
    dataIndex: "qc_status",
    key: "qc_status",
  },
  {
    title: "QC Limit",
    dataIndex: "qc_limit",
    key: "qc_limit",
  },
  {
    title: "PS Status",
    dataIndex: "qps_limit",
    key: "qps_limit",
  },
  {
    title: "Technical Isuue",
    dataIndex: "technical_issues",
    key: "technical_issues",
  },
  {
    title: " Remarks",
    dataIndex: "remarks",
    key: "remarks",
  },
  {
    title: "Updated By",
    dataIndex: ["updated_by", "email"],
    key: "updated_by",
  },
];

const CurrentStatusReportTable = () => {
  const { run, isLoading, data, error } = useAsync();
  const { user } = useAuth();
  const [subjectSearch, setSubjectSearch] = useState("");
  const [emailSearch, setEmailSearch] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      await run(client(`/fetch/current/status/${user.email}`));
    };
    fetchUsers();
  }, []);

  const handlePrint = () => {
    const fileName = "Current Status Report";
    const exportType = "xls";
    const flattenedData = data?.data?.map((item) => ({
      Email: item?.email,
      Department: item?.department,
      Subject: item.subject,
      Status: item.status,
      "Authoring Status": item.authoring_status,
      "Authoring Limit": item.authoring_limit,
      "PS Status":item.ps_limit,
      "QC Status": item.qc_status,
      "QC Limit": item.qc_limit,
      "PS Status":item.qps_limit,
      "Technical Issues": item.technical_issues,
      Remarks: item.remarks,
      "Updated By": item.updated_by?.email,
      "Updated At": new Date(item.updatedAt).toLocaleString(),
    }));

    exportFromJSON({ data: flattenedData, fileName, exportType });
  };

  const filteredData = data?.data?.filter((item) => {
    const subjectMatch = item.subject
      ?.toLowerCase()
      .includes(subjectSearch.toLowerCase());
    const emailMatch = item.email
      ?.toLowerCase()
      .includes(emailSearch.toLowerCase());
    return subjectMatch && emailMatch;
  });

  const inputStyle = {
    borderRadius: "8px",
    padding: "8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    border: "1px solid #d9d9d9",
    width: "100%",
  };

  return (
    <div className="manage--wrapper" style={{ padding: "20px" }}>
      <h1>Status Reports</h1>

      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Input
            placeholder="🔍 Search by Subject"
            value={subjectSearch}
            onChange={(e) => setSubjectSearch(e.target.value)}
            style={inputStyle}
          />
        </Col>
        <Col span={8}>
          <Input
            placeholder="🔍 Search by Email"
            value={emailSearch}
            onChange={(e) => setEmailSearch(e.target.value)}
            style={inputStyle}
          />
        </Col>
        <Col span={8}>
          <Button
            onClick={handlePrint}
            style={{ float: "right", marginBottom: "2%", borderRadius: "8px" }}
            type="primary"
          >
            Download Current Status
          </Button>
        </Col>
      </Row>

      {isLoading ? (
        <Spin size="large" />
      ) : error ? (
        <Alert
          message="Error"
          description={error.message}
          type="error"
          showIcon
        />
      ) : (
        <Table dataSource={filteredData} columns={columns} rowKey="_id" />
      )}
    </div>
  );
};

export default CurrentStatusReportTable;
