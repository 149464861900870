import React, { useEffect } from "react"
import { useAuth } from "./context/auth-context"
import { FullPageSpinner } from "./components/lib"
import AuthenticatedApp from "./components/dashboard"
import { useHistory } from "react-router-dom"

// const AuthenticatedApp = React.lazy(() =>
//   import(/* webpackPrefetch: true */ "./components/dashboard")
// )
const UnauthenticatedApp = React.lazy(() => import("./components/login"))

function App() {
  const { user } = useAuth()

  const history = useHistory()

  useEffect(() => {
    if (!user) {
      history.replace('/')
    }
  }, [user])
  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  )
}

export default App
