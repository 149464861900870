import React from "react";
import { Modal, Form, Input, message, Button } from "antd";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Spinner } from "../../../lib";

const AddProject = ({ modal, fetchProjects, handleCancel, setModal }) => {
  const { isLoading, run, isError, error } = useAsync();
  const addProject = (values) => {
    run(
      client("timesheet/project/new", { body: values }).then((res) => {
        message.success(res.message);
        setModal(false);
        fetchProjects();
      })
    );
  };

  return (
    <Modal title="Add Project" visible={modal} onCancel={handleCancel}>
      <Form
        size="middle"
        layout="horizontal"
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={addProject}
      >
        <Form.Item
          label="Full Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input project name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }}>
          <Button type="primary" htmlType="submit">
            Submit{" "}
            {isLoading && (
              <span style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                <Spinner />
              </span>
            )}
          </Button>
        </Form.Item>
        {isError && <p style={{ color: "red" }}>{error.message}</p>}
      </Form>
    </Modal>
  );
};

export default AddProject;
