import React from "react";
import { Modal, Form, Input, message, Button } from "antd";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Spinner } from "../../../lib"
import { useAuth } from "../../../../context/auth-context";

const AssignAll = ({ modal, setModal, projectId , targetId,fetchProjects }) => {
    const { isLoading, run, isError, error } = useAsync();
    const { user } = useAuth();
    const [userForm] = Form.useForm();

    const addSection = (values) => {
        run(client(`target/assigned/${projectId}/${targetId}`, { body: { ...values, addedBy: user._id } }).then(res => {
            message.success(res.message)
            setModal(false)
            fetchProjects();
        }))
    };

    const handleCancel = () => {
        setModal(false);
        userForm.resetFields();
    }

    return (
        <Modal title="Assign Target" visible={modal} onCancel={handleCancel}>
            <Form
                form={userForm}
                size="middle"
                layout="horizontal"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 14,
                }}
                onFinish={addSection}
            >
                <Form.Item
                    label="Task Count"
                    name="target_assigned"
                    rules={[
                        {
                            required: true,
                            message: "Please enter task count!",
                        },
                    ]}
                >
                    <Input type="Number"/>
                </Form.Item>
                <Form.Item
                    label="Effective Date"
                    name="dateEffective"
                    rules={[{ required: true, message: "Please enter a effective date" }]}
                >
                    <Input type="date" />

                </Form.Item>
                <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }}>
                    <Button type="primary" htmlType="submit">
                        Submit{" "}
                        {isLoading && (
                            <span style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                                <Spinner />
                            </span>
                        )}
                    </Button>
                </Form.Item>
                {isError && <p style={{ color: "red" }}>{error.message}</p>}
            </Form>
        </Modal>
    );
};

export default AssignAll;
