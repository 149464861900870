import React, { useState, useEffect } from "react";
import { Modal, message, Button, Upload, Table } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import "./report.css";
import exportFromJSON from "export-from-json";

const DepartmentReportCount= ({ modal, setModal, allCount }) => {
  const { isLoading, run, error } = useAsync();

  useEffect(() => {
    if (error) {
      message.error(error?.message);
    }
  }, [error]);

  const handleCancel = ()=> {
    setModal(false);
  }

  const handlePrintCount = () => {
    const fileName = "Count_Report"
    const exportType = "xls"
    exportFromJSON({ data: allCount, fileName, exportType })
  }

  return (
    <Modal title="Count Report" visible={modal} onCancel={handleCancel}>
      <div style={{ marginLeft: "2rem" }}>
        
        <Button
          type="primary"
          loading={isLoading}
          style={{ marginBottom:'1rem' }}
          onClick={()=>handlePrintCount()}
        >
          Download Data  
        </Button>
        <table className="tableClass">
      <thead>
        <tr>
          <th className="thClass" style={{fontSize:'1.3rem'}}>Email</th>
          <th className="thClass" style={{fontSize:'1.3rem'}}>Count</th>
        </tr>
      </thead>
      <tbody>
        {allCount?.map((entry, index) => (
          <tr key={index}>
            <td className="thClass" style={{fontSize:'1.2rem'}}>{entry.email}</td>
            <td className="thClass" style={{fontSize:'1.2rem'}}>{entry.count}</td>
          </tr>
        ))}
      </tbody>
    </table>
      </div>
    </Modal>
  );
};

export default DepartmentReportCount;
