import React, { useEffect, useState } from "react";
import { Modal, Tabs, Form, Select, Button } from "antd";
import { client, fileClient } from "../../../../utils/api-client";
import { useAsync } from "../../../../utils/use-async";
import { Spinner } from "../../../lib";

const Report = ({ modal, handleCancel }) => {
  const { run, data } = useAsync();
  const { TabPane } = Tabs;

  const [departments, setDepartments] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [users, setUsers] = useState([]);

  const fetchDepartments = () => {
    run(client(`department/all`));
  };

  const fetchProfiles = () => {
    run(client(`profile/all`));
  };

  const fetchUsers = () => {
    run(client(`admin/users`));
  };

  const downloadReport = (query) => {
    setDownloading(true);

    run(
      fileClient(
        `worklog/report?${Object.keys(query)[0]}=${Object.values(query)[0]}`,
        { method: "GET" }
      )
    );
  };

  useEffect(() => {
    if (data?.message === "Fetched departments") {
      setDepartments(data.departments);
    }

    if (data?.code === "PROFILE_FETCH_SUCCESS") {
      setProfiles(data.data);
    }

    if (data?.message === "Fetched users") {
      setUsers(data.users);
    }

    if (data?.type?.startsWith("application")) {
      var url = window.URL.createObjectURL(data);
      var a = document.createElement("a");
      a.href = url;
      if (downloading) {
        a.download = "report.xlsx";
        setDownloading(false);
      }
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    }
  }, [data]);

  useEffect(() => {
    fetchDepartments();
    fetchProfiles();
    fetchUsers();
  }, []);

  return (
    <Modal title="Generate Report" visible={modal} onCancel={handleCancel}>
      <Tabs defaultActiveKey="department" onChange={(k) => console.log(k)}>
        <TabPane tab="Department" key="department">
          <Form onFinish={downloadReport}>
            <Form.Item
              label="Select department"
              name="department"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="select"
              >
                {departments.map((department, idx) => (
                  <Select.Option
                    key={`department-${idx}`}
                    value={department._id}
                  >
                    {department.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
              <Button type="primary" htmlType="submit">
                {downloading ? (
                  <>
                    Fetching report{" "}
                    <span style={{ marginLeft: "4px" }}>
                      <Spinner />
                    </span>
                  </>
                ) : (
                  "Download Report"
                )}
              </Button>
            </Form.Item>
          </Form>
        </TabPane>
        <TabPane tab="Profile" key="profile">
          <Form onFinish={downloadReport}>
            <Form.Item
              label="Select profile"
              name="profile"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="select"
              >
                {profiles.map((profile, idx) => (
                  <Select.Option key={`profile-${idx}`} value={profile._id}>
                    {profile.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
              <Button type="primary" htmlType="submit">
                {downloading ? (
                  <>
                    Fetching report{" "}
                    <span style={{ marginLeft: "4px" }}>
                      <Spinner />
                    </span>
                  </>
                ) : (
                  "Download Report"
                )}
              </Button>
            </Form.Item>
          </Form>
        </TabPane>
        <TabPane tab="User" key="user">
          <Form onFinish={downloadReport}>
            <Form.Item
              label="Select user"
              name="user"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="select"
              >
                {users.map((user, idx) => (
                  <Select.Option key={`profile-${idx}`} value={user._id}>
                    {user.email}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
              <Button type="primary" htmlType="submit">
                {downloading ? (
                  <>
                    Fetching report{" "}
                    <span style={{ marginLeft: "4px" }}>
                      <Spinner />
                    </span>
                  </>
                ) : (
                  "Download Report"
                )}
              </Button>
            </Form.Item>
          </Form>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default Report;
