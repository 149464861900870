import React from "react";
import { Modal, Form, Input, message, Button, Select } from "antd";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Spinner } from "../../../lib"
import { useAuth } from "../../../../context/auth-context";

const AddTargetModal = ({ modal, fetchProjects, setModal, allDepartments, projectId }) => {
  const { isLoading, run, isError, error } = useAsync();
  const { user } = useAuth();
  const [userForm] = Form.useForm();

  const addSection = (values) => {
 
    run(client(`targets/project/add/${projectId}`, { body: { ...values, addedBy: user._id } }).then(res => {
      setModal(false)
      fetchProjects();
    }
    ))
  };

  return (
    <Modal title="Add Target Data" visible={modal} onCancel={() => {
      setModal(false)
      userForm.resetFields();
    }}>
      <Form
        size="middle"
        layout="horizontal"
        form={userForm}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={addSection}
      >
        <Form.Item
          label="Sub Project Name"
          name="sub_project_name"
          rules={[
            {
              required: true,
              message: "Please input sub project name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Select Department" name="department"
          rules={[
            {
              required: true,
              message: "Please input department!",
            },
          ]}
        >
          <Select
            placeholder="Department"
            showSearch={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              allDepartments.map((e) =>
              (
                <Select.Option key={e._id} value={e._id}>{e.name}</Select.Option>
              )
              )
            }
          </Select>
        </Form.Item>
        <Form.Item
          label="Subject"
          name="subject"
          rules={[
            {
              required: true,
              message: "Please input subject name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Role"
          name="role"
          rules={[
            {
              required: true,
              message: "Please input role!",
            },
          ]}
        >

          <Select
            name="role"
          >
            <Select.Option value="AUTHOR">Author</Select.Option>
            <Select.Option value="REVIEWER">Reviewer</Select.Option>
            <Select.Option value="REVIEWER-2">Reviewer-2</Select.Option>
            <Select.Option value="UPLOADER">Uploader</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Keyword"
          name="keyword"
          rules={[
            {
              required: true,
              message: "Please input subject name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }}>
          <Button type="primary" htmlType="submit">
            Submit{" "}
            {isLoading && (
              <span style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                <Spinner />
              </span>
            )}
          </Button>
        </Form.Item>
        {isError && <p style={{ color: "red" }}>{error.message}</p>}
      </Form>
    </Modal>
  );
};

export default AddTargetModal;
