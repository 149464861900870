import React from "react";

const Card = ({ title, value }) => {
  return (
    <div
      style={{
        margin: "1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        border: "1px solid #888",
        borderRadius: "5px",
        boxShadow: "2px 2px 10px #888888",
        minWidth: "200px",
        height: "100px",
      }}
    >
      <span
        style={{
          fontSize: "1.2rem",
          fontWeight: "bolder",
          display: "inline-block",
          padding: "1rem",
        }}
      >
        {title}
      </span>
      <hr style={{ height: "2px", width: "50%", marginBottom: "0" }} />
      <span style={{ fontSize: "2.0rem", fontWeight: "bolder" }}>{value}</span>
    </div>
  );
};

export default Card;
