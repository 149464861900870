import React from "react";
import { Table } from "antd";

const _Table = ({ data, columns, loading, paginationConfig, ...props }) => {

  columns = columns.map(({ sorter = false, ...col }) => ({
    ...col,
    sorter: sorter
      ? (a, b) => a[col.dataIndex].length - b[col.dataIndex].length
      : false,
  }));
  return (
    <div>
      <Table
        loading={loading}
        showSorterTooltip={false}
        columns={columns}
        dataSource={data}
        pagination={{
          pageSizeOptions: ["10", "20", "50", "100", "500"],
          showSizeChanger: true,
          position: ["bottomCenter"],
          ...paginationConfig
        }}
        bordered
        scroll={{ x: 1000, y: 500 }}
        {...props}
      />
    </div>
  );
};

export default _Table;
