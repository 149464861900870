import {client, localStorageKey,authorStorageKey} from './api-client'

function handleUserResponse({authToken, ...user}) {
  window.localStorage.setItem(localStorageKey, authToken)
  window.localStorage.setItem(authorStorageKey, user?.user?.roleData[0])
  return user.user
}

function getUser() {
  const token = getToken();
  const roleVal = getROLE();
  if (!token) {
    return Promise.resolve(null)
  }
  return client(`me/${roleVal}`).then(data => data.user)
}

function login({email, password, rememberMe,roles}) {
  return client('login', {body: {email, password, rememberMe,roles}}).then(handleUserResponse)
}

 const getToken=()=> {
  return window.localStorage.getItem(localStorageKey)
}

export const getROLE=()=> {
  return window.localStorage.getItem(authorStorageKey)
}

function isLoggedIn() {
  return Boolean(getToken())
}

export {login, getToken, getUser, isLoggedIn}
export {logout} from './api-client'
