import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  message,
  Button,
  Select,
  Input,
  DatePicker,
  Checkbox,
  TimePicker,
  Space,
  InputNumber,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import { Spinner } from "../../lib";

const EditList = ({ modal, setModal, records, fetchData }) => {
  const { isLoading, run, isError, error, data } = useAsync();
  const [userForm] = Form.useForm();
  const [disableVal, setDisable] = useState(true);
  const [totalTime, setTotalTime] = useState(""); //added by ayush

  const addTask = async (val) => {
    let set = {};
    const descriptionArray = val.descriptions;
    const titleArray = val.titles;
    const hoursArray = val.hours;
    const minutesArray = val.minutes;

    const effectiveDate = val.effectiveDate;
    const managerRemark = val.managerRemark;
    let taskList = [];

    for (
      let i = 0;
      i <
      Math.min(
        descriptionArray.length,
        titleArray.length,
        hoursArray.length,
        minutesArray.length
      );
      i++
    ) {
      let timeSpent = {};
      timeSpent["hours"] = hoursArray[i];
      timeSpent["minutes"] = minutesArray[i];
      let task = {
        title: titleArray[i],
        description: descriptionArray[i],
        timeSpent: timeSpent,
      };
      taskList.push(task);
    }

    set["effectiveDate"] = effectiveDate;
    set["managerRemark"] = managerRemark; //chnages by ayush
    set["taskList"] = taskList;
    set["_id"] = records?._id;
    await run(
      client("qualitative/task/edit", { body: set, method: "PUT" })
    ).then((res) => {
      if (res.message === "Effectice Date Already Exist") {
        message.error(res.message);
      } else {
        message.success(res.message);
      }
      setModal(false);
    });
    userForm.resetFields();

    fetchData();
    setDisable(true);
  };

  const handleChange = (e) => {
    setDisable(false);
  };
  //added by ayush
  const handleTimeChange = (changedValues, allValues) => {
    const parsedTasks = allValues.titles.map((title, index) => {
      const hours = parseInt(allValues.hours[index]) || 0;
      const minutes = parseInt(allValues.minutes[index]) || 0;
      return { title, hours, minutes };
    });

    const totalHours = parsedTasks.reduce((acc, task) => acc + task.hours, 0);
    const totalMinutes = parsedTasks.reduce(
      (acc, task) => acc + task.minutes,
      0
    );

    const hours = Math.floor(totalHours + totalMinutes / 60);
    const minutes = totalMinutes % 60;

    setTotalTime(
      `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`
    );
  };
  return (
    <Modal
      title="Edit Qualitative Project"
      visible={modal}
      onCancel={() => {
        userForm.resetFields();
        setModal(false);
        setDisable(true);
      }}
    >
      <Form
        onValuesChange={handleTimeChange} //added by ayush
        form={userForm}
        size="middle"
        layout="horizontal"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={addTask}
      >
        <Form.Item
       
          label="Effective Date"
          name="effectiveDate"
          value="effectiveDate"
          rules={[{ required: true, message: "Please enter a effective date" }]}
          initialValue={records?.effectiveDate?.slice(0, 10)}
        >
          <Input
          disabled
            name="effectiveDate"
            type="date"
            onChange={(e) => handleChange(e.target.value)}
          />
        </Form.Item>

        {records?.taskList?.map((item, index) => (
          <Space
            key={item._id}
            style={{ display: "grid", marginBottom: 8 }}
            align="baseline"
          >
            <Form.Item
              label={`Title${index + 1}`}
              name={["titles", index]}
              rules={[{ required: true, message: "Please enter a title" }]}
              initialValue={item.title}
            >
              <Input onChange={(e) => handleChange(e.target.value)} />
            </Form.Item>

            <Form.Item
              label={`Description${index + 1}`}
              name={["descriptions", index]}
              rules={[
                { required: true, message: "Please enter a description" },
              ]}
              initialValue={item.description}
            >
              <Input.TextArea onChange={(e) => handleChange(e.target.value)} />
            </Form.Item>
            {/* added rules in hours and in minutes */}
            <Form.Item
              label={`Hours${index + 1}`}
              name={["hours", index]}
              rules={[
                {
                  required: true,
                  message: "Please enter Hours",
                },
                {
                  validator: (_, value) => {
                    if (!isNaN(value) && value >= 0 && value <= 23) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "Please enter a valid hour between 0 and 23"
                    );
                  },
                },
                {
                  validator: (_, value) => {
                    if (!isNaN(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Please enter a numeric value");
                  },
                },
              ]}
              initialValue={item.timeSpent.hours}
            >
              <Input onChange={(e) => handleChange(e.target.value)} />
            </Form.Item>
            <Form.Item
              label={`Miniutes${index + 1}`}
              name={["minutes", index]}
              rules={[
                {
                  required: true,
                  message: "Please enter minutes",
                },
                {
                  validator: (_, value) => {
                    if (!isNaN(value) && value >= 0 && value <= 59) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "Please enter a valid minutes between 0 and 59"
                    );
                  },
                },
                {
                  validator: (_, value) => {
                    if (!isNaN(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Please enter a numeric value");
                  },
                },
              ]}
              initialValue={item.timeSpent.minutes}
            >
              <Input onChange={(e) => handleChange(e.target.value)} />
            </Form.Item>
          </Space>
        ))}

        <Form.Item
          label={`Remarks`}
          name="managerRemark"
          rules={[{ required: true, message: "Please enter  Remarks" }]}
        >
          <Input.TextArea onChange={(e) => handleChange(e.target.value)} />
        </Form.Item>

        {/* //line 125 to 135 chnages by ayush */}
        {/* added by ayush */}
        <p style={{ marginTop: "10px  ", marginLeft: "30%" }}>
          Total Time: {totalTime}
        </p>
        <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }}>
          <Button type="primary" htmlType="submit" disabled={disableVal}>
            Update and Approve{" "}
            {isLoading && (
              <span style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                <Spinner />
              </span>
            )}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditList;
