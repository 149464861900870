import React, { useState, useEffect } from "react";
import "./manage.css";
import Table from "../../table";
import { useAuth } from "../../../context/auth-context";
import { useAsync } from "../../../utils/use-async";
import exportFromJSON from 'export-from-json'
import { DownloadOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Select,
  message,
} from "antd";
import { client } from "../../../utils/api-client";
import moment from "moment";
import { useHistory } from "react-router-dom"
import DepartmentReportCount from "../user-report-count";

const roleOption = [
  {
    label: 'AUTHOR',
    value: 'AUTHOR'
  },
  {
    label: 'REVIEWER',
    value: 'REVIEWER'
  },
  {
    label: 'REVIEWER-2',
    value: 'REVIEWER-2'
  },
  {
    label: 'UPLOADER',
    value: 'UPLOADER'
  },
]

const UserReport = ({ type }) => {
  const { run, isLoading, data, isError, error } = useAsync();
  const { user } = useAuth();
  const [departments, setDepartment] = useState([]);
  const [countData, setCountData] = useState([]);
  const [dep, setDep] = useState('');
  const [users, setUsers] = useState([]);
  const [roleData, setRole] = useState('');
  const [dateType, setDateType] = useState('');
  const [fromDate, setFrom] = useState(new Date());
  const [toDate, setTo] = useState(new Date());
  const [rec, setRec] = useState([]);
  const [managers, setManagers] = useState([]);
  const [manager, setManager] = useState('');
  const [project, setProject] = useState('');
  const [allProject, setProjectsData] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [columns, setColumns] = useState([]);
  const [form] = Form.useForm();
  const router = useHistory();

  const submitData = async (val) => {
    if (user.roleData.includes('MANAGER') && type === "team") {
      const payload = { ...val, manager: user._id };
      await run(client("projects/records/report", { body: { ...payload, type }, method: 'POST' }));
    }
    else if (type === "user") {
      await run(client(`projects/records/reports/data`, { body: { ...val, type }, method: 'POST' }));
    }
    else {
      await run(client("projects/records/report", { body: { ...val, type }, method: 'POST' }));
    }
  }

  useEffect(() => {

    if (data?.message === "Fetched departments") {
      setDepartment(data?.departments)
    }
    else if (data?.message === "Fetched Report Users") {
      setUsers(data?.users);
    }
    else if (data?.message === "Fetched Report Projects") {
      setRec(data?.records);
      setProjectsData(data?.projectData);
      setCols(data?.records);
      setCountData(data?.countReport)
    }
    else if (data?.message === "Fetched managers") {
      setManagers(data.data);
    }
  }, [data]);

  const fetchDepartments = async () => {
    await run(client("department/all/report"));
  };

  const fetchManagers = async () => await run(client("admin/managers"));

  const fetchUsers = async (role, department) => {
    if (type === "user") {
      await run(client(`user/details/${department}/${role}`));
    }
  };

  useEffect(() => {
    fetchDepartments();
    if (type === "team") {
      fetchManagers();
    }
  }, [])

  useEffect(() => {
    if (isError) {
      message.error(error.message);
    }
  }, [error]);

  const setDepChange = (e) => {
    setDep(e)
    if (roleData !== "") {
      fetchUsers(roleData, e)
    }
  }

  const setManagerChange = (e) => {
    setManager(e)
  }

  const projectChange = (e) => {
    setProject(e);
  }

  const fromDateChange = (e) => {
    setFrom(e.target.value)
  }

  const toDateChange = (e) => {
    setTo(e.target.value)
  }

  const setRoleChange = (e) => {
    setRole(e);
    if (dep !== "") {
      fetchUsers(e, dep);
    }
  }

  const changeDateType = (e) => {
    setDateType(e);
  }

  const setCols = (rows) => {
    let cols = rows[0]
      ? Object.keys(rows[0])
        .filter((_) => !!_)
        .map((col) => {
          if (col === "sr_no") {
            return {
              title: col,
              key: col,
              dataIndex: col,
              width: 70,
              fixed: true,
            };
          }
          if (["link", "question_link", "solution_link"].includes(col)) {
            return {
              title: col,
              key: "x",
              width: 150,
              ellipsis: true,
              render: (record) => (
                <a target="_blank" href={record[col]}>
                  {record[col]}
                </a>
              ),
            };
          } else if (col.endsWith("date")) {
            return {
              title: col,
              key: col,
              width: 190,
              render: (record) => (
                <div
                  style={{ wordWrap: "break-word", wordBreak: "break-word" }}
                >
                  {record[col]
                    ? moment(record[col]).format("DD/MM/YYYY hh:mm a")
                    : ""}
                </div>
              ),
            };
          } else if (col.endsWith("status")) {
            return {
              title: col,
              dataIndex: col,
              key: col,
              width: 140,
            };
          } else if (
            [
              "chapter",
              "section",
              "page",
              "q_number",
              "q_part",
              "q_type",
            ].includes(col)
          ) {
            return {
              title: col,
              dataIndex: col,
              key: col,
              width: 100,
            };
          } else {
            return {
              title: col,
              dataIndex: col,
              key: col,
              width: 180,
            };
          }
        })
      : [];

    setColumns(cols);
  };

  const handlePrint = () => {
    const fileName = type.charAt(0).toUpperCase() + type.slice(1) + "_Report"
    const exportType = "xls"
    exportFromJSON({ data: rec, fileName, exportType })
  }

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "grid",
          alignItems: "center",
        }}
      >
        <div style={{ marginBottom: 0, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <h1 >{type.charAt(0).toUpperCase() + type.slice(1)} Report</h1>
          <div >
            {
              rec.length !== 0 && <>
                <Button icon={<DownloadOutlined />} onClick={() => handlePrint()}>Data Download</Button>
                {
                  type === "department" &&
                  <Button onClick={() =>setModal(true)} style={{ marginLeft: '0.5rem' }}>Count View</Button>
                }
              </>
            }
          </div>
        </div>
        {/* <div style={{textAlign:'right'}}>
          <Button>Count View</Button>
        </div> */}
        <div style={{ marginLeft: '10px', width: '100%' }}>
          <Form style={{ display: 'grid', marginTop: '2rem' }} onFinish={submitData}>

            <div style={{ display: 'flex', width: 'auto', gap: '3rem' }}>
              {
                type === "team" && user.roleData[0] === "ADMIN" &&
                <Form.Item label="Select Managers" name="manager" style={{ width: '20%' }}>
                  <Select
                    placeholder="Managers"
                    onChange={(e) => setManagerChange(e)}
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {
                      managers.map((e) =>
                      (
                        <Select.Option key={e._id} value={e._id}>{e.fullName}</Select.Option>
                      )
                      )
                    }
                  </Select>
                </Form.Item>
              }
              {
                (type === "department" || type === "user") &&
                <Form.Item label="Select Department" name="dep" style={{ width: '20%' }}>
                  <Select
                    placeholder="Department"
                    onChange={(e) => setDepChange(e)}
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {
                      departments.map((e) =>
                      (
                        <Select.Option key={e._id} value={e._id}>{e.name}</Select.Option>
                      )
                      )
                    }
                  </Select>
                </Form.Item>
              }
              <Form.Item label="Select Role" name="roleData" style={{ width: '15%' }}>
                <Select
                  placeholder="Role"
                  onChange={setRoleChange}
                  showSearch={true}
                  mode={type === "user" ? "multiple" : ""}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    roleOption.map((e) =>
                    (
                      <Select.Option key={e.value} value={e.value}>{e.value}</Select.Option>
                    )
                    )
                  }
                </Select>
              </Form.Item>
              {
                type === "user" &&
                <Form.Item label="Select User" name="user" style={{ width: '20%' }}>
                  <Select
                    placeholder="Users"
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {
                      users.map((e) =>
                      (
                        <Select.Option key={e._id} value={e.email}>{e.email}</Select.Option>
                      )
                      )
                    }
                  </Select>
                </Form.Item>
              }
            </div>

            <div style={{ display: 'flex', gap: '3rem', width: 'auto' }}>
              {
                type === "user" &&
                <Form.Item label="Select Date Type" name="dateType" style={{ width: '20%' }}>
                  <Select
                    placeholder="Date Type"
                    onChange={changeDateType}
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {
                      roleData.length === 1 && roleData[0] === "AUTHOR" && <>
                        <Select.Option value={"done_date"}>Done Date</Select.Option>
                        <Select.Option value={"skipped_date"}>Skipped Date</Select.Option>
                        <Select.Option value={"rework_done_date"}>Rework Date</Select.Option>
                      </>
                    }
                    {
                      roleData.length === 1 && (roleData[0] === "REVIEWER" || roleData[0] === "REVIEWER-2") && <>
                        <Select.Option value={"done_date"}>Done Date</Select.Option>
                        <Select.Option value={"sent_for_rework_date"}>Sent For Rework Date</Select.Option>
                      </>
                    }

                    {roleData.length === 1 && roleData[0] === "UPLOADER" && <>
                      <Select.Option value={"done_date"}>Done Date</Select.Option>
                      <Select.Option value={"skipped_date"}>Skipped Date</Select.Option>
                    </>
                    }
                    {roleData.length > 1 && <>
                      <Select.Option value={"done_date"}>Done Date</Select.Option>
                    </>
                    }
                  </Select>
                </Form.Item>
              }

              {
                type !== "user" &&
                <Form.Item label="Select Date Type" name="dateType" style={{ width: '20%' }}>
                  <Select
                    placeholder="Date Type"
                    onChange={changeDateType}
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {
                      roleData === "AUTHOR" && <>
                        <Select.Option value={"done_date"}>Done Date</Select.Option>
                        <Select.Option value={"skipped_date"}>Skipped Date</Select.Option>
                        <Select.Option value={"rework_done_date"}>Rework Date</Select.Option>
                      </>
                    }
                    {
                      (roleData === "REVIEWER" || roleData === "REVIEWER-2") && <>
                        <Select.Option value={"done_date"}>Done Date</Select.Option>
                        <Select.Option value={"sent_for_rework_date"}>Sent For Rework Date</Select.Option>
                      </>
                    }

                    {roleData === "UPLOADER" && <>
                      <Select.Option value={"done_date"}>Done Date</Select.Option>
                      <Select.Option value={"skipped_date"}>Skipped Date</Select.Option>
                    </>
                    }
                  </Select>
                </Form.Item>
              }

              <Form.Item
                label="From"
                name="fromDate"
                onChange={fromDateChange}
              >
                <Input type="date" />
              </Form.Item>
              <Form.Item
                label="To"
                name="toDate"
                onChange={toDateChange}
              >
                <Input type="date" />
              </Form.Item>

              <Form.Item>
                <Button type="link" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </div>

            <hr style={{ border: 'none', height: '1px', backgroundColor: '#333', margin: '20px 0' }} />
            <div style={{ display: 'flex' }}>

              <Form.Item label="Select Projects" name="projects" style={{ width: '20%', marginLeft: '2rem' }}>
                <Select
                  placeholder="Projects"
                  showSearch={true}
                  mode="multiple"
                  onChange={projectChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    allProject.map((e, id) =>
                    (
                      <Select.Option key={e} value={e}>{e}</Select.Option>
                    )
                    )
                  }
                </Select>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
      <Table
        loading={usersLoading}
        data={rec}
        columns={columns}
        size="middle"
      />
      {
        type === "department" &&
         <DepartmentReportCount
         modal={modal}
         setModal={setModal}
         allCount = {countData}
         />
      }
    </div>
  );
};

export default UserReport;
