import React, { useState, useEffect } from "react";
import Table from "../../table";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import { Button, Modal, Form, Input } from "antd";
import { Spinner } from "../../lib";

const DeclinedSheets = () => {
  const { run, data } = useAsync();

  let [timesheet, setTimesheet] = useState([]);
  let [timesheetLoading, setTimesheetLoading] = useState(false);
  const [currentTimesheet, setCurrentTimesheet] = useState(null);
  const [showResubmit, setShowResubmit] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [maxProjects, setMaxProjects] = useState(1);

  useEffect(() => {
    if (data?.code === "TIMESHEET_FETCH_SUCCESS") {
      const timesheet = data.data;
      setTimesheet(timesheet);
      setMaxProjects(data.maxProjects);
      setTimesheetLoading(false);
    }

    if (data?.code === "TIMESHEET_UPDATE_SUCCESS") {
      setUpdateLoading(false);
      setShowResubmit(false);
      fetchTimesheet();
    }
  }, [data]);

  const columns = () => {
    const projectKeys = Array.from(Array(maxProjects)).map((p, idx) => {
      const name = `project${idx + 1}-name`;
      const done = `project${idx + 1}-done`;
      return [
        { title: name, dataIndex: name, key: name },
        { title: done, dataIndex: done, key: done },
      ];
    });

    return [
      {
        title: "Sr. No",
        dataIndex: "sr",
        key: "sr",
        ellipsis: true,
        fixed: "left",
      },
      {
        title: "Date",
        key: "date",
        render: (record) => (
          <span>{new Date(record.date).toLocaleDateString()}</span>
        ),
        fixed: "left",
      },
      ...projectKeys.flat(),
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
      },
      {
        title: "Your remarks",
        key: "userRemarks",
        dataIndex: "userRemarks",
      },
      {
        title: "Approver remarks",
        key: "approverRemarks",
        dataIndex: "approverRemarks",
      },
      {
        title: "Actions",
        key: "x",
        dataIndex: "",
        render: (record) => {
          return (
            <Button
              disabled={record.status !== "REJECTED"}
              onClick={() => {
                setShowResubmit(true);
                setCurrentTimesheet(record._id);
              }}
            >
              Resubmit
            </Button>
          );
        },
      },
    ];
  };

  const transformTableData = (data) =>
    data.map(({ _id, timesheets, ...rest }, index) => ({
      key: index,
      sr: index + 1,
      ...timesheets,
      _id,
      ...rest,
    }));

  const fetchTimesheet = () => {
    run(client("timesheet/user?status=FILLED,REJECTED,APPROVED"));
    setTimesheetLoading(true);
  };

  const updateTimesheet = (updates = {}) => {
    run(
      client("timesheet/user", {
        method: "PATCH",
        body: updates,
      })
    );
  };

  useEffect(() => {
    fetchTimesheet();
  }, []);

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h1>Your timesheets</h1>
        </div>
      </div>
      <Table
        loading={timesheetLoading}
        data={transformTableData(timesheet)}
        columns={columns()}
        size="middle"
      />
      <Modal
        title="Resubmit timesheet"
        visible={showResubmit}
        onCancel={() => setShowResubmit(!showResubmit)}
      >
        <Form
          size="middle"
          layout="horizontal"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          onFinish={(values) => {
            updateTimesheet({
              ...values,
              timesheetId: currentTimesheet,
            });
            setUpdateLoading(true);
          }}
        >
          <Form.Item label="Your remarks" name="userRemarks">
            <Input.TextArea />
          </Form.Item>
          <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }}>
            <Button type="primary" htmlType="submit">
              Resubmit{" "}
              {updateLoading && (
                <span style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                  <Spinner />
                </span>
              )}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default DeclinedSheets;
