import React, { useState, useEffect } from "react";
import "./allocate.css";
import Table from "../../../table";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Button, Popconfirm, message, Space } from "antd";
import AddProject from "../add-project";
import { Spinner } from "../../../lib";
import { useHistory } from "react-router-dom"
import AddDepartment from "../add-department";
import { useParams } from "react-router-dom";
import exportFromJSON from "export-from-json";
import { DownloadOutlined } from '@ant-design/icons';

const DepartmnentAllocations = () => {
  const {
    run,
    isLoading,
    data
  } = useAsync();
  const {projectId} = useParams();
  let [all, setAll] = useState([]);
  let [project, setProject] = useState('')
  let [modal, setModal] = useState(false);
  let [currentAllocation, setCurrentAllocation] = useState([]);
  let [historyAllocation, setHistoryAllocation] = useState([]);
  let [usersLoading, setUsersLoading] = useState(false);
  const history = useHistory()

  let [toDelete, setToDelete] = useState("");

  useEffect(() => {
    if (data?.message === "Deleted Department") {
      message.success(data?.message);
      setToDelete("");
      fetchSections();
    }

    if (data?.message === "Fetched Departments") {
      setAll(data?.departmentData?.departments);
      setProject(data?.departmentData?.project_name)
      setUsersLoading(false);
    }
    if (data?.message === "Fetched Current Allocation") {
      setCurrentAllocation(data?.allocationDetails);
      setHistoryAllocation(data?.historyDetails);
    }
  }, [data]);

  const handleDelete = (name) => {
    setToDelete(name);
    run(client(`department/delete/${projectId}`, { method: "DELETE", body: {name}  }));
  };

  const allocationData = async()=> {
   await run(client(`allocation/current/all/${projectId}`));
  }

  console.log(currentAllocation)

  const handlePrint = () => {
    const fileName = "Current Allocation Report"
    const exportType = "xls"
    exportFromJSON({ data: currentAllocation, fileName, exportType })
  }

  const handlePrintHistory = () => {
    const fileName = "History Allocation Report"
    const exportType = "xls"
    exportFromJSON({ data: historyAllocation, fileName, exportType })
  }

  const columns = [
    {
      title: "Department Name",
      dataIndex: "department_name",
      key: "department_name",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Actions",
      render: (record) => (
        <>
          <Space>
          <Button type="primary" onClick={() => history.push(`/projectAllocation/${projectId}/${record._id}`)}>Show Details</Button>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record?.department_name)}
          >
          <Button type="danger">
            Delete {isLoading && toDelete === record.name ? <Spinner /> : ""}
          </Button>
          </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

//   const transformTableData = (data) => {
//     return data.map(({departments: { department_name }, ...department}, index) => ({ key: index, ...department, department_name, sr: index + 1 }));
//   };

  const fetchSections = () => {
    run(client(`allocation/departments/all/${projectId}`));
    setUsersLoading(true);
  };

  useEffect(() => {
    fetchSections();
    allocationData();
  }, []);

  const handleCancel = (e) => {
    setModal(false);
  };

  const showModal = () => setModal(true);

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>Add Department for {project}</h1>
        <div>
        <Button type="ghost" onClick={showModal}>
          Add Department
        </Button>
        <Button type="ghost"  icon={<DownloadOutlined />} onClick={handlePrint} style={{marginLeft:'0.3rem'}}>
          Download Current Allocation
        </Button>
        <Button type="ghost"  icon={<DownloadOutlined />} onClick={handlePrintHistory} style={{marginLeft:'0.3rem'}}>
          Download History Allocation
        </Button>
        </div>
      </div>
      <Table
        loading={usersLoading}
        columns={columns}
        data={all}
        size="middle"
      />
      <AddDepartment
        modal={modal}
        handleCancel={handleCancel}
        fetchSections={fetchSections}
        setModal={setModal}
        project = {projectId}
      />
    </div>
  );
};

export default DepartmnentAllocations;
