import React, { useState, useEffect } from "react";
import Table from "../../table";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import {
  Button,
  Collapse,
  Tabs,
  Popover,
  Form,
  Input,
  InputNumber,
  Radio,
  Space,
} from "antd";
import { EditOutlined } from "@ant-design/icons";

const { Panel } = Collapse;
const { TabPane } = Tabs;

const UserWorklog = () => {
  const { run, data } = useAsync();

  const [targets, setTargets] = useState([]);
  const [currentTarget, setCurrentTarget] = useState(0);
  const [targetsLoading, setTargetLoading] = useState(false);

  useEffect(() => {
    if (data?.message === "Fetched targets") {
      setTargets(data.targets);
      setCurrentTarget(0);
      setTargetLoading(false);
    }

    if (data?.message === "Updated target") {
      setCurrentTarget(null);
      fetchTarget();
    }
  }, [data]);

  const onWorklogUpdate = (values, record) => {
    run(
      client(`targets/user/${currentTarget.targetId}`, {
        body: { ...values, subTargetId: currentTarget._id },
        method: "PATCH",
      })
    );
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      ellipsis: true,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Target",
      dataIndex: "",
      key: "target",
      render: (record) => (
        <span>
          {record.target} {record.type}
        </span>
      ),
    },
    {
      title: "Completed",
      key: "completed",
      render: (record) => (
        <span>
          {record.type === "units"
            ? `${record.completedUnits} units`
            : record.type === "hours"
            ? `${record.completedHours} hours`
            : `${record.completedUnits} units ${record.completedHours} hours`}
        </span>
      ),
    },
    {
      title: "Incentive",
      dataIndex: "incentive",
      key: "incentive",
      render: (incentive) => (
        <span
          style={{
            color: incentive < 0 ? "red" : incentive > 0 ? "green" : "inherit",
          }}
        >
          {incentive}
        </span>
      ),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
  ];

  const absenceForm = (record) => (
    <div>
      <Form
        style={{ width: "235px" }}
        labelCol={{ span: 8 }}
        layout="horizontal"
        size="small"
        onFinish={(vals) => onWorklogUpdate(vals, record)}
        initialValues={{
          reason: record?.off.reason,
        }}
      >
        <Form.Item name="reason" label="reason" rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value="SL">SL</Radio>
            <Radio value="PL">PL</Radio>
            <Radio value="LOP">LOP</Radio>
          </Radio.Group>
        </Form.Item>
        <span style={{ color: "red" }}>
          {new Date().getDay() !== (0 || 6)
            ? "It's not a weekend, do you still opt for weekly off ?"
            : null}
        </span>
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );

  const content = (record) => (
    <div>
      <Form
        style={{ width: "235px" }}
        labelCol={{ span: 13 }}
        layout="horizontal"
        size="small"
        onFinish={(vals) => onWorklogUpdate(vals, record)}
        initialValues={{
          completedUnits: record.completedUnits,
          completedHours: record.completedHours,
        }}
      >
        <Form.Item
          label="Completed units"
          name="completedUnits"
          rules={[{ type: "number", min: 0, max: 999, required: true }]}
        >
          <InputNumber
            style={{ width: "70px" }}
            disabled={record.type === "hours"}
          />
        </Form.Item>
        <Form.Item
          label="Completed hours"
          name="completedHours"
          rules={[{ type: "number", min: 0, max: 999, required: true }]}
        >
          <InputNumber
            style={{ width: "70px" }}
            disabled={record.type === "units"}
          />
        </Form.Item>
        <Form.Item label="Remarks" name="remarks">
          <span>
            <Input.TextArea style={{ height: "150px" }} />
          </span>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );

  const editColumn = {
    title: "Update",
    dataIndex: "",
    key: "update",
    render: (record) => (
      <Space>
        <Popover
          content={() => content(record)}
          title="Update worklog"
          trigger="click"
        >
          <Button type="primary" onClick={() => setCurrentTarget(record)}>
            <EditOutlined />
          </Button>
        </Popover>

        <Popover
          content={() => absenceForm(record)}
          title="Mark absence"
          trigger="click"
        >
          <Button type="dashed" onClick={() => setCurrentTarget(record)}>
            Mark absence
          </Button>
        </Popover>
      </Space>
    ),
  };

  const transformTableData = (data, targetId) =>
    data.map(({ date, ...targets }, index) => ({
      key: index,
      date: new Date(date).toLocaleDateString(),
      sr: index + 1,
      targetId,
      ...targets,
    }));

  const fetchTarget = () => {
    run(client(`targets/user`));
    setTargetLoading(true);
  };

  useEffect(() => {
    fetchTarget();
  }, []);

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1>Your worklog</h1>
      </div>
      <Collapse defaultActiveKey={["target-0"]} bordered={false}>
        {targets.map((target, idx) => (
          <Panel header={target.name} key={`target-${idx}`}>
            <Tabs defaultActiveKey="1">
              <TabPane
                tab="Ongoing"
                disabled={!target.targets.ongoing.length}
                key="1"
              >
                <Table
                  loading={targetsLoading}
                  data={transformTableData(target.targets.ongoing, target._id)}
                  columns={[...columns, editColumn]}
                  size="middle"
                />
              </TabPane>
              <TabPane
                tab="Upcoming"
                disabled={!target.targets.upcoming.length}
                key="2"
              >
                <Table
                  loading={targetsLoading}
                  data={transformTableData(target.targets.upcoming, target._id)}
                  columns={columns}
                  size="middle"
                />
              </TabPane>
              <TabPane
                tab="Lapsed"
                disabled={!target.targets.completed.length}
                key="3"
              >
                <Table
                  loading={targetsLoading}
                  data={transformTableData(
                    target.targets.completed,
                    target._id
                  )}
                  columns={columns}
                  size="middle"
                />
              </TabPane>
            </Tabs>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default UserWorklog;
