import React, { useState, useEffect, useCallback } from "react";
import "./manage.css";
import Table from "../../table";
import { useAuth } from "../../../context/auth-context";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import { DownloadOutlined } from '@ant-design/icons';
import exportFromJSON from 'export-from-json'
import {
  Button,
  Form,
  Input,
  Select,
  Popconfirm,
  message,
  Modal,
  InputNumber,
  Radio,
  DatePicker,
} from "antd";
import AddUser from "./add-user";
import { removeFalsyObj } from "../../../utils";
import { Spinner } from "../../lib";
import { Link } from "react-router-dom";
import moment from "moment";
import AssignProject from "../timesheet/manage-sheet/assign-project";

const WORKING_DAYS = 20;
const WORKING_HOURS_PER_DAY = 8;

const Managers = ({ type }) => {
  const { run, isLoading, data, isError, error } = useAsync();
  const { user } = useAuth();
  const [users, setUsers] = useState([]);
  const [modal, setModal] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);
  const [toBlock, setToBlock] = useState("");
  const [toUnblock, setToUnblock] = useState("");
  const [dptmts, setDptmts] = useState([]);
  const [selectedDptmts, setSelectedDptmts] = useState([]);
  const [toDelete, setToDelete] = useState("");
  const [changePwd, setChangePwd] = useState(false);
  const [changeSalary, setChangeSalary] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [inpSalary, setInpSalary] = useState(0);
  const [managers, setManagers] = useState([]);
  const [toggleAddProject, setToggleAddProject] = useState(false);
  const [employee_id, setEmpId] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [workingHours, setHours] = useState(null)
  const [effective, setEffective] = useState(new Date())
  const [currentRole, setRole] = useState('');

  const [form] = Form.useForm();
  const fetchUsers = useCallback((query) => {
    run(client(`admin/users${query ? query : ""}`));
    setUsersLoading(true);
  }, [run]);

  const fetchDepartments = (query) => {
    run(client("department/all"));
  };

  const fetchManagers = () => run(client("admin/managers"));

  const updatePwd = async (val) => {

    if (val.employee_id == "") {
      delete val.employee_id;
    }
    const payload = { email: currentUser.email, workingHours: workingHours, ...val };

    const { manager } = payload;

    if (manager && manager.includes("@")) {
      payload.manager = managers.find((_) => _.email === manager)?._id;
    }

    if (payload.newPassword === "") {
      delete payload.newPassword;
      await run(client("user/update-profile", { body: payload }));
    }
    else {
      run(client("user/update-profile", { body: payload }));
    }
  };

  const updateSalary = (val) => {
    if (val.effectiveDate) {
      val.effectiveDate = moment(val.effectiveDate)
        .utc()
        .startOf("day")
        .toString();
    }

    run(client(`user/salary/${currentUser._id}`, { body: val, method: "PUT" }));
    form.resetFields();
  };

  const handleBlock = (e) => {
    setToBlock(e._id);
    run(client(`user/block/${e._id}`, { method: "PUT" }));
  };

  const handleDelete = (e) => {
    setToDelete(e.email);
    run(client(`user/delete/${e.email}`, { method: "DELETE" }));
  };

  const handleUnblock = (e) => {
    setToUnblock(e._id);
    run(client(`user/unblock/${e._id}`, { method: "PUT" }));
  };

  useEffect(() => {
    if (data?.message === "User blocked") {
      message.success(data?.message);
      setToBlock("");
      fetchUsers();
    } else if (data?.message === "User deleted") {
      message.success(data?.message);
      setToDelete("");
      fetchUsers();
    } else if (data?.message === "User unblocked") {
      message.success(data?.message);
      setToUnblock("");
      fetchUsers();
    } else if (data?.message === "Fetched users") {
      setUsers(transformTableData(data.users));
      setUsersLoading(false);
    } else if (data?.message === "Salary updated") {
      message.success(data.message);
      setChangeSalary(false);
      setInpSalary(0);
      fetchUsers();
    } else if (data?.message === "Profile updated successfully") {
      message.success(data.message);
      form.resetFields()
      setChangePwd(false);
      fetchUsers();
    } else if (data?.message === "Fetched departments") {
      setDptmts(data.departments);
    } else if (data?.message === "Fetched managers") {
      setManagers(data.data);
    }
  }, [data, fetchUsers, form]);

  useEffect(() => {
    form.setFieldsValue({
      remember: false,
      enableAssignments:
        currentUser.enableAssignments === undefined
          ? true
          : currentUser.enableAssignments,
      manager: currentUser.manager,
    });
  }, [form, currentUser]);

  useEffect(() => {
    if (isError) {
      message.error(error.message);
    }
  }, [error]);

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      width: 100,
      ellipsis: true,
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "name",
      width: 200,
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Role",
      dataIndex: "roleData",
      key: "roleData",
      width: 300,
      ellipsis: true,
      render: (record) =>
        record.length === 1 ? record[0] : record.join(',')
    },
    {
      title: "Shift Type",
      dataIndex: "shiftType",
      key: "shiftType",
      width: 300,
      ellipsis: true,
      render: (record) =>
        record ? record + " Days" : ""
    },
    {
      title: "Departments",
      dataIndex: "departments",
      key: "departments",
      width: 200,
    },
    {
      title: "Manager",
      dataIndex: "manager",
      key: "manager",
      width: 200,
    },
    {
      title: "Salary",
      key: "salary",
      width: 200,
      render: (record) =>
        record.newSalary?.amount ? record.newSalary.amount : record.salary,
    },
    {
      title: "Employee Id",
      key: "employee_id",
      width: 200,
      render: (record) =>
        record.employee_id,
    },
    {
      title: "Action",
      key: "x",
      dataIndex: "",
      width: 400,
      render: (record) => (
        <>
          <Link to={`/users/${record._id}`}>
            <Button
              type="link"
              disabled={record.roleData[0] === "MANAGER" ? true : false}
            >
              View summary
            </Button>
          </Link>
          {record.accountState === "blocked" ? (
            <Popconfirm
              title="Sure to Unblock?"
              onConfirm={() => handleUnblock(record)}
            >
              <Button type="link">
                Unblock{" "}
                {isLoading && toUnblock === record._id ? <Spinner /> : ""}
              </Button>
            </Popconfirm>
          ) : (
            <Popconfirm
              title="Sure to block?"
              onConfirm={() => handleBlock(record)}
            >
              <Button type="link">
                Block {isLoading && toBlock === record._id ? <Spinner /> : ""}
              </Button>
            </Popconfirm>
          )}
          <Button type="link" onClick={() => handleChangePwd(record)}>
            Update details
          </Button>
          <Button type="link" onClick={() => handleSalary(record)}>
            Update salary
          </Button>
          {record.roleData[0] !== "MANAGER" && record.roleData[0] !== "ADMIN" ? (
            <Button
              type="link"
              onClick={() => {
                setCurrentUser(record);
                setToggleAddProject(!toggleAddProject);
              }}
            >
              Assign timesheet projects
            </Button>
          ) : null}
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record)}
          >
            <Button type="link">
              Delete {isLoading && toDelete === record.email ? <Spinner /> : ""}
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const transformTableData = (data) =>
    data.map((user, index) => {
      let { departments, manager, ...rest } = user;
      departments = departments
        ? departments.map((dpmt) => dpmt.name).join(", ")
        : "";

      return {
        key: index,
        manager: manager?.email,
        departments: departments,
        ...rest,
        sr: index + 1,
      };
    });

  useEffect(() => {
    fetchUsers();
    fetchDepartments();
    fetchManagers();
  }, []);

  const handleChangePwd = (record) => {
    setCurrentUser(record);
    setChangePwd(true);
    setRole(record.roleData ? record.roleData[0] : "")
  };

  const handleSalary = (record) => {
    setCurrentUser(record);
    setInpSalary(record.salary || 0);
    setChangeSalary(true);
  };

  const handleCancel = (e) => {
    setModal(false);
  };

  const showModal = () => setModal(true);

  const applyFilters = (values) => {
    let queryStr = "";

    const filtered = removeFalsyObj(values);

    Object.keys(filtered).forEach((f, idx) => {
      if (filtered[f]) {
        queryStr =
          idx === 0
            ? `${queryStr}?${f}=${filtered[f]}`
            : `${queryStr}&${f}=${filtered[f]}`;
      }
    });

    fetchUsers(queryStr);
  };

  const resetFilter = () => {
    fetchUsers();
  };

  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const handlePrint = () => {
    const fileName = "UserData"
    const exportType = "xls"
    exportFromJSON({ data: users, fileName, exportType })
  }

  const roleOption = [
    {
      label: 'AUTHOR',
      value: 'AUTHOR'
    },
    {
      label: 'REVIEWER',
      value: 'REVIEWER'
    },
    {
      label: 'REVIEWER-2',
      value: 'REVIEWER-2'
    },
    {
      label: 'UPLOADER',
      value: 'UPLOADER'
    },
  ]

  const shiftOption = [
    {
      label: '5 Days',
      value: '5'
    },
    {
      label: '5.5 Days',
      value: '5.5'
    },
    {
      label: '6 Days',
      value: '6'
    },
  ]

  const roleOptionForManager = [
    {
      label: 'AUTHOR',
      value: 'AUTHOR'
    },
    {
      label: 'REVIEWER',
      value: 'REVIEWER'
    },
    {
      label: 'REVIEWER-2',
      value: 'REVIEWER-2'
    },
    {
      label: 'UPLOADER',
      value: 'UPLOADER'
    },
    {
      label: 'MANAGER',
      value: 'MANAGER'
    },
  ]

  // function findRole(item) {
  //   return item.value === currentRole;
  // }

  // roleOption.splice(roleOption.findIndex(findRole), 1);
  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>Manage Users</h1>
        <div>
          <Button
            type="ghost"
            onClick={showModal}
            style={{ marginRight: "1rem" }}
          >
            Add User
          </Button>
          <Link to="/departments" type="button">
            <Button>Manage Departments</Button>
          </Link>
          <Button icon={<DownloadOutlined />} onClick={() => handlePrint()}
            style={{ marginLeft: "1rem" }}
          >Export Data</Button>
        </div>
      </div>
      <div style={{ margin: "1rem" }}>
        <Form layout="inline" onFinish={applyFilters}>
          <Form.Item
            label="Full Name"
            name="fullName"
            style={{ padding: "1rem 0rem" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            style={{ padding: "1rem 0rem" }}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Role" name="role" style={{ padding: "1rem 0rem" }}>
            <Select placeholder="Select role">
              {user.roleData[0] === "ADMIN" ? (
                <Select.Option value="MANAGER">Manager</Select.Option>
              ) : null}
              <Select.Option value="AUTHOR">Author</Select.Option>
              <Select.Option value="REVIEWER">Reviewer</Select.Option>
              <Select.Option value="REVIEWER-2">Reviewer-2</Select.Option>
              <Select.Option value="UPLOADER">Uploader</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item style={{ padding: "1rem 0rem" }}>
            <Button type="link" htmlType="submit">
              Apply filters
            </Button>
            <Button type="link" onClick={resetFilter}>
              Reset
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Table
      
        loading={usersLoading}
        data={users}
        columns={columns}
        size="middle"
        pagination={{   //  pagination added by ayush
          pageSize: 100
          }}
      />
      <AddUser
        modal={modal}
        handleCancel={handleCancel}
        fetchUsers={fetchUsers}
        setModal={setModal}
      />
      <Modal
        visible={changePwd}
        onCancel={() => {
          form.resetFields();
          setCurrentUser({});
          setChangePwd(false);
        }}
      >
        <div style={{ padding: "1rem" }}>
          <Form {...layout} form={form} name="basic" onFinish={updatePwd} autoComplete="off">
            <Form.Item label="Email">{currentUser?.email}</Form.Item>
            <Form.Item label="Enter New Password" name="newPassword">
              <Input type="password" name="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} autoComplete="new-password" />
            </Form.Item>

            <Form.Item label="New full name" name="newFullname">
              <Input />
            </Form.Item>
            {/* {currentUser.employee_id ? <></> : */}
            <Form.Item label="Add Employee Id" name="employee_id" >
              <Input name="employee_id" value={employee_id} onChange={(e) => setEmpId(e.target.value)} autoComplete="employee_id" />
            </Form.Item>

            {/* } */}

            <Form.Item label="Shift Hours" >
              <Input name="workingHours" onChange={(e) => setHours(e.target.value)} type="time" step="1" />
            </Form.Item>

            {
              workingHours !== null ? <>
                <Form.Item label="Effective Date" name="effective" rules={[{ required: workingHours && workingHours !== "" ? true : false }]}>
                  <Input name="effective" onChange={(e) => setEffective(e.target.value)} type="date" />
                </Form.Item>

                <Form.Item
                  label="Shift Type"
                  name="shiftType"
                >
                  <Select
                    onChange={(e) => console.log(e)}
                    options={shiftOption}
                  />
                </Form.Item>
              </>

                : <></>
            }
            {
              <Form.Item
                label="Add multiple role"
                name="roleData"
              >
                <Select
                  onChange={(e) => console.log(e)}
                  mode="multiple"
                  options={currentRole === "MANAGER" ? roleOptionForManager : roleOption}
                />
              </Form.Item>
            }

            <Form.Item label="Departments" name="departments">
              <Select
                mode="multiple"
                placeholder="Select departments"
                value={selectedDptmts}
                onChange={setSelectedDptmts}
                style={{ width: "100%" }}
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {dptmts.map((item) => (
                  <Select.Option key={item._id} value={item._id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Enable assignments" name="enableAssignments">
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            {
              user?.roleData[0] === "ADMIN" && currentRole !== "MANAGER" && (
                <Form.Item label="Manager" name="manager">
                  <Select
                    allowClear={true}
                    placeholder="Select manager"
                    style={{ width: "100%" }}
                  >
                    {managers.map((item) => (
                      <Select.Option key={item._id} value={item._id}>
                        {item.email}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

            <Form.Item label="Enable Qualitative" name="enableQualitative" initialValue={currentUser?.enableQualitative}>
              <Radio.Group initialValue={currentUser.enableQualitative}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Submit{" "}
                {isLoading ? (
                  <span style={{ paddingLeft: "0.5rem" }}>
                    <Spinner />
                  </span>
                ) : (
                  ""
                )}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Modal
        visible={changeSalary}
        onCancel={() => {
          setChangeSalary(false);
          setCurrentUser({});
          setInpSalary(currentUser.salary);
        }}
      >
        <div style={{ padding: "1rem" }}>
          <Form
            {...layout}
            form={form}
            name="basic"
            initialValues={{
              salary: currentUser.salary,
              amount: currentUser.newSalary?.amount,
              effectiveDate: moment(currentUser.newSalary?.effectiveDate),
            }}
            onFinish={updateSalary}
          >
            <Form.Item label="Email">{currentUser.email}</Form.Item>
            <Form.Item label="salary" name="salary">
              <InputNumber value={inpSalary} style={{ width: "150px" }} />
            </Form.Item>
            {currentUser.salary && (
              <>
                <Form.Item label="New salary" name="amount">
                  <InputNumber />
                </Form.Item>
                <Form.Item label="Effective date" name="effectiveDate">
                  <DatePicker />
                </Form.Item>
              </>
            )}
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Submit{" "}
                {isLoading ? (
                  <span style={{ paddingLeft: "0.5rem" }}>
                    <Spinner />
                  </span>
                ) : (
                  ""
                )}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      {currentUser._id && (
        <AssignProject
          modal={toggleAddProject}
          setModal={setToggleAddProject}
          handleCancel={() => {
            setToggleAddProject(!toggleAddProject);
            setCurrentUser({});
          }}
          userId={currentUser._id}
        />
      )}
    </div>
  );
};

export default Managers;
