import React, { useState, useEffect } from "react";
import "./manage.css";
import Table from "../../table";
import { useAsync } from "../../../utils/use-async";
import { client } from "../../../utils/api-client";
import {
  Button,
  Popconfirm,
  message,
} from "antd";
import CreateDepartment from "./create-department";
import { Spinner } from "../../lib";

const ManageDepartments = ({ type }) => {
  const { run, isLoading, data, isError, error } = useAsync();
  const [dptmtModal, setDptmtModal] = useState(false);
  const [dptmtLoading, setDptmtLoading] = useState(false);
  const [dptmts, setDptmts] = useState([]);
  const [toDelete, setToDelete] = useState("");

  const fetchDepartments = (query) => {
    run(client(`department/all`));
    setDptmtLoading(true);
  };

  const handleDelete = (e) => {
    setToDelete(e._id);
    run(client(`department/delete/${e._id}`, { method: "DELETE" }));
  };

  useEffect(() => {
    if (data?.message === "Department created successfully") {
      message.success(data.message);
      fetchDepartments();
    } else if (data?.message === "Fetched departments") {
      setDptmts(data.departments);
      setDptmtLoading(false);
    } else if (data?.code === "DEPARTMENT_DELETE_ERR") {
      message.error(data.message)
    } else if (data?.code === "DEPARTMENT_DELETE_SUCCESS") {
      message.success(data.message)
      fetchDepartments()
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      message.error(error.message);
    }
  }, [isError]);

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      width: 100,
      ellipsis: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Added By",
      dataIndex: "addedBy",
      key: "addedBy",
      width: 200,
      ellipsis: true,
    },
    {
      title: "Action",
      key: "x",
      dataIndex: "",
      width: 400,
      render: (record) => (
        <>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record)}
          >
            <Button type="link">
              Delete {isLoading && toDelete === record._id ? <Spinner /> : ""}
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const transformTableData = (data) =>
    data.map((dptmt, index) => {
      let { addedBy, ...rest } = dptmt;

      return { key: index, addedBy: addedBy.email, ...rest, sr: index + 1 };
    });

  useEffect(() => {
    fetchDepartments();
  }, []);

  const handleCancel = (e) => {
    setDptmtModal(false);
  };

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>Manage Departments</h1>
        <div>
          <Button type="ghost" onClick={() => setDptmtModal(true)}>
            Create Department
          </Button>
        </div>
      </div>
      <Table
        loading={dptmtLoading}
        data={transformTableData(dptmts)}
        columns={columns}
        size="middle"
      />
      <CreateDepartment
        modal={dptmtModal}
        handleCancel={handleCancel}
        fetchDepartments={fetchDepartments}
        setModal={setDptmtModal}
      />
    </div>
  );
};

export default ManageDepartments;
