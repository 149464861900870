import React, { useEffect, useState } from "react";
import { useAsync } from "../../../../utils/use-async";
import { client, fileClient } from "../../../../utils/api-client";
import {
  Button,
  Form,
  DatePicker,
  Select,
  Radio,
  Divider,
  Table as AntTable,
} from "antd";
import Table from "../../../table";
import { Spinner } from "../../../lib";
import roles from "./roles.json";
import allCols from "./cols.json";

const Daywise = ({ type, ...props }) => {
  const { run, data } = useAsync();
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";

  const [filters, setFilters] = useState({});
  const [selectedRole, setSelectedRole] = useState("");
  const [rows, setRows] = useState([]);
  const [reportLoading, setReportLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [downloadingFile, setDownloadingFile] = useState(false);
  const [page_no, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sections, setSections] = useState([]);
  const [totalRows, setTotalRows] = useState("");
  const [summary, setSummary] = useState([]);
  const [isSummaryReport, setIsSummaryReport] = useState(false);
  const [filterForm] = Form.useForm();

  const setCols = (rows) => {
    let cols = allCols
      .filter((_) => !!_)
      .map((col) => {
        if (col === "sr_no") {
          return {
            title: col,
            key: col,
            dataIndex: col,
            width: 70,
            fixed: true,
          };
        }
        if (col === "question_code") {
          return {
            title: col,
            key: col,
            dataIndex: col,
            width: 120,
            fixed: true,
          };
        }
        if (col === "link") {
          return {
            title: col,
            key: "x",
            width: 50,
            render: (record) => (
              <a target="_blank" href={record.link}>
                link
              </a>
            ),
          };
        } else if (col.endsWith("status")) {
          return {
            title: col,
            dataIndex: col,
            key: col,
            width: 140,
          };
        } else if (
          [
            "chapter",
            "section",
            "page",
            "q_number",
            "q_part",
            "q_type",
          ].includes(col)
        ) {
          return {
            title: col,
            dataIndex: col,
            key: col,
            width: 100,
          };
        } else {
          return {
            title: col,
            dataIndex: col,
            key: col,
            width: 180,
          };
        }
      });

    setColumns(cols);
  };

  const transformTableData = (data) => {
    if (data[0] && data[0].sr_no) {
      let d = data.map((row, index) => ({
        key: index,
        ...row,
      }));
      return d;
    } else {
      return [];
    }
  };

  const fetchReport = async (params) => {
    run(
      client(`report/view/day${isSummaryReport ? "?type=summary" : ""}`, {
        body: params,
      })
    );
    setReportLoading(true);
  };

  const getSections = () => {
    run(client(`/sections`));
  };

  useEffect(() => {
    getSections();
  }, []);

  useEffect(() => {
    if (data?.message === "Fetched sections") {
      let _sections = data.sections.map(({ name }) => name);
      setSections(_sections);
    }

    if (data?.message === "Generated report") {
      if (isSummaryReport) {
        setSummary(data.data);
      } else {
        setCols(data.data);
        setRows(transformTableData(data.data));
        setTotalRows(data.totalRows);
      }
      setReportLoading(false);
    }

    if (data?.type?.startsWith("application")) {
      var url = window.URL.createObjectURL(data);
      var a = document.createElement("a");
      a.href = url;
      if (downloadingFile) {
        a.download = "file.xlsx";
        setDownloadingFile(false);
      } else {
        a.download = "report.xlsx";
        setDownloading(false);
      }

      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    }
  }, [data]);

  const downloadReport = async () => {
    setDownloading(true);
    run(fileClient(`report/download/day`, { body: filters }));
  };

  const handleReportDownload = () => {
    downloadReport(filters);
  };

  const handlePage = (page_no, limit) => {
    setPageNo(page_no);
    setLimit(limit);
    fetchReport({ ...filters, page_no, limit });
  };

  const changePageSize = (page_no, limit) => {
    setPageNo(1);
    setLimit(limit);
    fetchReport({ ...filters, page_no: 1, limit });
  };

  const submitFilter = ({ role, status, subject, range }) => {
    const start = range?.[0].startOf("day").format();
    const end = range?.[1].endOf("day").format();

    const filters = {
      role,
      status,
      subject,
      start,
      end,
    };

    setPageNo(1);
    setLimit(10);
    setFilters(filters);
    fetchReport({ ...filters, page_no: 1, limit: 10 });
  };

  return (
    <div style={{ background: "white", padding: "1rem" }}>
      <div style={{ clear: "both", height: "50px" }}>
        <div style={{ float: "left" }}>
          <h1>Report</h1>
        </div>
        <div style={{ float: "right" }}>
          <Button
            disabled={!filters || Object.keys(filters).length === 0}
            type="primary"
            onClick={handleReportDownload}
          >
            {downloading ? (
              <>
                Downloading{" "}
                <span style={{ marginLeft: "4px" }}>
                  <Spinner />
                </span>
              </>
            ) : (
              "Download Report"
            )}
          </Button>
        </div>
      </div>
      <div style={{ minHeight: "50px" }}>
        <div style={{ margin: "1rem" }}>
          <Form
            form={filterForm}
            name="filter_form"
            layout="inline"
            onFinish={submitFilter}
          >
            <Form.Item
              name="subject"
              getValueFromEvent={(data) =>
                data.includes("all") ? sections : data
              }
            >
              <Select
                mode="multiple"
                placeholder="select subject"
                style={{ width: "350px" }}
                allowClear
              >
                <Option value="all">Select all</Option>
                {sections?.map((_, key) => (
                  <Option key={key} value={_}>
                    {_}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="role"
              rules={[{ required: true, message: "Please select role" }]}
            >
              <Select
                placeholder="select role"
                style={{ width: "250px" }}
                onChange={setSelectedRole}
                allowClear
              >
                {Object.keys(roles).map((_, key) => (
                  <Option key={key} value={_}>
                    {_}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="status"
              rules={[{ required: true, message: "Please select status!" }]}
            >
              <Select
                placeholder="select status"
                style={{ width: "250px" }}
                allowClear
              >
                {roles[selectedRole]?.map((_, key) => (
                  <Option key={`st-${key}`} value={_.value}>
                    {_.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="range"
              rules={[{ required: true, message: "Please input date range!" }]}
            >
              <RangePicker format={dateFormat} />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Apply filter
            </Button>
          </Form>
        </div>
        <div style={{ margin: "1rem" }}>
          <Radio.Group
            defaultValue={false}
            onChange={({ target }) => setIsSummaryReport(target.value)}
            buttonStyle="solid"
          >
            <Radio.Button value={false}>Detail</Radio.Button>
            <Radio.Button value={true}>Summary</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      {isSummaryReport ? (
        <div style={{ margin: "2rem 1rem" }}>
          <h1>Summarised report:</h1>
          {summary.length ? (
            summary.map(({ user, subjects }) => (
              <div>
                <strong>User: {user}</strong>
                {subjects.map(({ name, books }) => (
                  <div style={{ margin: "1rem" }}>
                    <strong>Subject: {name}</strong>
                    <div style={{ margin: "1rem" }}>
                      <AntTable
                        pagination={false}
                        columns={[
                          {
                            title: "Book name",
                            dataIndex: "name",
                            key: "name",
                          },
                          {
                            title: "count",
                            dataIndex: "count",
                            key: "count",
                          },
                        ]}
                        dataSource={books}
                      />
                      <Divider />
                    </div>
                  </div>
                ))}
              </div>
            ))
          ) : (
            <strong>No data found! Please apply appropriate filters</strong>
          )}
        </div>
      ) : (
        <div style={{ display: "block", overflow: "auto" }}>
          <Table
            pagination={{
              onChange: handlePage,
              position: ["bottomCenter"],
              current: page_no,
              total: totalRows,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "50", "100", "500"],
              showSizeChanger: true,
              onShowSizeChange: changePageSize,
            }}
            loading={reportLoading}
            data={rows}
            columns={columns}
            size="middle"
          />
        </div>
      )}
    </div>
  );
};

export default Daywise;
