import React, { useState, useEffect } from "react";
import "./allocate.css";
import Table from "../../../table";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Button, Popconfirm, Space, message } from "antd";
import { useHistory , useParams} from "react-router-dom"
import AddTargetModal from "../add-target-data-modal";

const AddTargetData = () => {
  const {
    run,
    isLoading,
    data
  } = useAsync();

  let [projects, setProjects] = useState([]);
  let [allDep, setDep] = useState([])
  let [modal, setModal] = useState(false);
  let [usersLoading, setUsersLoading] = useState(false);
  const history = useHistory()
  const params = useParams();

  const handleDelete = async(id)=> {
    await run(client(`targets/delete/${id}`, {method: "DELETE"}))
    await fetchProjects();
  }

  useEffect(() => {
    if (data?.message === "Fetched Target Project") {
      setProjects(transformTableData(data?.allProjects))
      setUsersLoading(false)
      message.success(data?.message);
    }
    else if (data?.message === "Fetched departments") {
      setDep(data?.departments)
    }
  }, [data]);

  const columns = [
    {
      title: "Project",
      dataIndex: "project_name",
      key: "project_name",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Keyword",
      dataIndex: "keyword",
      key: "keyword",
   
    },
    {
      title: "Sub Project",
      dataIndex: "sub_project_name",
      key: "sub_project_name",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Department",
      key: "x",
      render: (record) => (
        <>
          {record.department.name}
        </>
      ),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Added By",
      key: "x",
      render: (record) => (
        <>
          {record?.addedBy?.email}
        </>
      ),
    },
    {
      title: "Actions",
      render: (record) => (
        <>
          <Space>
            <Button type="primary" onClick={()=>history.push(`/target-assign/${params.projectId}/${record?._id}`,{id:record?._id})}>Show Details</Button>
          </Space>

          <Space style={{marginLeft: '0.8rem'}}>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record?._id)}
          >
            <Button type="danger">Delete</Button>
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

  const transformTableData = (data) => {
    //chnages by ayush
    return data.map(({ addedBy, ...project }, index) => ({ key: index, ...project,  email: addedBy ? addedBy.email : '', sr: index + 1 }));
  };

  const fetchProjects = async() => {
    await run(client(`targets/get/projects/${params.projectId}`));
    setUsersLoading(true);
  };

  const fetchDepartments = async () => {
    await run(client("department/all/report"));
  };


  useEffect(() => {
    fetchProjects();
    fetchDepartments();
  }, []);

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ marginBottom: 0 }}>All Target Data</h1>
        <div>
        <Button type="ghost" onClick={()=>setModal(true)} style={{marginRight:'1rem'}}>
          Add Target Data
        </Button>
        </div>
      </div>
      <Table
        loading={usersLoading}
        columns={columns}
        data={projects}
        size="middle"
      />
      <AddTargetModal
        modal={modal}
        fetchProjects={fetchProjects}
        setModal={setModal}
        allDepartments= {allDep}
        projectId = {params.projectId}
      />
    </div>
  );
};

export default AddTargetData;
