import React, { useEffect, useState } from "react";
import { Modal, Form, message, Button, Select, Input, DatePicker, Checkbox } from "antd";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Spinner } from "../../../lib";
import moment from "moment"
const { Option } = Select
export const url = "https://work.vaidikedu.com"

const AddUser = ({
  modal,
  fetchProject,
  handleCancel,
  setModal,
  project,
  addedUsers,
  remove
}) => {
  const { isLoading, run, isError, error, data } = useAsync();
  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [wef, setWef] = useState('')
  const [accountIdData, setAccountData] = useState([])
  const [departmentData, setDepartment] = useState("");
  let [loading, setLoading] = useState(false)
  const [departmentValue, setDep] = useState("")
  const [userValue, setUserValue] = useState("")
  const [accountId, setAccount] = useState("");
  const [checked, setChecked] = useState(false)
  const [userForm] = Form.useForm();
  const getToken = localStorage.getItem("__vaidikedu_token__")



  const addUser = async (values) => {
    await run(
      client(`timesheet/project/${project}/user/add`, {
        body: { ...values },
        method: "PUT",
      }).then((res) => {
        message.success(res.message);
        setModal(false);
        fetchProject(project);
        setUserValue("");
        setLoading(true)
      })
    );
    console.log({...values,wef:wef._d})
    userForm.resetFields();
  };
  const fetchUsers = () => {
    run(client("timesheet/users"));
  };

  const fetchDepartments = () => {
    run(client("department/all"))
  }

  const fetchOneDep = () => {
    if (departmentData !== "")
      run(client(`department/${departmentData}`))
  }

  const fetchAccountIds = async () => {
    const all = await fetch(`${url}/api/v1/timesheet/getAllocations/${project}`, {
      headers: {
        "x-auth-token": getToken
      }
    }).then((res) => res.json().then((res) => {
      setAccountData(res.allocations)
    })).catch(error => console.log(error))
  }

  useEffect(() => {
    fetchDepartments()
  }, []);

  useEffect(() => {
    fetchOneDep()
  }, [departmentData])

  useEffect(() => {
    if (data?.message === "Fetched users") {
      setUsers(data.users);
    }
    else if (data?.message === "Fetched departments") {
      setDepartments(data.departments)
    }
    else if (data?.message === "Fetched One department") {
      setDep(data?.department?.name)
    }
  }, [data, departmentData]);


  useEffect(() => {
    fetchUsers()
    fetchAccountIds()
  }, [loading, remove])

  const handleChange = (value) => {
    setDepartment(value)
  };


  const handleChangeWEF = (value) => {
    setWef(value)
  };

  const handleChangeA = (value) => {
    setAccount(value)
  };

  const remainingUsers = users.filter((user) => {
    const found = !addedUsers.find((u) => {
      return u._id === user._id;
    });
    return found;
  });

  const displayUser = remainingUsers.filter((user) => {
    if (departmentData) {
      return user.departments[0]?.name === departmentData;
    }
    return user
  })


  const onOk = (value) => {
    console.log("onOk: ", moment().format());
    console.log("onOk: ", typeof value);
    console.log("onOk: ", value.format("YYYY-MM-DD HH:mm:ss"));
  };

  return (
    <Modal
      title="Add User"
      visible={modal}
      onCancel={() => {
        handleCancel();
        userForm.resetFields();
        setDepartment("")
        setUserValue("");
      }}
    >
      <Form
        form={userForm}
        size="middle"
        layout="horizontal"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={addUser}
      >
        <Form.Item
          label="Department"
          name="departmentData"
          rules={[{ required: true }]}
        >
          <Select
            onChange={handleChange}
            name="departmentData"
            placeholder="Select Department">

            {departments.map((user, idx) => (
              <Option key={idx} value={user._id}>
                {user.name}
              </Option>
            ))}
          </Select>

        </Form.Item>

        <Form.Item
          label="Select users"
          name="users"
          rules={[{ required: true }]}
        >
          <Select
            showSearch={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear={true}
            placeholder="select"
            onChange={(value) => setUserValue(value)}
            mode="multiple"
          >
            {users.map((user, idx) => {

              return (
                <Select.Option key={`profile-${idx}`} value={user._id}>
                  {user.email}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>

        {
          userValue !== "" ?
            <Form.Item
              label="Account ID"
              name="accountId"
              rules={[{ required: true }]}
              onChange={handleChangeA}
            >
              <Select
                showSearch={true}
                placeholder="Select Account Id"
              >
                {
                  accountIdData.filter((data) => {
                    if (data.current_user === "" && departmentValue !== "" ? data.subject_name === departmentValue : data.current_user === "") {
                      return data;
                    }
                  }).map((user, idx) =>
                  (
                    <Select.Option key={idx} value={user.account_id}>
                      {user.account_id}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
            : <></>
        }

        <Form.Item
          label="Add Effective Date">
          <Checkbox onChange={(e) => setChecked(e.target.checked)}></Checkbox>
        </Form.Item>

        {
          checked === true &&
          <Form.Item
            label="Date Effective :"
            name="wef"
            onChange={handleChangeWEF}
            rules={[
              {
                required: true,
                message: "Please input the date effective",
              },
            ]}
          >
            <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime={true} onOk={onOk} style={{ width: '100%' }} />

          </Form.Item>
        }



        <p>
          <em>
            <strong>Note: </strong>Please assign a manager to the users first
            otherwise the user email won't show in the list.
          </em>
        </p>
        <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }}>
          <Button type="primary" htmlType="submit">
            Submit{" "}
            {isLoading && (
              <span style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                <Spinner />
              </span>
            )}
          </Button>
        </Form.Item>
        {isError && <p style={{ color: "red" }}>{error.message}</p>}
      </Form>
    </Modal>
  );
};

export default AddUser;
