import React, { useState, useEffect } from "react";
import "./details.css";
import Table from "../../../table";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import { Button, message, Popconfirm, Space, Breadcrumb } from "antd";
import AddSubject from "../add-subject";
import { Spinner } from "../../../lib";
import { Link, useHistory } from "react-router-dom";

const ProjectDetails = ({
  match: {
    params: { projectId },
  },
}) => {
  const { run, error, data, isLoading } = useAsync();

  let [subjects, setSubjects] = useState([]);
  let [modal, setModal] = useState(false);
  let [subjectsLoading, setSubjectsLoading] = useState(false);
  let [currentProject, setCurrentProject] = useState({});
  const [toDelete, setToDelete] = useState("");

  useEffect(() => {
    if (data?.code === "SUBJECT_DELETE_ERR") {
      message.error(data.message);
      setToDelete("");
    }

    if (data?.code === "SUBJECT_DELETE_SUCCESS") {
      message.success(data.message);
      setToDelete("");
    }

    if (data?.message === "Fetched project") {
      const { subjects, ...project } = data.data;
      setSubjects(subjects);
      setCurrentProject(project);
      setSubjectsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (error?.message === "Project not found") {
      setSubjectsLoading(false);
      message.error(error.message);
    }
  }, [error]);

  const handleSubjectDelete = ({ _id }) => {
    setToDelete(_id);
    run(
      client(`/project/${projectId}/subject/${_id}/delete`, {
        method: "DELETE",
      })
    );
  };

  const history = useHistory();

  const openProfiles = (record) => {
    const id = subjects[record.sr - 1]._id;
    history.push(`/worklog/${id}/profiles`);
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      ellipsis: true,
    },
    {
      title: "Subject Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Actions",
      key: "x",
      dataIndex: "",
      render: (record) => (
        <Space>
          <Button type="primary" onClick={() => openProfiles(record)}>
            Manage profiles
          </Button>
          <Popconfirm
            title="Sure to remove?"
            onConfirm={() => handleSubjectDelete(record)}
          >
            <Button type="danger" disabled={isLoading && toDelete}>
              Remove {isLoading && toDelete === record.name ? <Spinner /> : ""}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const transformTableData = (data) =>
    data.map(({ name, ...data }, index) => ({
      key: index,
      name,
      sr: index + 1,
      ...data,
    }));

  const fetchProject = (id) => {
    run(client(`project/${id}`));
    setSubjectsLoading(true);
  };

  useEffect(() => {
    fetchProject(projectId);
  }, []);

  const handleCancel = (e) => {
    setModal(false);
  };

  const showModal = () => setModal(true);

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/worklog">Projects</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{currentProject.name}</Breadcrumb.Item>
          </Breadcrumb>
          <h1>Available subjects</h1>
        </div>
        <Button type="ghost" onClick={showModal}>
          Add Subject
        </Button>
      </div>
      <Table
        loading={subjectsLoading}
        data={transformTableData(subjects)}
        columns={columns}
        size="middle"
      />
      <AddSubject
        modal={modal}
        handleCancel={handleCancel}
        fetchProject={fetchProject}
        project={projectId}
        setModal={setModal}
      />
    </div>
  );
};

export default ProjectDetails;
