import React, { useState, useEffect } from "react";
import Table from "../../../table";
import { useAsync } from "../../../../utils/use-async";
import { client } from "../../../../utils/api-client";
import {
  Button,
  message,
  Form,
  Modal,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Popover,
  Breadcrumb,
  Checkbox,
  Space,
  Popconfirm,
} from "antd";
import { Link } from "react-router-dom";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const targetTypes = ["units", "hours"];

const Targets = ({
  match: {
    params: { projectId, profileId },
  },
}) => {
  const { run, data } = useAsync();

  const [profileMeta, setProfileMeta] = useState(null);
  const [targets, setTargets] = useState([]);
  const [targetsLoading, setTargetsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [currDepartment, setCurrDepartment] = useState(null);
  const [users, setUsers] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (data?.message === "Fetched targets") {
      const { targets, ...profileMeta } = data.data;

      setTargets(targets);
      setProfileMeta(profileMeta);
      setTargetsLoading(false);
    }

    if (data?.message === "Fetched departments") {
      setDepartments(data.departments);
    }

    if (data?.code === "FETCH_DEPARTMENT_SUCCESS") {
      setUsers(data.data);
    }

    if (data?.code === "TARGET_DELETE_SUCCESS") {
      message.success(data.message);
      fetchTargets(profileId);
    }

    if (data?.code === "PROFILE_UPDATE_SUCCESS") {
      message.success(data.message);
      fetchTargets(profileId);
    }

    if (data?.message === "Targets created succesfully.") {
      fetchTargets(profileId);
      message.success(data.message);
      setModal(false);
    }
  }, [data]);

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "sr",
      key: "sr",
      ellipsis: true,
    },
    { title: "Target name", dataIndex: "name", key: "name" },
    {
      title: "Assigned to",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Added by",
      dataIndex: "addedBy",
      key: "addedBy",
    },
    {
      title: "Action",
      key: "x",
      dataIndex: "",
      width: 400,
      render: (record) => (
        <Space>
          <Link to={`targets/${record._id}`}>
            <Button type="primary" type="link">
              View
            </Button>
          </Link>
          <Popconfirm
            title="Are you sure？"
            onConfirm={() => deleteTargets(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const transformTableData = (data) =>
    data.map(({ name, addedBy, userId, ...rest }, index) => ({
      key: index,
      name,
      addedBy: addedBy.email,
      user: userId.email,
      sr: index + 1,
      ...rest,
    }));

  const updateProfile = (vals, id) => {
    run(client(`profile/${profileId}/update`, { body: vals, method: "PATCH" }));
  };

  const fetchTargets = (id) => {
    run(client(`targets/${id}/all`));
    setTargetsLoading(true);
  };

  const deleteTargets = (id) => {
    run(client(`targets/admin/${id}`, { method: "DELETE" }));
  };

  const fetchDepartments = () => {
    run(client(`department/all`));
  };

  const fetchUsers = (department) => {
    run(client(`department/users?department=${department}`));
  };

  useEffect(() => {
    fetchTargets(profileId);
    fetchDepartments();
  }, []);

  useEffect(() => {
    fetchUsers(currDepartment);
  }, [currDepartment]);

  const showModal = () => setModal(true);

  const createTarget = ({ dates, type, ...values }) => {
    const target = {
      start: dates[0].startOf("day").utc().toISOString(),
      end: dates[1].endOf("day").utc().toISOString(),
      type: type.join("_"),
      ...values,
    };

    projectId = profileMeta.subject.projectId._id;

    run(
      client(`project/${projectId}/${profileId}/target/create`, {
        body: target,
      })
    );
  };

  const content = ({ profileId, budget } = {}) => (
    <div>
      <Form
        layout="horizontal"
        size="small"
        initialValues={{
          budget,
        }}
        onFinish={(val) => updateProfile(val, profileId)}
      >
        <Form.Item label="Budget" name="budget">
          <InputNumber style={{ width: "80px" }} />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </div>
  );

  const changeProfileName = ({ profileId, name } = {}) => (
    <div>
      <Form
        layout="horizontal"
        size="small"
        initialValues={{
          name,
        }}
        onFinish={(val) => updateProfile(val, profileId)}
      >
        <Form.Item label="Profile name" name="name">
          <Input style={{ width: "150px" }} />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
    </div>
  );

  return (
    <div className="manage--wrapper">
      <div
        style={{
          margin: "1rem 0rem",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/worklog">Projects</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/worklog/${profileMeta?.subject?.projectId?._id}`}>
                {profileMeta?.subject?.projectId?.name}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/worklog/${profileMeta?.subject?._id}/profiles`}>
                {profileMeta?.subject?.name}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{profileMeta?.name}</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <h1>Targets:</h1>
          <ul style={{ listStyle: "none" }}>
            <li>
              Project: <strong>{profileMeta?.subject?.name}</strong>
            </li>
            <li>
              Subject: <strong>{profileMeta?.subject?.projectId?.name}</strong>
            </li>
            <li>
              Profile: <strong>{profileMeta?.name}</strong>{" "}
              <Popover
                content={() =>
                  changeProfileName({ profileId, name: profileMeta?.name })
                }
                title="Change profile name"
                trigger="click"
              >
                <Button type="primary">
                  <EditOutlined />
                </Button>
              </Popover>
            </li>
            <li>
              Budget: <strong> ₹ {profileMeta?.budget} / unit</strong>{" "}
              <Popover
                content={() =>
                  content({ profileId, budget: profileMeta?.budget })
                }
                title="Change budget"
                trigger="click"
              >
                <Button type="primary">
                  <EditOutlined />
                </Button>
              </Popover>
            </li>
            <li>
              Avg cost: <strong>₹ {profileMeta?.cost} / unit</strong>
            </li>
            <li>
              Total expense: <strong>₹ {profileMeta?.totalExpense}</strong>
            </li>
            <li>
              PnL:{" "}
              <strong
                style={{
                  letterSpacing: "1.2px",
                  color:
                    profileMeta?.pnl < 0
                      ? "red"
                      : profileMeta?.pnl > 0
                      ? "green"
                      : "inherit",
                }}
              >
                ₹ {profileMeta?.pnl}
              </strong>
            </li>
          </ul>
          <em>Available targets</em>
        </div>
        <Button type="primary" onClick={showModal}>
          Create target
        </Button>
      </div>
      <Modal
        title="Create target"
        visible={modal}
        onCancel={() => setModal(false)}
      >
        <Form
          {...layout}
          form={form}
          onFinish={createTarget}
          initialValues={{ type: ["units"] }}
          name="create target"
        >
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Select department">
            <Select
              placeholder="select"
              onChange={(selected) => setCurrDepartment(selected)}
            >
              {departments.map((department, idx) => (
                <Select.Option
                  key={`department-${idx}`}
                  value={department.name}
                >
                  {department.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Select users"
            name="users"
            rules={[{ required: true }]}
          >
            <Select placeholder="select" mode="multiple" allowClear>
              {users.map((user, idx) => (
                <Select.Option key={`user-${idx}`} value={user.email}>
                  {user.email}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Start and end dates"
            name="dates"
            rules={[{ required: true }]}
          >
            <DatePicker.RangePicker />
          </Form.Item>
          <Form.Item label="Target" name="target" rules={[{ required: true }]}>
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item
            label="Target type"
            name="type"
            rules={[{ required: true }]}
          >
            <Checkbox.Group options={targetTypes} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
          {/* 
  name,
  department,
  users,
  startDate,
  endDate,
  target
 */}
        </Form>
      </Modal>
      {targets && (
        <Table
          loading={targetsLoading}
          data={transformTableData(targets)}
          columns={columns}
          size="middle"
        />
      )}
    </div>
  );
};

export default Targets;
